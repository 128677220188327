import React from 'react';
import { TextBody } from '@directsoftware/ui-kit-web-admin';
import ReactI18n from 'react-i18n';
import { every, some, find, findIndex, includes, flatMap } from 'lodash';
import { useSelector } from 'react-redux';
import ListingsDetailsContentBlock from './listings-details-content-block';
import { selectListingsDetailsSingleState } from '../../../redux/slices/listingsDetailsSingle';

const BookingDeposit = ({ vehicleDeposits }) => {
  const translate = ReactI18n.getIntlMessage;
  const { pricing } = useSelector(selectListingsDetailsSingleState);

  const getDepositRefundPolicy = () => {
    const deposits = pricing?.deposits || vehicleDeposits;
    if (deposits) {
      if (every(deposits, ['refundable', true])) {
        return 'is_refundable';
      } else if (some(deposits, ['refundable', true])) {
        return 'is_part_refundable';
      } else {
        return 'is_not_refundable';
      }
    } else {
      // this is if pricing deposits aren't set
      return 'full';
    }
  };

  const calculateBalanceDueDate = () => {
    if (pricing?.deposits) {
      const dueDates = flatMap(
        pricing.deposits,
        deposit => deposit.remaining_balance_due_date
      );
      const availableDueDates = [
        'day90',
        'day60',
        'day30',
        'day15',
        'day7',
        'check_in_date',
        'manual'
      ];
      if (dueDates.length > 1) {
        return find(availableDueDates, date => includes(dueDates, date));
      } else {
        return dueDates[0];
      }
    } else {
      // this is if pricing deposits aren't set
      return 'check_in_date';
    }
  };

  const renderBalanceDueText = () => {
    const dueDateOptions = [
      { value: 'day90', label: '90 days prior to check-in' },
      { value: 'day60', label: '60 days prior to check-in' },
      { value: 'day30', label: '30 days prior to check-in' },
      { value: 'day15', label: '15 days prior to check-in' },
      { value: 'day7', label: '7 days prior to check-in' },
      { value: 'check_in_date', label: 'on the check-in date' },
      { value: 'manual', label: 'before check-in' }
    ];
    const dueDate = calculateBalanceDueDate();
    const index = findIndex(dueDateOptions, option => option.value === dueDate);
    return dueDateOptions[index].label;
  };

  return (
    <ListingsDetailsContentBlock
      label={translate(`cx.details.headers.booking_deposit_policy`)}
    >
      {!vehicleDeposits && (
        <TextBody isFullWidth textColor="dark-gray" weight="semibold">
          {translate(`global.deposit_policy.${getDepositRefundPolicy()}.label`)}
        </TextBody>
      )}
      <TextBody>
        {vehicleDeposits
          ? '50% due now, 50% due 30 days prior to pick-up'
          : `Booking deposits are due at booking or booking request
        confirmation. The remaining balance will then be due ${renderBalanceDueText()}`}
      </TextBody>
    </ListingsDetailsContentBlock>
  );
};

export default BookingDeposit;
