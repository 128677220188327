import React, { useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  IconFontAwesome,
  SlideoutPanel,
  Spacer,
  TextCaption
} from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import NavSidebarLink from './nav-sidebar-link';

const NavSidebar = () => {
  const brand = useSelector(state => state.brand);
  const [navLinks, setNavLinks] = useState(null);
  const [contactLinks, setContactLinks] = useState(null);
  const [socialLinks, setSocialLinks] = useState(null);
  const [openNav, setOpenNav] = useState(false);
  const [dropdownPanelId, setDropdownPanelId] = useState(0);

  const unflatten = items => {
    const topLevel = items
      .filter(item => !item.parent_id)
      .sort((a, b) => a.position - b.position);
    const childLevel = items
      .filter(item => item.parent_id)
      .sort((a, b) => a.position - b.position);
    const finalArray = topLevel.map(link => {
      const hasChildLinks = childLevel.filter(
        child => link.id === child.parent_id
      );
      return hasChildLinks.length > 0
        ? {
            ...link,
            subLinks: [...hasChildLinks]
          }
        : link;
    });

    return finalArray;
  };

  const renderUrlOrType = (link, renderType = false) => {
    if (renderType) {
      return link.slug.startsWith('/') || link.slug.startsWith('http')
        ? 'link'
        : 'router-link';
    } else {
      return link.slug.startsWith('/') || link.slug.startsWith('http')
        ? link.slug
        : `/${link.slug}`;
    }
  };

  const renderPhoneLabel = phone => {
    const textLabel =
      phone.label && phone.label !== '' ? `${phone.label}: ` : '';
    return `${textLabel}${phone.number}`;
  };

  const hasContactInfo = () => {
    if (
      contactLinks.email === '' &&
      contactLinks.phone_primary?.number === '' &&
      contactLinks.phone_secondary?.number === ''
    )
      return false;
    return true;
  };

  useEffect(() => {
    if (brand.menu && brand.menu.active && brand.menu.menu_items)
      setNavLinks(brand.menu.menu_items);
    if (brand.brand_info.social) {
      const socialLinkArray = [];
      for (const [key, value] of Object.entries(brand.brand_info.social)) {
        const keyName = key.replace('social_', '');
        if (value) {
          socialLinkArray.push({
            title: keyName.charAt(0).toUpperCase() + keyName.slice(1),
            slug: `https://${value}`,
            iconName: keyName
          });
        }
      }
      setSocialLinks(socialLinkArray);
    }
    if (brand.brand_info.contact) setContactLinks(brand.brand_info.contact);
  }, []);

  return (
    (navLinks || contactLinks || socialLinks) && (
      <Box>
        <IconButton
          variation="secondary"
          appearance="ghost"
          size="dense"
          onClick={() => setOpenNav(true)}
        >
          <IconFontAwesome name="bars" />
        </IconButton>
        <SlideoutPanel
          reveal={openNav}
          contentIsScrollable
          title="Menu"
          variation="side-navigation"
          modalOnMobile={false}
          closeButtonOnClick={() => setOpenNav(false)}
          removeContentPadding
        >
          <Spacer />
          <Box paddingHorizontal="m">
            {navLinks && navLinks.length > 0 && (
              <>
                <TextCaption textTransform="uppercase" textColor="disabled">
                  Site Links
                </TextCaption>
                <Spacer />
                {unflatten(navLinks).map((link, idx) => {
                  return link.subLinks?.length > 0 ? (
                    <>
                      <NavSidebarLink
                        key={`nav-link-${idx}`}
                        href={renderUrlOrType(link)}
                        label={link.title}
                        type={renderUrlOrType(link, true)}
                        dropdownOnClick={() =>
                          setDropdownPanelId(
                            dropdownPanelId === link.id ? 0 : link.id
                          )
                        }
                        dropdownIconToggle={dropdownPanelId === link.id}
                      />
                      {dropdownPanelId === link.id && (
                        <>
                          {link.subLinks.map((subLink, idx2) => (
                            <NavSidebarLink
                              key={`sub-nav-link-${idx2}`}
                              href={renderUrlOrType(subLink)}
                              label={subLink.title}
                              type={renderUrlOrType(subLink, true)}
                              isSubLink
                            />
                          ))}
                        </>
                      )}
                    </>
                  ) : (
                    <NavSidebarLink
                      key={`nav-link-${idx}`}
                      href={renderUrlOrType(link)}
                      label={link.title}
                      type={renderUrlOrType(link, true)}
                    />
                  );
                })}
                <Spacer />
              </>
            )}
            {socialLinks && socialLinks.length > 0 && (
              <>
                <TextCaption textTransform="uppercase" textColor="disabled">
                  Social Media
                </TextCaption>
                <Spacer />
                {socialLinks.map((link, idx) => (
                  <NavSidebarLink
                    key={`s0cial-nav-link-${idx}`}
                    href={renderUrlOrType(link)}
                    label={link.title}
                    type={renderUrlOrType(link, true)}
                    icon={<IconFontAwesome name={link.iconName} />}
                  />
                ))}
                <Spacer />
              </>
            )}
            {contactLinks && hasContactInfo() && (
              <>
                <TextCaption textTransform="uppercase" textColor="disabled">
                  Contact Info
                </TextCaption>
                <Spacer />
                {contactLinks.email && (
                  <NavSidebarLink
                    href={`mailTo:${contactLinks.email}`}
                    label={contactLinks.email}
                    type="link"
                    icon={<IconFontAwesome name="envelope" />}
                  />
                )}
                {contactLinks.phone_primary?.number &&
                  contactLinks.phone_primary?.number !== '' && (
                    <NavSidebarLink
                      href={`tel:${contactLinks.phone_primary.number}`}
                      label={renderPhoneLabel(contactLinks.phone_primary)}
                      type="link"
                      icon={<IconFontAwesome name="phone" />}
                    />
                  )}
                {contactLinks.phone_secondary?.number &&
                  contactLinks.phone_secondary?.number !== '' && (
                    <NavSidebarLink
                      href={`tel:${contactLinks.phone_secondary.number}`}
                      label={renderPhoneLabel(contactLinks.phone_secondary)}
                      type="link"
                      icon={<IconFontAwesome name="phone" />}
                    />
                  )}
                <Spacer />
              </>
            )}
            <TextCaption textTransform="uppercase" textColor="disabled">
              Other Links
            </TextCaption>
            <Spacer />
            <NavSidebarLink
              href="/sitemap.xml"
              label="Sitemap"
              type="link"
              icon={<IconFontAwesome name="sitemap" />}
            />
          </Box>
        </SlideoutPanel>
      </Box>
    )
  );
};

export default NavSidebar;
