import React from 'react';
import {
  Box,
  Divider,
  FlexBox,
  FlexBoxGrid,
  PageStateMessage,
  Spacer,
  StandByOverlay,
  TextDense
} from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { selectListingsSearchState } from '../../../redux/slices/listingsSearch';
import ListingsSearchGridItem from './listings-search-grid-item';
import { useDetectMobile } from '../../../../shared/hooks/useDetectMobile';
import ListingsSearchFilterInstantBooking from '../atoms/listings-search-filter-instant-booking';
import ListingsSearchGridItemSkeleton from '../skeletons/listings-search-grid-item-skeleton';

const ListingsSearchGrid = ({ updateSearchState, translate }) => {
  const { isLargeDesktop, isMobile } = useDetectMobile();
  const {
    results: searchResults,
    resultsLength,
    totalPages,
    currentPage,
    pageOffset,
    totalProperties
  } = useSelector(selectListingsSearchState);
  const { searchInProgress, allowSearchSpinner } = useSelector(
    state => state.ui
  );

  const numberOfColumns = () => {
    return isLargeDesktop ? 3 : 2;
  };

  const handlePageClick = data => {
    const newPage = data.selected;
    const offset = Math.ceil(data.selected * 18);
    updateSearchState({ pageOffset: offset, currentPage: newPage }, true);
  };

  const showTotalProperties = () => {
    const over300 = totalProperties && totalProperties > 300;
    return over300 ? '300+ results' : `${totalProperties} results`;
  };

  const parsePageOffset = () => {
    const offset = parseInt(pageOffset) + 1;
    const length = resultsLength || '';
    const endItem =
      totalProperties <= 18 ? totalProperties : offset + length - 1;
    return `${offset} - ${endItem} of ${showTotalProperties()}`;
  };

  return (
    <>
      {allowSearchSpinner && (
        <StandByOverlay
          reveal={searchInProgress}
          position="absolute"
          freezeBody={false}
        />
      )}
      {searchResults ? (
        <>
          {isMobile && (
            <>
              <FlexBox justifyContent="space-between" alignItems="center">
                <Box paddingBottom="xxs">
                  <TextDense weight="semibold" textColor="dark-gray">
                    {`${totalProperties} Listings`}
                  </TextDense>
                </Box>
                <ListingsSearchFilterInstantBooking
                  updateSearchState={updateSearchState}
                />
              </FlexBox>
              <Divider paddingTop="xs" />
              <Spacer />
            </>
          )}
          {searchResults.length > 0 ? (
            <FlexBoxGrid columns={numberOfColumns()} gap="s">
              {searchResults.map((listing, idx) => (
                <ListingsSearchGridItem
                  key={`listing-${idx}`}
                  listing={listing}
                  translate={translate}
                />
              ))}
            </FlexBoxGrid>
          ) : (
            <PageStateMessage
              iconProps={{ name: 'emptySet' }}
              headline="No Listings Found"
              description="Try adjusting the features or amenities"
            />
          )}
          <Spacer />
          {totalPages > 1 && (
            <>
              <Divider />
              <FlexBox className="searchGridPagination" justifyContent="center">
                <ReactPaginate
                  previousLabel="<"
                  pageRangeDisplayed={3}
                  nextLabel=">"
                  breakLabel={<a href="">...</a>}
                  breakClassName="break-me"
                  pageCount={totalPages}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  containerClassName=""
                  forcePage={currentPage}
                  activeClassName="active"
                />
              </FlexBox>
              <FlexBox justifyContent="center">
                <TextDense weight="semibold">{parsePageOffset()}</TextDense>
              </FlexBox>
              <Spacer size="m" />
            </>
          )}
        </>
      ) : (
        <FlexBoxGrid columns={numberOfColumns()} gap="s">
          <ListingsSearchGridItemSkeleton />
          <ListingsSearchGridItemSkeleton />
          <ListingsSearchGridItemSkeleton />
          <ListingsSearchGridItemSkeleton />
          <ListingsSearchGridItemSkeleton />
          <ListingsSearchGridItemSkeleton />
        </FlexBoxGrid>
      )}
    </>
  );
};

export default ListingsSearchGrid;
