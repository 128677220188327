import React from 'react';
import { useSelector } from 'react-redux';
import { FlexBoxGrid, Label, FlexBox } from '@directsoftware/ui-kit-web-admin';
import ReactI18n from 'react-i18n';
import ListingsDetailsContentBlock from '../shared-v2/listings-details-content-block';

const DeleveryLocations = () => {
  const translate = ReactI18n.getIntlMessage;
  const listing = useSelector(state => state.listing);
  const deliveryLocations = listing.delivery_locations;

  return (
    <ListingsDetailsContentBlock label="Delivery Locations">
      <FlexBoxGrid columns={2} gap="m">
        {deliveryLocations.map(location => (
          <FlexBox justifyContent="space-between">
            <Label>{location.name}</Label>
            {translate(`global.parsers.currency.${listing.currency}`, {
              value: Math.round(location.price)
            })}
          </FlexBox>
        ))}
      </FlexBoxGrid>
    </ListingsDetailsContentBlock>
  );
};

export default DeleveryLocations;
