import React from 'react';
import {
  Box,
  FlexBox,
  InputIncrementer,
  Label
} from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import { selectListingsSearchState } from '../../../redux/slices/listingsSearch';

const ListingsSearchNumBaths = ({ updateSearchState, translate }) => {
  const searchState = useSelector(selectListingsSearchState);

  return (
    <FlexBox alignItems="center" justifyContent="space-between">
      <Box flex="1">
        <Label>{translate('cx.global.amenities.bathrooms')}</Label>
      </Box>
      <Box>
        <InputIncrementer
          onChange={num =>
            updateSearchState({ filter_numBathrooms: num }, true)
          }
          value={searchState.filter_numBathrooms}
          inputWidth="s"
          min={0}
          max={searchState.maxBaths}
          isDense
          removeBottomSpacer
        />
      </Box>
    </FlexBox>
  );
};

export default ListingsSearchNumBaths;
