import React from 'react';
import { Divider, Spacer, TextH2 } from '@directsoftware/ui-kit-web-admin';

const ListingsDetailsContentBlock = ({ label, children, removeDivider, id }) => {
  return (
    <section id={id || label}>
      <TextH2 textColor="dark-gray">{label}</TextH2>
      <Spacer size="m" />
      {children}
      {!removeDivider && <Divider padding="l" />}
    </section>
  );
};

export default ListingsDetailsContentBlock;
