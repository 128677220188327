import React from 'react';
import {
  FlexBox,
  IconFontAwesome,
  TextBody
} from '@directsoftware/ui-kit-web-admin';

const CardButtonItem = ({ label, iconName, onClick }) => {
  return (
    <FlexBox
      justifyContent="center"
      alignItems="center"
      className="cardButtonItem"
      gap="xs"
      renderAs={onClick ? 'button' : 'div'}
      onClick={onClick}
    >
      <IconFontAwesome name={iconName} />
      <TextBody>{label}</TextBody>
    </FlexBox>
  );
};

export default CardButtonItem;
