import React from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  PageStateMessage,
  Spacer,
  TextH2
} from '@directsoftware/ui-kit-web-admin';
import { selectCheckoutState } from '../../../redux/slices/checkout';
import CheckoutPricingInfo from './checkout-pricing-info';

const CheckoutMobilePricing = ({ updateCheckoutState }) => {
  const checkoutState = useSelector(selectCheckoutState);

  return !checkoutState.availabilityLoading ? (
    <>
      <TextH2>Your Total</TextH2>
      <Spacer size="xs" />
      {checkoutState.availability.bookable && checkoutState.pricing ? (
        <CheckoutPricingInfo updateCheckoutState={updateCheckoutState} />
      ) : (
        <Box padding="l">
          Sorry, this listing is not available for booking at this time.
        </Box>
      )}
      <Spacer size="l" />
    </>
  ) : (
    <PageStateMessage iconProps={{ name: 'spinner', spin: true }} />
  );
};

export default CheckoutMobilePricing;
