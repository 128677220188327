import React from 'react';
import { useSelector } from 'react-redux';
import {
  InputCheckbox,
  Spacer,
  TextH2
} from '@directsoftware/ui-kit-web-admin';
import { selectVerificationState } from '../../../redux/slices/verification';

const VerificationAge = ({ updateVerificationState }) => {
  const verificationState = useSelector(selectVerificationState);

  const updateAgeVerification = () => {
    updateVerificationState({
      age_verified: !verificationState.age_verified
    });
  };

  return (
    <>
      <TextH2>Confirm Your Age</TextH2>
      <Spacer />
      <InputCheckbox onChange={() => updateAgeVerification()}>
        {`By checking this box, I acknowledge that I am at least ${
          verificationState.required_age
        } years old.`}
      </InputCheckbox>
      <Spacer size="l" />
    </>
  );
};

export default VerificationAge;
