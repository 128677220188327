import React from 'react';
import { TextBody } from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import ReactI18n from 'react-i18n';
import ListingsDetailsContentBlock from './listings-details-content-block';
import ShowMoreWrapper from './show-more-wrapper';

const CancellationPolicy = ({ listingState }) => {
  const listing = listingState || useSelector(state => state.listing);
  const translate = ReactI18n.getIntlMessage;

  return (
    <ListingsDetailsContentBlock
      label={translate(`cx.details.headers.cancel_policy`)}
    >
      <ShowMoreWrapper>
        <TextBody isFullWidth textColor="dark-gray" weight="semibold">
          {translate(
            `global.refund_policy.${listing.refund_policy || 'full'}.label`
          )}
        </TextBody>
        <TextBody>
          {listing.refund_policy === 'custom' ? (
            <>{listing.refund_policy_custom}</>
          ) : (
            <>
              {translate(
                `global.refund_policy.${listing.refund_policy ||
                  'full'}.details`
              )}
            </>
          )}
        </TextBody>
      </ShowMoreWrapper>
    </ListingsDetailsContentBlock>
  );
};

export default CancellationPolicy;
