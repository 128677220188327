import React from 'react';
import {
  Box,
  IconFontAwesome,
  Panel,
  Spacer,
  TextBody,
  TextDense
} from '@directsoftware/ui-kit-web-admin';
import { getListingImageUrl } from '../resources/shared-functions';
import { renderPrice } from '../../../../shared/helpers';

const MapMarkerExpanded = ({ rate, name, listingInfo, href }) => {
  return (
    <Panel addShadow className="mapMarker__expanded">
      <Box setPositionRelative>
        <a
          href={href}
          target="_blank"
          className="searchGridItem__link"
          rel="noreferrer"
        />
        <Box
          className="searchGridItem__imageWrapper -widescreen"
          setPositionRelative
        >
          {!getListingImageUrl(listingInfo) ? (
            <Box className="searchGridItem__noImage">
              <IconFontAwesome name="image" />
            </Box>
          ) : (
            <img
              className="searchGridItem__image"
              alt=""
              src={getListingImageUrl(listingInfo)}
            />
          )}
        </Box>
        <Box padding="s">
          <TextBody weight="semibold" textColor="dark-gray" isFullWidth>
            {name}
          </TextBody>
          <Spacer size="xxs" />
          <TextDense weight="semibold" textColor="dark-gray">
            {renderPrice(listingInfo, true)}
          </TextDense>
        </Box>
      </Box>
    </Panel>
  );
};

export default MapMarkerExpanded;
