import React, { useEffect, useState } from 'react';
import { Spacer, TextH2 } from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import { filter, sortBy } from 'lodash';
import { selectCheckoutState } from '../../../redux/slices/checkout';
import FeeAddon from './fee-addon';

const ExtraAddons = ({ updateCheckoutState }) => {
  const checkoutState = useSelector(selectCheckoutState);
  const [sortedAddonFees, setSortedAddonFees] = useState([]);

  const showAddonFees = () => {
    return sortedAddonFees.length > 0;
  };

  useEffect(
    () => {
      if (checkoutState.fees) {
        const addonFees = filter(checkoutState.fees, ['is_addon', 'true']);
        const sortedAddonFees = sortBy(addonFees, ['name']);
        setSortedAddonFees(sortedAddonFees);
      }
    },
    [checkoutState.fees]
  );

  return showAddonFees() ? (
    <>
      <TextH2>Extra Add-ons</TextH2>
      <Spacer size="xs" />
      {sortedAddonFees.map((fee, index) => (
        <FeeAddon
          key={`add-on-${index}`}
          fee={fee}
          updateCheckoutState={updateCheckoutState}
        />
      ))}
      <Spacer size="l" />
    </>
  ) : null;
};

export default ExtraAddons;
