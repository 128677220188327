import React from 'react';
import {
  Box,
  CallToActionButton,
  Spacer,
  TextCaption
} from '@directsoftware/ui-kit-web-admin';
import ReactI18n from 'react-i18n';
import { useSelector } from 'react-redux';
import Link from '../links/link';

const CheckoutCtaV2 = props => {
  const brand = useSelector(state => state.brand);
  const translate = ReactI18n.getIntlMessage;

  return (
    <>
      {props.chargeAmount > 0 ? (
        <CallToActionButton
          onClick={() => props.handleSubmit()}
          size="large"
          isFullWidth
          isDisabled={props.disableCta}
          customButtonColor={brand.brand_info.colors?.color_primary}
          customTextColor={brand.brand_info.colors?.color_primary_text}
        >
          Process Payment
        </CallToActionButton>
      ) : (
        <CallToActionButton
          onClick={() => props.handleSubmit()}
          size="large"
          isFullWidth
          isDisabled={props.disableCta}
          customButtonColor={brand.brand_info.colors?.color_primary}
          customTextColor={brand.brand_info.colors?.color_primary_text}
        >
          {props.availability.instant_booking
            ? translate(`cx.global.book.long`)
            : translate(`cx.global.book_inquiry.long`)}
        </CallToActionButton>
      )}
      <Spacer size="m" />
      <TextCaption>
        <div
          dangerouslySetInnerHTML={{
            __html: translate(`cx.global.book_confirm.rules`, {
              property_url: `/listings/${props.slug}`
            })
          }}
        />
      </TextCaption>
      {props.rental_agreement ? (
        <>
          <Spacer />
          <TextCaption>
            <Box>{translate(`cx.global.book_confirm.contract`)}</Box>
            <Link to={props.rental_agreement.short_url} target="_blank">
              {translate(`cx.global.book_confirm.contract_link`)}
            </Link>
            .
          </TextCaption>
        </>
      ) : null}
    </>
  );
};

export default CheckoutCtaV2;
