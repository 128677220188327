import React from 'react';
import {
  Box,
  FlexBox,
  IconFontAwesome,
  TextBody,
  TextH2
} from '@directsoftware/ui-kit-web-admin';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useDetectMobile } from '../../../../shared/hooks/useDetectMobile';

const CheckInCheckOut = ({ checkInLabel, checkOutLabel }) => {
  const { isMobile } = useDetectMobile();
  const { default_time_check_in, default_time_check_out } = useSelector(
    state => state.listing.availability
  );

  return (
    <FlexBox flexDirection="row">
      <FlexBox flexDirection="column" flex="1" gap="xs">
        <FlexBox alignItems="center" gap="xs">
          {!isMobile && (
            <Box className="list-item-icon-size">
              <IconFontAwesome name="clock" iconStyle="regular" />
            </Box>
          )}
          {isMobile ? (
            <TextBody weight="semibold" textColor="dark-gray">
              {checkInLabel}
            </TextBody>
          ) : (
            <TextH2 textColor="dark-gray">{checkInLabel}</TextH2>
          )}
        </FlexBox>
        <TextBody>
          {moment(`1982-05-27 ${default_time_check_in}`).format('h:mm A')}
        </TextBody>
      </FlexBox>
      <FlexBox flexDirection="column" flex="1" gap="xs">
        <FlexBox alignItems="center" gap="xs">
          {isMobile ? (
            <TextBody weight="semibold" textColor="dark-gray">
              {checkOutLabel}
            </TextBody>
          ) : (
            <TextH2 textColor="dark-gray">{checkOutLabel}</TextH2>
          )}
        </FlexBox>
        <TextBody>
          {moment(`1982-05-27 ${default_time_check_out}`).format('h:mm A')}
        </TextBody>
      </FlexBox>
    </FlexBox>
  );
};

CheckInCheckOut.propTypes = {
  checkInLabel: PropTypes.string,
  checkOutLabel: PropTypes.string
};

CheckInCheckOut.defaultProps = {
  checkInLabel: 'Check-in Time',
  checkOutLabel: 'Check-out Time'
};

export default CheckInCheckOut;
