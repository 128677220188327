import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@directsoftware/ui-kit-web-admin';
import { useDispatch, useSelector } from 'react-redux';
import MapMarker from '../atoms/map-marker';
import MapMarkerExpanded from '../atoms/map-marker-expanded';
import useClickOutsideListener from '../../../../shared/hooks/useOutsideClickListener';
import { useDetectMobile } from '../../../../shared/hooks/useDetectMobile';
import {
  selectListingsSearchState,
  updateListingsSearchSlice
} from '../../../redux/slices/listingsSearch';

const SearchMapMarker = props => {
  const { isDesktop } = useDetectMobile();
  const dispatch = useDispatch();
  const { showMobileMarker } = useSelector(selectListingsSearchState);
  const button = useRef(null);
  const { isOutsideClick: controlOutsideClick } = useClickOutsideListener(
    button
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const translate = props.translate;
  const currency = props.result.currency;

  const toggleExpanded = e => {
    e.preventDefault();
    setIsExpanded(!isExpanded);
    if (!isDesktop) {
      dispatch(
        updateListingsSearchSlice({
          showMobileMarker: !showMobileMarker,
          mobileMarkerData: props.result
        })
      );
    }
  };

  useEffect(
    () => {
      if (isExpanded && controlOutsideClick) {
        if (isDesktop) setIsExpanded(false);
      }
    },
    [isExpanded, controlOutsideClick]
  );

  return (
    <Box setPositionRelative domRef={button}>
      <MapMarker
        listingId={parseInt(props.$dimensionKey)}
        onClick={e => toggleExpanded(e)}
        result={props.result}
      />
      {isExpanded && isDesktop && (
        <MapMarkerExpanded
          onClick={e => toggleExpanded(e)}
          name={props.result.property.name}
          href={`/listings/${props.result.slug +
            props.getStringifiedQueryString()}`}
          rate={translate(`global.parsers.currency.${currency}`, {
            value: Math.round(props.result.bookable_nightly_price)
          })}
          listingInfo={props.result}
        />
      )}
    </Box>
  );
};

export default SearchMapMarker;
