import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  availability: null,
  bookingType: null,
  checkInDate: null,
  checkOutDate: null,
  bookingRange: null,
  bookingLength: 0,
  datesParsed: false,
  guests: 1,
  pricing: null,
  addonFeeIds: [],
  couponCode: '',
  review_average: 0,
  reviews: 0
};

const listingsDetailsSingleSlice = createSlice({
  name: 'listingsDetailsSingle',
  initialState,
  reducers: {
    updateListingsDetailsSingleSlice: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetListingsDetailsSingleSlice: state => {
      return { ...state, ...initialState };
    }
  }
});

export const {
  updateListingsDetailsSingleSlice,
  resetListingsDetailsSingleSlice
} = listingsDetailsSingleSlice.actions;

export default listingsDetailsSingleSlice.reducer;

export const selectListingsDetailsSingleState = createSelector(
  state => state.listingsDetailsSingle,
  listingsDetailsSingle => listingsDetailsSingle
);
