import React, { useState } from 'react';
import {
  FormField,
  FlexBoxGrid,
  InfoPanel,
  Spacer,
  IconFontAwesome,
  Modal,
  Box,
  TextBody
} from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';

const HtmlContactForm = ({ reveal, closeOnClick, listingName }) => {
  const brand = useSelector(state => state.brand);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [emailAddress, setEmailAddress] = useState(null);
  const [message, setMessage] = useState(null);
  const [showSpacer, setShowSpacer] = useState(true);

  return (
    <Box className="contact-form-v2">
      <Modal
        reveal={reveal}
        size="form"
        closeOnClick={closeOnClick}
        title="Send Us a Message"
        variation="minimal"
      >
        <Modal.Content contentIsScrollable>
          <Box padding="s">
            {listingName && (
              <>
                <TextBody weight="semibold" textColor="dark-gray">
                  {`Inquiring About ${listingName}`}
                </TextBody>
                <Spacer />
              </>
            )}
            <FlexBoxGrid columns={2}>
              <FormField
                labelText="First Name"
                labelHtmlFor="first_name"
                inputProps={{
                  onChange: e => setFirstName(e.target.value),
                  value: firstName,
                  inputWidth: 'm',
                  removeBottomSpacer: true
                }}
              />
              <FormField
                labelText="Last Name"
                labelHtmlFor="last_name"
                inputProps={{
                  onChange: e => setLastName(e.target.value),
                  value: lastName,
                  inputWidth: 'm',
                  removeBottomSpacer: true
                }}
              />
            </FlexBoxGrid>
            <FormField
              labelText="Email Address"
              labelHtmlFor="email_address"
              inputProps={{
                onChange: e => setEmailAddress(e.target.value),
                value: emailAddress,
                type: 'email'
              }}
            />
            <FormField
              labelText="Your Message"
              labelHtmlFor="message"
              inputType="textarea"
              inputProps={{
                onChange: e => setMessage(e.target.value),
                value: message,
                rows: 5,
                useCharacterCount: true,
                characterCount: message?.length || 0,
                characterCountTotal: 500,
                maxLength: 500
              }}
            />
            {showSpacer && <Spacer />}
            <InfoPanel
              color="blue"
              headline="You may need to disable popup blockers"
              isDismissible
              icon={<IconFontAwesome name="infoCircle" />}
              onDismiss={() => setShowSpacer(false)}
            />
          </Box>
        </Modal.Content>
        <Modal.Footer
          primaryLabel="Send Message"
          primaryOnClick={() => {
            let subjectLine = `${firstName}%20${lastName}%20has%20a%20Booking%20Inquiry`;
            if (listingName)
              subjectLine = `${subjectLine}%20for%20${encodeURIComponent(
                listingName
              )}`;
            const body = `Contact Email: ${emailAddress}%0A%0A${message}`;

            window.open(
              `mailTo:${
                brand.contact.email
              }?subject=${subjectLine}&body=${body}`
            );
          }}
          primaryIsDisabled={
            !firstName || !lastName || !emailAddress || !message
          }
          secondaryOnClick={closeOnClick}
          secondaryLabel="Cancel"
          primaryCustomButtonColor={brand.brand_info.colors?.color_primary}
          primaryCustomTextColor={brand.brand_info.colors?.color_primary_text}
        />
      </Modal>
    </Box>
  );
};

HtmlContactForm.propTypes = {};

export default HtmlContactForm;
