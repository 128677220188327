import React, { useEffect } from 'react';
import {
  Box,
  Container,
  FlexBox,
  Spacer
} from '@directsoftware/ui-kit-web-admin';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useDetectMobile } from '../../../shared/hooks/useDetectMobile';
import Meta from './meta';
import PhotoGrid from './shared-v2/photo-grid';
import ListingName from './shared-v2/listing-name';
import ListingSubHeadline from './shared-v2/listing-subheadline';
import FooterFinal from '../layout/footer-final';
import FeatureList from './shared-v2/feature-list';
import DetailsTabNav from './shared-v2/details-tab-nav';
import CheckInCheckOut from './shared-v2/check-in-check-out';
import Description from './shared-v2/description';
import RentalRules from './shared-v2/rental-rules';
import ManagerInfo from './shared-v2/manager-info';
import LocationMap from './shared-v2/location-map';
import Reviews from './shared-v2/reviews';
import BookingSidebar from './shared-v2/booking-sidebar';
import BedroomsList from './shared-v2/bedrooms-list';
import BathroomsList from './shared-v2/bathrooms-list';
import TermsAndConditions from './shared-v2/terms-and-conditions';
import CancellationPolicy from './shared-v2/cancellation-policy';
import CheckInCheckOutPolicy from './shared-v2/check-in-check-out-policy';
import {
  selectListingsDetailsSingleState,
  updateListingsDetailsSingleSlice
} from '../../redux/slices/listingsDetailsSingle';
import { selectUiState, updateUiSlice } from '../../redux/slices/ui';
import {
  parseQuery,
  updateQueryString
} from '../listings/resources/shared-functions';

const RoomV2 = props => {
  const { isLargeDesktop, isMobile } = useDetectMobile();
  const dispatch = useDispatch();
  const brand = useSelector(state => state.brand);
  const listing = useSelector(state => state.listing);
  const listingsDetailsState = useSelector(selectListingsDetailsSingleState);
  const { listingsDetailsChanged } = useSelector(selectUiState);

  const updateDetailsSingleState = (data, isFilterChange = false) => {
    dispatch(updateListingsDetailsSingleSlice(data));
    if (isFilterChange)
      dispatch(updateUiSlice({ listingsDetailsChanged: true }));
  };

  const handleBrowserState = () => {
    const queryInfo = parseQuery(location.search, brand);

    updateDetailsSingleState(
      {
        bookingRange: queryInfo.bookingRange || null,
        bookingLength: queryInfo.bookingRange
          ? queryInfo.bookingRange.length - 1
          : 0,
        checkInDate: queryInfo.checkIn || null,
        checkOutDate: queryInfo.checkOut || null,
        guests: queryInfo.guests || 1,
        isDirty: true,
        datesParsed: true
      },
      true
    );
  };

  const checkAvailability = () => {
    const queryInfo = parseQuery(location.string, brand);
    const availabilityType = listing.room_type
      ? 'room_type_availability'
      : 'availability';

    axios
      .get(
        `${process.env.DIRECT_URL}/api/v2/listings/room/${
          listing.id
        }/${availabilityType}`,
        {
          headers: { 'Content-Type': 'application/json' },
          context: this,
          params: {
            unit_id: listing.unit.id,
            booking_range: JSON.stringify(listingsDetailsState.bookingRange),
            guests: queryInfo.guests
          }
        }
      )
      .then(response => {
        updateDetailsSingleState({ availability: response.data });
      })
      .catch(error => {
        console.log(error);
      });
  };

  const checkPricing = () => {
    const queryInfo = parseQuery(location.search, brand);

    axios
      .get(
        `${process.env.DIRECT_URL}/api/v2/listings/room/${listing.id}/pricing`,
        {
          headers: { 'Content-Type': 'application/json' },
          params: {
            booking_range: JSON.stringify(listingsDetailsState.bookingRange),
            num_guests: queryInfo.guests,
            addon_fee_ids: listingsDetailsState.addonFeeIds,
            coupon_code: listingsDetailsState.couponCode
          }
        }
      )
      .then(response => {
        updateDetailsSingleState({ pricing: response.data });
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(
    () => {
      if (listingsDetailsChanged) {
        dispatch(updateUiSlice({ listingsDetailsChanged: false }));
        if (listingsDetailsState.bookingRange) {
          checkAvailability();
          checkPricing();
          updateQueryString(listingsDetailsState);
        }
      }
    },
    [listingsDetailsState, listingsDetailsChanged]
  );

  useEffect(() => {
    handleBrowserState();
    updateDetailsSingleState({ room: props.listing?.room_type_id });
    window.onpopstate = handleBrowserState;
    if (window.location.hash) {
      const id = window.location.hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) element.scrollIntoView();
    }
  }, []);

  return (
    <>
      <Meta />
      {!isMobile && <Spacer size="m" />}
      <Container variation={isMobile ? 'full-width' : 'fluid-wls'}>
        <PhotoGrid />
      </Container>
      <Spacer size="m" />
      <Container variation="extended-wls">
        <FlexBox className="detailsLayout">
          <Box flex="1">
            <ListingName />
            <Spacer size="xxs" />
            <ListingSubHeadline />
            <Spacer />
            <FeatureList />
            {!isMobile && <Spacer size="m" />}
            <DetailsTabNav />
            <Spacer size="l" />
            <Box id="details-overview" />
            <CheckInCheckOut />
            <Spacer size="l" />
            <Description />
            {(listing.unit.num_bedrooms >= 0 ||
              listing.unit.num_sleep_in_beds >= 0) && <BedroomsList />}
            {listing.unit.num_bathrooms && <BathroomsList />}
            <TermsAndConditions />
            {listing.refund_policy && <CancellationPolicy />}
            {listing.availability.check_in_check_out_policy && (
              <CheckInCheckOutPolicy />
            )}
            {listing.property.summary_accommodations && <RentalRules />}
            {listing.property_manager && <ManagerInfo />}
          </Box>
          {isLargeDesktop ? (
            <Box className="detailsLayout__right">
              <Box className="detailsLayout__bookingSidebar">
                <BookingSidebar
                  updateDetailsSingleState={updateDetailsSingleState}
                />
              </Box>
            </Box>
          ) : (
            <>
              <Box className="detailsLayout__mobileFooter">
                <BookingSidebar
                  updateDetailsSingleState={updateDetailsSingleState}
                />
              </Box>
            </>
          )}
        </FlexBox>
        <Box id="details-location" />
        <LocationMap />
        {listing.reviews.length > 0 && (
          <>
            <Box id="details-reviews" />
            <Reviews />
          </>
        )}
        <Spacer size="l" />
      </Container>
      <FooterFinal />
    </>
  );
};

export default RoomV2;
