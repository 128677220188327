import React from 'react';
import ReactI18n from 'react-i18n';
import {
  FlexBox,
  FlexBoxGrid,
  IconFontAwesome,
  Spacer,
  TextBody
} from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';

const AmenitiesListGroup = ({
  features,
  features_label,
  removeBottomSpacer
}) => {
  const listing = useSelector(state => state.listing);
  const translate = ReactI18n.getIntlMessage;

  const getAmountAmenities = () => {
    let amountTrue = 0;
    Object.keys(features).map(featureKey => {
      if (features[featureKey].value) {
        amountTrue++;
      }
    });
    return amountTrue;
  };

  const renderFeature = (featureKey, label) => {
    return (
      <FlexBox key={featureKey} alignItems="center" gap="xs">
        <IconFontAwesome name="check" />
        <TextBody>
          {translate(`global.features.${label}.${featureKey}`)}
        </TextBody>
      </FlexBox>
    );
  };

  return (
    getAmountAmenities() > 0 && (
      <>
        <TextBody weight="semibold" textColor="dark-gray">
          {translate(`global.features.${features_label}.label`)}
        </TextBody>
        <Spacer />
        <FlexBoxGrid columns={listing.multi_unit ? 4 : 2}>
          {Object.keys(features)
            .filter(featureKey => features[featureKey].value)
            .map((featureKey, idx) =>
              renderFeature(featureKey, features_label)
            )}
        </FlexBoxGrid>
        {!removeBottomSpacer && <Spacer size="l" />}
      </>
    )
  );
};

export default AmenitiesListGroup;
