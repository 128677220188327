import React from 'react';
import {
  DisplayLineItem,
  Spacer,
  TextBody
} from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import ReactI18n from 'react-i18n';
import { formatCurrency } from '../../../../shared/helpers';
import { selectCheckoutState } from '../../../redux/slices/checkout';

const CheckoutPricingRates = () => {
  const checkoutState = useSelector(selectCheckoutState);
  const brand = useSelector(state => state.brand);
  const translate = ReactI18n.getIntlMessage;
  const nightlyPriceStyle =
    checkoutState.pricing.average_nightly_promotional_price <
    checkoutState.pricing.average_nightly_price;
  const promoSubtotal =
    checkoutState.pricing.promotional_subtotal < checkoutState.pricing.subtotal;
  const losDiscount =
    checkoutState.pricing.los_discount[0] &&
    checkoutState.pricing.los_discount[1] > 0;

  return (
    <>
      <TextBody weight="semibold" textColor="dark-gray">
        Rates and Fees
      </TextBody>
      <Spacer size="xs" />
      <DisplayLineItem
        label={`${formatCurrency(
          checkoutState.pricing.average_nightly_price,
          brand.currency
        )} avg. * ${translate(
          `global.parsers.num_nights.${
            checkoutState.bookingLength > 1 ? 'plural' : 'single'
          }`,
          { nights: checkoutState.bookingLength }
        )}`}
        value={formatCurrency(checkoutState.pricing.subtotal, brand.currency)}
        labelClassName={nightlyPriceStyle ? 'crossed-out-red-text' : null}
        valueClassName={nightlyPriceStyle ? 'crossed-out-red-text' : null}
        collapseOnMobile={false}
        padding="xxs"
        labelIsRegularWeight
        removeBottomBorder
      />
      {promoSubtotal && (
        <DisplayLineItem
          label={`${formatCurrency(
            checkoutState.pricing.average_nightly_promotional_price,
            brand.currency
          )} avg. * ${translate(
            `global.parsers.num_nights.${
              checkoutState.bookingLength > 1 ? 'plural' : 'single'
            }`,
            { nights: checkoutState.bookingLength }
          )}`}
          value={formatCurrency(
            checkoutState.pricing.promotional_subtotal,
            brand.currency
          )}
          collapseOnMobile={false}
          padding="xxs"
          labelIsRegularWeight
          removeBottomBorder
        />
      )}
      {losDiscount && (
        <DisplayLineItem
          label={`${translate(
            `global.parsers.discount.${checkoutState.pricing.los_discount[0]}`,
            {
              value: checkoutState.pricing.los_discount[1]
            }
          )}`}
          value={`- ${translate(`global.parsers.currency.${brand.currency}`, {
            value: parseFloat(checkoutState.pricing.los_discount[2]).toFixed(2)
          })}`}
          valueClassName="success-green-text"
          collapseOnMobile={false}
          padding="xxs"
          labelIsRegularWeight
          removeBottomBorder
        />
      )}
    </>
  );
};

export default CheckoutPricingRates;
