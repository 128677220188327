import React from 'react';
import { Box, FlexBox, Spacer } from '@directsoftware/ui-kit-web-admin';
import Skeleton from 'react-loading-skeleton';

const ListingsSearchGridItemSkeleton = () => {
  return (
    <FlexBox
      flexDirection="column"
      className="searchGridItem"
      setPositionRelative
    >
      <FlexBox
        className="searchGridItem__imageWrapper -skeleton"
        setPositionRelative
      >
        <Box className="searchGridItem__skeleton">
          <Skeleton className="searchGridItem__skeleton_image" />
        </Box>
      </FlexBox>
      <Spacer size="xs" />
      <Skeleton height={16} />
      <Spacer size="xxs" />
      <Skeleton height={14} width="50%" />
      <Skeleton height={14} width="45%" />
      <Spacer size="xs" />
      <Skeleton size={16} width="25%" />
    </FlexBox>
  );
};

export default ListingsSearchGridItemSkeleton;
