import React, { useEffect, useState } from 'react';
import {
  Box,
  CallToActionButton,
  DisplayLineItem,
  FlexBox,
  IconButton,
  IconFontAwesome,
  Modal
} from '@directsoftware/ui-kit-web-admin';
import ReactI18n from 'react-i18n';
import { useSelector } from 'react-redux';
import { isNull } from 'lodash';
import CouponModal from '../../listing/single/coupon-modal';
import { selectCheckoutState } from '../../../redux/slices/checkout';

const CheckoutPricingCouponCode = ({ updateCheckoutState }) => {
  const checkoutState = useSelector(selectCheckoutState);
  const brand = useSelector(state => state.brand);
  const [openCouponModal, setOpenCouponModal] = useState(false);
  const [couponAdded, setCouponAdded] = useState(false);
  const translate = ReactI18n.getIntlMessage;

  const addCouponCode = code => {
    updateCheckoutState({ couponCode: code, checkoutCheckPricing: true }, true);
  };

  const verifyCouponCode = () => {
    if (isNull(checkoutState.allCouponCodes)) {
      updateCheckoutState({ badCode: true });
      setCouponAdded(false);
    } else if (
      checkoutState.allCouponCodes.includes(
        checkoutState.couponCode.toLowerCase()
      )
    ) {
      addCouponCode(checkoutState.couponCode);
      setOpenCouponModal(false);
      setCouponAdded(true);
    } else {
      updateCheckoutState({ badCode: true });
      setCouponAdded(false);
    }
  };

  const updateCouponCode = couponCode => {
    updateCheckoutState({ couponCode, badCode: false });
  };

  useEffect(() => {
    updateCheckoutState({ badCode: false });
  }, []);

  return (
    <>
      {couponAdded && !checkoutState.badCode ? (
        <FlexBox gap="xs" alignItems="center">
          <Box flex="1">
            <DisplayLineItem
              label="Coupon Added"
              value={checkoutState.couponCode}
              removeBottomBorder
            />
          </Box>
          <IconButton
            onClick={() => {
              setCouponAdded(false);
              updateCheckoutState(
                { couponCode: '', checkoutCheckPricing: true },
                true
              );
            }}
            variation="secondary"
            appearance="ghost"
            size="dense"
          >
            <IconFontAwesome name="times" />
          </IconButton>
        </FlexBox>
      ) : (
        <CallToActionButton
          onClick={() => setOpenCouponModal(true)}
          appearance="ghost"
          isFullWidth
          prependIcon={<IconFontAwesome name="plus" />}
          customButtonColor={brand.brand_info.colors?.color_primary}
          customTextColor={brand.brand_info.colors?.color_primary_text}
        >
          {translate(`cx.details.apply_coupon`)}
        </CallToActionButton>
      )}
      <Modal
        reveal={openCouponModal}
        title="Apply Coupon"
        closeOnClick={() => {
          setOpenCouponModal(false);
        }}
        size="form"
      >
        <Modal.Content contentIsScrollable>
          <Box padding="s">
            <CouponModal
              badCode={checkoutState.badCode}
              updateCouponCode={updateCouponCode}
              useDesignKit
            />
          </Box>
        </Modal.Content>
        <Modal.Footer
          primaryOnClick={() => verifyCouponCode()}
          primaryLabel="Apply"
          primaryIsDisabled={
            !checkoutState.couponCode || checkoutState.couponCode === ''
          }
          secondaryOnClick={() => {
            setOpenCouponModal(false);
          }}
          secondaryLabel="Cancel"
          primaryCustomButtonColor={brand.brand_info.colors?.color_primary}
          primaryCustomTextColor={brand.brand_info.colors?.color_primary_text}
        />
      </Modal>
    </>
  );
};

export default CheckoutPricingCouponCode;
