import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import {
  Box,
  Container,
  FlexBox,
  Spacer,
  Divider
} from '@directsoftware/ui-kit-web-admin';
import {
  selectListingsDetailsSingleState,
  updateListingsDetailsSingleSlice
} from '../../redux/slices/listingsDetailsSingle';
import {
  parseQuery,
  updateQueryString
} from '../listings/resources/shared-functions';
import { selectUiState, updateUiSlice } from '../../redux/slices/ui';
import PhotoGrid from './shared-v2/photo-grid';
import FeatureList from './shared-v2/feature-list';
import DetailsTabNav from './shared-v2/details-tab-nav';
import ListingName from './shared-v2/listing-name';
import CheckInCheckOut from './shared-v2/check-in-check-out';
import Description from './shared-v2/description';
import DeleveryLocations from './vehicle/delivery-locations';
import BedroomsList from './shared-v2/bedrooms-list';
import TermsAndConditions from './shared-v2/terms-and-conditions';
import CancellationPolicy from './shared-v2/cancellation-policy';
import RentalRules from './shared-v2/rental-rules';
import LocationMap from './shared-v2/location-map';
import BookingSidebar from './shared-v2/booking-sidebar';
import Reviews from './shared-v2/reviews';
import { useDetectMobile } from '../../../shared/hooks/useDetectMobile';
import Meta from './meta';
import BookingDeposit from './shared-v2/booking-deposit';
import CheckInCheckOutPolicy from './shared-v2/check-in-check-out-policy';
import Details from './vehicle/details';
import FooterFinal from '../layout/footer-final';
import Usages from './vehicle/usages';

const VehicleV2 = () => {
  const { isLargeDesktop, isMobile } = useDetectMobile();
  const dispatch = useDispatch();
  const brand = useSelector(state => state.brand);
  const listing = useSelector(state => state.listing);
  const listingsDetailsState = useSelector(selectListingsDetailsSingleState);
  const { listingsDetailsChanged } = useSelector(selectUiState);

  const updateDetailsSingleState = (data, isFilterChange = false) => {
    dispatch(updateListingsDetailsSingleSlice(data));
    if (isFilterChange)
      dispatch(updateUiSlice({ listingsDetailsChanged: true }));
  };

  const handleBrowserState = () => {
    const queryInfo = parseQuery(location.search, brand);

    updateDetailsSingleState(
      {
        bookingRange: queryInfo.bookingRange || null,
        bookingLength: queryInfo.bookingRange
          ? queryInfo.bookingRange.length - 1
          : 0,
        checkInDate: queryInfo.checkIn || null,
        checkOutDate: queryInfo.checkOut || null,
        guests: queryInfo.guests || 1,
        isDirty: true,
        datesParsed: true
      },
      true
    );
  };

  const checkAvailability = () => {
    const queryInfo = parseQuery(location.string, brand);

    axios
      .get(
        `${process.env.DIRECT_URL}/api/v2/listings/single/${
          listing.id
        }/availability`,
        {
          headers: { 'Content-Type': 'application/json' },
          context: this,
          params: {
            vehicle_id: listing.vehicle.id,
            booking_range: JSON.stringify(listingsDetailsState.bookingRange),
            guests: queryInfo.guests
          }
        }
      )
      .then(response => {
        updateDetailsSingleState({ availability: response.data });
      })
      .catch(error => {
        console.log(error);
      });
  };

  const checkPricing = () => {
    axios
      .get(
        `${process.env.DIRECT_URL}/api/v2/listings/single/${
          listing.id
        }/pricing`,
        {
          headers: { 'Content-Type': 'application/json' },
          params: {
            booking_range: JSON.stringify(listingsDetailsState.bookingRange),
            num_guests: listingsDetailsState.guests,
            addon_fee_ids: listingsDetailsState.addonFeeIds,
            coupon_code: listingsDetailsState.couponCode,
            delivery_location: listingsDetailsState?.deliveryLocation
          }
        }
      )
      .then(response => {
        updateDetailsSingleState({ pricing: response.data });
      })
      .catch(error => {
        console.log(error);
      });
  };

  const hasBookingDeposit = deposits => {
    return deposits.filter(dep => dep.name === 'Booking Deposit').length > 0;
  };

  useEffect(
    () => {
      if (listingsDetailsChanged) {
        dispatch(updateUiSlice({ listingsDetailsChanged: false }));
        if (listingsDetailsState.bookingRange) {
          checkAvailability();
          checkPricing();
          updateQueryString(listingsDetailsState);
        }
      }
    },
    [listingsDetailsState, listingsDetailsChanged]
  );

  useEffect(() => {
    handleBrowserState();
    window.onpopstate = handleBrowserState;
    if (window.location.hash) {
      const id = window.location.hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) element.scrollIntoView();
    }
  }, []);

  return (
    <>
      <Meta />
      {!isMobile && <Spacer size="m" />}
      <Container variation={isMobile ? 'full-width' : 'fluid-wls'}>
        <PhotoGrid />
      </Container>
      <Spacer size="m" />
      <Container variation="extended-wls">
        <FlexBox className="detailsLayout">
          <Box flex="1">
            <ListingName />
            <Spacer size="xxs" />
            <Spacer />
            <FeatureList />
            {!isMobile && <Spacer size="m" />}
            <DetailsTabNav />
            <Spacer size="l" />
            <Box id="details-overview" />
            <CheckInCheckOut
              checkInLabel="Pick Up Time"
              checkOutLabel="Drop Off Time"
            />
            <Usages />
            <Divider padding="l" />
            <Description
              description={
                listing.vehicle.description ||
                listing.vehicle.description_plain_text
              }
            />
            <Details />
            {listing.vehicle.num_sleep_in_beds >= 0 && <BedroomsList />}
            {listing.vehicle.summary_rules && <TermsAndConditions />}
            <CancellationPolicy />
            {listing.deposits.length > 0 &&
              hasBookingDeposit(listing.deposits) && (
                <BookingDeposit vehicleDeposits={listing.deposits} />
              )}
            {listing.availability.check_in_check_out_policy && (
              <CheckInCheckOutPolicy />
            )}
            {listing.vehicle.summary_accommodations && <RentalRules />}
          </Box>
          {isLargeDesktop ? (
            <Box className="detailsLayout__right">
              <Box className="detailsLayout__bookingSidebar">
                <BookingSidebar
                  updateDetailsSingleState={updateDetailsSingleState}
                />
              </Box>
            </Box>
          ) : (
            <>
              <Box className="detailsLayout__mobileFooter">
                <BookingSidebar
                  updateDetailsSingleState={updateDetailsSingleState}
                />
              </Box>
            </>
          )}
        </FlexBox>
        <Box id="details-location" />
        {listing.delivery_locations.length > 0 && <DeleveryLocations />}
        <LocationMap />
        {listing.reviews.length > 0 && (
          <>
            <Box id="details-reviews" />
            <Reviews />
          </>
        )}
        <Spacer size="l" />
      </Container>
      <FooterFinal />
    </>
  );
};

export default VehicleV2;
