import React from 'react';
import Checkout from './index';
import { useSelector } from 'react-redux';
import { selectUiState } from '../../redux/slices/ui';
import CheckoutV2 from './checkout-v2';

const CheckoutSwitch = (props) => {
  const ui = useSelector(selectUiState);

  return ui.isBookingEngine ? <CheckoutV2 {...props} /> : <Checkout {...props} />;
};

export default CheckoutSwitch;
