import React from 'react';
import {
  Box,
  Divider,
  FlexBox,
  IconFontAwesome,
  TextDense
} from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ReactI18n from 'react-i18n';
import { selectCheckoutState } from '../../../redux/slices/checkout';

const CheckoutListingInfo = () => {
  const checkoutState = useSelector(selectCheckoutState);
  const translate = ReactI18n.getIntlMessage;

  const renderName = () => {
    if (checkoutState.unit.room_type_name) {
      return `${checkoutState.unit.room_type_name} | ${
        checkoutState.property.name
      } `;
    } else if (checkoutState.property.multi_unit) {
      return `${checkoutState.property.name} | ${checkoutState.unit.name}`;
    } else {
      return checkoutState.property.name;
    }
  };

  const buildDetailsLink = () => {
    let link = `/listings/${checkoutState.slug}?`;
    if (checkoutState.checkInDate) {
      link += `&check-in=${moment(checkoutState.checkInDate).format(
        'DD-MM-YYYY'
      )}`;
    }
    if (checkoutState.checkOutDate) {
      link += `&check-out=${moment(checkoutState.checkOutDate).format(
        'DD-MM-YYYY'
      )}`;
    }
    if (checkoutState.guests) {
      link += `&guests=${checkoutState.guests}`;
    }
    return link;
  };

  const renderFeatures = () => {
    let featureString = '';

    if (checkoutState.unit.num_sleep)
      featureString += `${translate('cx.global.amenities.sleeps')} ${
        checkoutState.unit.num_sleep
      } • `;
    if (checkoutState.unit.num_bedrooms) {
      const translation =
        checkoutState.unit.num_bedrooms > 1
          ? translate('cx.global.amenities.beds')
          : translate('cx.global.amenities.bed');
      featureString += `${checkoutState.unit.num_bedrooms} ${translation} • `;
    }
    if (checkoutState.unit.num_bathrooms) {
      const translation =
        checkoutState.unit.num_bathrooms > 1
          ? translate('cx.global.amenities.baths')
          : translate('cx.global.amenities.bath');
      featureString += `${checkoutState.unit.num_bathrooms} ${translation} • `;
    }

    return featureString === '' ? null : (
      <TextDense>{featureString.slice(0, -3)}</TextDense>
    );
  };

  return (
    <>
      <Box padding="s">
        <FlexBox gap="s">
          <FlexBox flexShrink="0">
            <Link to={buildDetailsLink()}>
              {checkoutState.featuredImage ? (
                <img
                  src={checkoutState.featuredImage.small}
                  alt=""
                  className="checkout__listingInfo_img"
                />
              ) : (
                <FlexBox className="checkout__listingInfo_img">
                  <IconFontAwesome name="image" />
                </FlexBox>
              )}
            </Link>
          </FlexBox>
          <FlexBox flexDirection="column">
            <Box flex="1">
              <Link to={buildDetailsLink()}>
                <TextDense isFullWidth textColor="dark-gray" weight="semibold">
                  {renderName()}
                </TextDense>
              </Link>
              <TextDense>{checkoutState.obfuscatedAddress}</TextDense>
            </Box>
            {renderFeatures()}
          </FlexBox>
        </FlexBox>
      </Box>
      <Divider />
    </>
  );
};

export default CheckoutListingInfo;
