import React from 'react';
import {
  Box,
  CallToActionButton,
  FlexBox,
  Panel,
  Spacer,
  TextBody,
  TextH2
} from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import { selectCheckoutState } from '../../../redux/slices/checkout';
import { selectPaymentState } from '../../../redux/slices/payment';

const CheckoutGuestInfoCollapsedV2 = ({ editOnClick, usePaymentState }) => {
  const guestInfoState = usePaymentState
    ? useSelector(selectPaymentState)
    : useSelector(selectCheckoutState);

  return (
    <>
      <FlexBox>
        <Box flex="1">
          <TextH2>Your Contact Info</TextH2>
        </Box>
        <Box>
          <CallToActionButton onClick={editOnClick} appearance="ghost">
            Edit
          </CallToActionButton>
        </Box>
      </FlexBox>
      <Spacer size="s" />
      <Spacer size="xxs" />
      <Panel padding="s">
        <TextBody isFullWidth weight="semibold">
          {`${guestInfoState.customerFirstName} ${
            guestInfoState.customerLastName
          }`}
        </TextBody>
        <TextBody isFullWidth>{guestInfoState.customerEmail}</TextBody>
        <TextBody isFullWidth>{guestInfoState.customerTelephone}</TextBody>
        <Spacer />
        <TextBody isFullWidth>
          {`${guestInfoState.adrStreet} ${
            guestInfoState.adrUnit ? `, ${guestInfoState.adrunit}` : ''
          }`}
        </TextBody>
        <TextBody isFullWidth>
          {`${guestInfoState.adrCity}, ${guestInfoState.adrState} ${
            guestInfoState.adrPostalCode
          }`}
        </TextBody>
        <TextBody>{guestInfoState.adrCountry}</TextBody>
      </Panel>
      <Spacer size="l" />
    </>
  );
};

export default CheckoutGuestInfoCollapsedV2;
