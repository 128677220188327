import React from 'react';
import { useSelector } from 'react-redux';
import { selectUiState } from '../../redux/slices/ui';
import Receipt from './index';
import ReceiptV2 from './receipt-v2';

const ReceiptSwitch = props => {
  const ui = useSelector(selectUiState);

  return ui.isBookingEngine ? <ReceiptV2 {...props} /> : <Receipt {...props} />;
};

export default ReceiptSwitch;
