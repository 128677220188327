import React from 'react';
import {
  Box,
  Divider,
  FlexBox,
  Panel,
  Spacer
} from '@directsoftware/ui-kit-web-admin';
import Skeleton from 'react-loading-skeleton';

const CheckoutRightSkeleton = () => {
  return (
    <Panel addShadow>
      <Box padding="s">
        <FlexBox gap="s">
          <Box>
            <Skeleton width={130} height={104} />
          </Box>
          <Box flex="1">
            <Skeleton height={16} />
            <Skeleton height={14} width="50%" />
          </Box>
        </FlexBox>
      </Box>
      <Divider />
      <Box padding="s">
        <Skeleton height={16} width={100} />
        <Spacer />
        <FlexBox justifyContent="space-between">
          <Box>
            <Skeleton height={16} width={150} />
          </Box>
          <Box>
            <Skeleton height={16} width={150} />
          </Box>
        </FlexBox>
        <Spacer size="xs" />
        <FlexBox justifyContent="space-between">
          <Box>
            <Skeleton height={16} width={150} />
          </Box>
          <Box>
            <Skeleton height={16} width={150} />
          </Box>
        </FlexBox>
        <Spacer size="xs" />
        <FlexBox justifyContent="space-between">
          <Box>
            <Skeleton height={16} width={150} />
          </Box>
          <Box>
            <Skeleton height={16} width={150} />
          </Box>
        </FlexBox>
      </Box>
      <Divider />
      <Box padding="s">
        <FlexBox justifyContent="space-between">
          <Box>
            <Skeleton height={16} width={150} />
          </Box>
          <Box>
            <Skeleton height={16} width={150} />
          </Box>
        </FlexBox>
      </Box>
      <Divider />
      <Box padding="s">
        <FlexBox justifyContent="space-between">
          <Box>
            <Skeleton height={21} width={100} />
          </Box>
          <Box>
            <Skeleton height={21} width={100} />
          </Box>
        </FlexBox>
      </Box>
    </Panel>
  );
};

export default CheckoutRightSkeleton;
