import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  checkIn: null,
  checkOut: null,
  bookingLength: 0,
  bookingRange: null,
  datesParsed: false,
  guests: 1,
  pricing: null,
  addonFeeIds: [],
  availabilities: {},
  pricings: {},
  unitDataForModal: null
};

const listingsDetailsMultiSlice = createSlice({
  name: 'listingsDetailsMulti',
  initialState,
  reducers: {
    updateListingsDetailsMultiSlice: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetListingsDetailsMultiSlice: state => {
      return { ...state, ...initialState };
    }
  }
});

export const {
  updateListingsDetailsMultiSlice,
  resetListingsDetailsMultiSlice
} = listingsDetailsMultiSlice.actions;

export default listingsDetailsMultiSlice.reducer;

export const selectListingsDetailsMultiState = createSelector(
  state => state.listingsDetailsMulti,
  listingsDetailsMulti => listingsDetailsMulti
);
