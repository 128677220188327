import React, { useEffect, useState } from 'react';
import {
  Box,
  FlexBox,
  IconFontAwesome,
  IconLink,
  Spacer,
  TextCaption,
  TextH1,
  TextH2
} from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { selectUiState } from '../../../redux/slices/ui';
import { checkoutSteps } from '../../../../shared/data';
import { useDetectMobile } from '../../../../shared/hooks/useDetectMobile';
import { selectCheckoutState } from '../../../redux/slices/checkout';
import LegacyCheckoutHeader from '../legacy-checkout-header';
import { renderMobileCheckoutSteps } from '../../../../shared/helpers';

const CheckoutSteps = ({ removeBottomSpacer }) => {
  const { isDesktop } = useDetectMobile();
  const brand = useSelector(state => state.brand);
  const { checkoutActiveStep, isBookingEngine } = useSelector(selectUiState);
  const steps = Object.keys(checkoutSteps);
  const checkoutState = useSelector(selectCheckoutState);
  const [listingUrl, setListingUrl] = useState('/');

  useEffect(() => {
    if (checkoutActiveStep === 'stepOne') {
      setListingUrl(`/listings/${checkoutState.slug}${location.search}`);
    }
  }, []);

  return !isBookingEngine ? (
    <LegacyCheckoutHeader
      checkoutActiveStep={checkoutActiveStep}
      steps={steps}
    />
  ) : (
    <>
      {isDesktop && <Spacer size="m" />}
      <FlexBox
        className="checkoutSteps__navbar"
        justifyContent="space-between"
        alignItems="center"
      >
        <FlexBox gap="xs" alignItems="center">
          {checkoutActiveStep === 'stepOne' && (
            <IconLink
              href={listingUrl}
              appearance="ghost"
              customButtonColor={brand.brand_info.colors?.color_primary}
              customTextColor={brand.brand_info.colors?.color_primary_text}
            >
              <IconFontAwesome name="arrowLeft" />
            </IconLink>
          )}
          {isDesktop ? (
            <TextH1>{checkoutSteps[checkoutActiveStep].label}</TextH1>
          ) : (
            <TextH2>{checkoutSteps[checkoutActiveStep].label}</TextH2>
          )}
        </FlexBox>
        {isDesktop ? (
          <FlexBox gap="s">
            {steps.map((step, idx) => {
              return (
                <FlexBox
                  key={`step-${idx}`}
                  gap="xs"
                  alignItems="center"
                  className={classnames('checkoutSteps__button', {
                    isActive: checkoutActiveStep === step
                  })}
                >
                  <FlexBox
                    className="checkoutSteps__step"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <TextCaption className="checkoutSteps__step_text">
                      {idx + 1}
                    </TextCaption>
                  </FlexBox>
                  <TextCaption textTransform="uppercase">
                    {checkoutSteps[step].label}
                  </TextCaption>
                </FlexBox>
              );
            })}
            <FlexBox />
          </FlexBox>
        ) : (
          <Box className="checkoutSteps__mobileSteps">
            {renderMobileCheckoutSteps(checkoutActiveStep, steps.length)}
          </Box>
        )}
      </FlexBox>
      {!removeBottomSpacer && <Spacer size="m" />}
    </>
  );
};

export default CheckoutSteps;
