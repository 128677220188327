import React, { useState } from 'react';
import {
  Box,
  CallToActionButton,
  Divider,
  FlexBox,
  IconFontAwesome,
  Panel,
  Spacer,
  TextBody,
  TextDense
} from '@directsoftware/ui-kit-web-admin';
import PropTypes from 'prop-types';

const IconCardItem = ({ iconNames, headline, listItems, description }) => {
  const [showDescription, setShowDescription] = useState(false);

  return (
    <Panel padding="s" className="iconCardItem">
      <FlexBox flexDirection="column" className="iconCardItem__content">
        <Box flex="1">
          {iconNames && iconNames.length > 0 && (
            <FlexBox className="iconCardItem__icon" alignItems="center">
              {iconNames.map((icon, idx) => {
                return icon?.useOldIcon ? (
                  <Box className="iconCardItem__oldIcon">{icon.name}</Box>
                ) : (
                  <IconFontAwesome
                    name={icon.name || icon}
                    iconStyle="regular"
                    key={`icon-${idx}`}
                  />
                );
              })}
            </FlexBox>
          )}
          {headline && (
            <TextBody
              className="iconCardItem__headline"
              weight="semibold"
              textColor="dark-gray"
              truncate
            >
              {headline}
            </TextBody>
          )}
          {listItems && listItems.length > 0 && (
            <Box renderAs="ul" className="iconCardItem__list">
              {listItems.map((item, idx) => (
                <Box renderAs="li" key={`item-${idx}`}>
                  <TextBody className="iconCardItem__list_item">
                    {item}
                  </TextBody>
                </Box>
              ))}
            </Box>
          )}
        </Box>
        {description && (
          <Box>
            <Divider padding="s" />
            {showDescription && (
              <>
                <TextDense>{description}</TextDense>
                <Spacer size="xs" />
              </>
            )}
            <CallToActionButton
              onClick={() => setShowDescription(!showDescription)}
              size="dense"
              className="iconCardItem__cta"
            >
              {showDescription ? 'Hide' : 'Read More'}
            </CallToActionButton>
          </Box>
        )}
      </FlexBox>
    </Panel>
  );
};

IconCardItem.propTypes = {
  iconNames: PropTypes.array,
  headline: PropTypes.string,
  listItems: PropTypes.array
};

export default IconCardItem;
