import React from 'react';
import { useSelector } from 'react-redux';
import { pickBy } from 'lodash';
import { CheckboxGrid } from '@directsoftware/ui-kit-web-admin';
import amenitiesList from '../resources/amenities_list.json';
import { selectListingsSearchState } from '../../../redux/slices/listingsSearch';
import { useDetectMobile } from '../../../../shared/hooks/useDetectMobile';

const ListingsSearchAmenities = ({ updateSearchState }) => {
  const { isMobile } = useDetectMobile();
  const amenitiesFilterFromState = useSelector(
    state => state.brand.settings.amenities_filter
  );
  const { selectedAmenities } = useSelector(selectListingsSearchState);
  const amenities = pickBy(
    amenitiesList,
    (_value, key) => amenitiesFilterFromState[key]
  );
  const brand = useSelector(state => state.brand);

  const onAmenityClick = amenity => {
    const currentIndex = selectedAmenities.indexOf(amenity);
    if (currentIndex === -1) {
      updateSearchState({
        selectedAmenities: [...selectedAmenities, amenity],
        isDirty: true
      });
    } else {
      const selectedAmenitiesClone = [...selectedAmenities];
      selectedAmenitiesClone.splice(currentIndex, 1);
      updateSearchState({
        selectedAmenities: selectedAmenitiesClone,
        isDirty: true
      });
    }
  };

  return (
    <>
      <CheckboxGrid
        customColor={brand.brand_info.colors?.color_primary}
        checkboxes={Object.keys(amenities)
          .sort()
          .map(amenity => {
            return {
              label: amenity,
              value: amenity,
              onChange: () => onAmenityClick(amenity),
              checked:
                selectedAmenities.length > 0 &&
                selectedAmenities.includes(amenity)
            };
          })}
        numberOfColumns={isMobile ? 1 : 2}
      />
    </>
  );
};

export default ListingsSearchAmenities;
