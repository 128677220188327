import React from 'react';
import { Box } from '@directsoftware/ui-kit-web-admin';
import Skeleton from 'react-loading-skeleton';

const PhotoGridSkeleton = () => {
  return (
    <Box className="photoGrid__wrapper" setPositionRelative>
      <Box className="photoGrid__wrapper_inside" setPositionRelative>
        <Box className="photoGrid__wrapper_row">
          <Box className="photoGrid__colLarge -skeleton">
            <Box className="photoGrid__item">
              <Skeleton height="100%" />
            </Box>
          </Box>
          <Box className="photoGrid__colSmall -middle">
            <Box className="photoGrid__imageWrapper">
              <Box className="photoGrid__imageWrapper_image -skeleton">
                <Box>
                  <Box className="photoGrid__item">
                    <Skeleton height="100%" />
                  </Box>
                </Box>
              </Box>
              <Box className="photoGrid__imageWrapper_image -skeleton">
                <Box className="photoGrid__item">
                  <Skeleton height="100%" />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="photoGrid__colSmall -right">
            <Box className="photoGrid__imageWrapper">
              <Box className="photoGrid__imageWrapper_image -skeleton">
                <Box className="photoGrid__item">
                  <Skeleton height="100%" />
                </Box>
              </Box>
              <Box className="photoGrid__imageWrapper_image -skeleton">
                <Box className="photoGrid__item">
                  <Skeleton height="100%" />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PhotoGridSkeleton;
