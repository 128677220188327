import React from 'react';
import { Spacer, TextBody, TextH2 } from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import { selectVerificationState } from '../../../redux/slices/verification';
import IdPhotoUploader from '../../inputs/id-photo-uploader';

const VerificationId = ({ updateVerificationState }) => {
  const verificationState = useSelector(selectVerificationState);

  return (
    <>
      <TextH2>Photo ID</TextH2>
      <Spacer />
      <TextBody>
        {verificationState.verify_id_description ||
          'Please upload a valid ID to confirm the booking.'}
      </TextBody>
      <Spacer />
      <IdPhotoUploader
        booking={verificationState.booking}
        afterPhotoDelete={() => updateVerificationState({ idPhoto: null })}
        afterUpload={photo => updateVerificationState({ idPhoto: photo })}
      />
      <Spacer size="l" />
    </>
  );
};

export default VerificationId;
