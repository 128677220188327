import React from 'react';

const Crib = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        enableBackground: 'new 0 0 512 512'
      }}
      viewBox="0 0 512 512"
      {...props}
    >
      <path d="M482 0c-16.542 0-30 13.458-30 30v15H60V30C60 13.458 46.542 0 30 0S0 13.458 0 30v474.5a7.5 7.5 0 0 0 7.5 7.5h45a7.5 7.5 0 0 0 7.5-7.5v-106a7.5 7.5 0 0 0-15 0V497H15V376h482v121h-30v-98.5a7.5 7.5 0 0 0-15 0v106a7.5 7.5 0 0 0 7.5 7.5h45a7.5 7.5 0 0 0 7.5-7.5V30c0-16.542-13.458-30-30-30zM102.4 241H82.5a37.297 37.297 0 0 0-22.5 7.521V105h42.4zM60 278.5c0-12.407 10.093-22.5 22.5-22.5h19.9v60H60zM117.4 105h15v211h-15zm72.4 136h-42.4V105h42.4zm-42.4 15h42.4v60h-42.4zm57.4-151h15v211h-15zm72.4 136h-42.4V105h42.4zm-42.4 15h42.4v60h-42.4zm57.4-151h15v211h-15zm72.4 136h-42.4V105h42.4zm-42.4 15h42.4v60h-42.4zm57.4-151h15v211h-15zm30 0H452v143.521A37.303 37.303 0 0 0 429.5 241h-19.9zm0 151h19.9c12.407 0 22.5 10.093 22.5 22.5V316h-42.4zM452 60v30H60V60zM15 361V30c0-8.271 6.729-15 15-15s15 6.729 15 15v293.5a7.5 7.5 0 0 0 7.5 7.5h407a7.5 7.5 0 0 0 7.5-7.5V30c0-8.271 6.729-15 15-15s15 6.729 15 15v331z" />
    </svg>
  );
};

export default Crib;
