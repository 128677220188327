import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  checkIn: null,
  checkOut: null,
  geoNELat: null,
  geoNELon: null,
  geoSWLat: null,
  geoSWLon: null,
  loc: null,
  min_price: null,
  max_price: null,
  guests: 1,
  sort: null,
  searchWithMap: false,
  filter_numBedrooms: 0,
  filter_numBathrooms: 1,
  filter_instantBook: false,
  filter_priceLow: null,
  filter_priceHigh: null,
  zoom: null,
  results: null,
  resultsLength: null,
  filteredResults: null,
  isDirty: false,
  isLoading: false,
  isLoaded: false,
  mapsLoaded: false,
  mapsLoading: true,
  filter_distance: 0,
  pageOffset: 0,
  totalPages: 1,
  currentPage: 0,
  totalProperties: 0,
  filtersSet: false,
  datesSet: false,
  instantBookingOnly: false,
  sortVisible: false,
  amenities: {},
  selectedAmenities: [],
  view: 'map',
  maxBaths: null,
  maxBedrooms: null,
  maxGuests: null,
  showMobileMarker: false,
  mobileMarkerData: null,
  initialBrowserQuery: true
};

const listingsSearchSlice = createSlice({
  name: 'listingsSearch',
  initialState,
  reducers: {
    updateListingsSearchSlice: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetListingsSearchSlice: state => {
      return { ...state, ...initialState };
    }
  }
});

export const {
  updateListingsSearchSlice,
  resetListingsSearchSlice
} = listingsSearchSlice.actions;

export default listingsSearchSlice.reducer;

export const selectListingsSearchState = createSelector(
  state => state.listingsSearch,
  listingsSearch => listingsSearch
);
