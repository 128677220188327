import React from 'react';

const CheckoutGuestInfoCollapsed = props => {
  return (
    <div className="guestInfoPlaceholder">
      <div className="guestInfoPlaceholder__guest">
        <div>
          <strong>
            {`${props.customerFirstName} ${props.customerLastName}`}
          </strong>
        </div>
        <div>{props.customerEmail}</div>
        <div>{props.customerTelephone}</div>
        <div style={{ height: 16 }} />
        <div>
          {`${props.adrStreet} ${props.adrUnit ? `, ${props.adrUnit}` : ''}`}
        </div>
        <div>
          {`${props.adrCity}, ${props.adrState} ${props.adrPostalCode}`}
        </div>
        <div>{props.adrCountry}</div>
      </div>
      <div>
        <button
          onClick={props.showGuestInfoForm}
          className="guestInfoPlaceholder__editButton"
        >
          Edit
        </button>
      </div>
    </div>
  );
};

export default CheckoutGuestInfoCollapsed;
