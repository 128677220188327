import React from 'react';
import ReactI18n from 'react-i18n';
import { useSelector } from 'react-redux';
import {
  Box,
  Divider,
  FlexBox,
  IconFontAwesome,
  TextDense
} from '@directsoftware/ui-kit-web-admin';
import { Link } from 'react-router-dom';
import { selectVerificationState } from '../../../redux/slices/verification';

const VerificationListingInfo = () => {
  const verificationState = useSelector(selectVerificationState);
  const translate = ReactI18n.getIntlMessage;

  const renderName = () => {
    return verificationState.property.multi_unit
      ? `${verificationState.property.name} | ${verificationState.unit.name}`
      : verificationState.property.name;
  };

  const renderAddress = () => {
    const loc = verificationState.location;
    const address = [];
    if (loc.adr_street) {
      address.push(loc.adr_street);
    }
    if (loc.adr_unit) {
      address.push(loc.adr_unit);
    }
    if (loc.adr_city) {
      address.push(loc.adr_city);
    }
    if (loc.adr_state) {
      address.push(loc.adr_state);
    }
    if (loc.adr_country) {
      address.push(loc.adr_country);
    }
    if (loc.adr_postal_code) {
      address.push(loc.adr_postal_code);
    }
    return address.join(', ');
  };

  const renderFeatures = () => {
    let featureString = '';

    if (verificationState.unit.num_sleep)
      featureString += `${translate('cx.global.amenities.sleeps')} ${
        verificationState.unit.num_sleep
      } • `;
    if (verificationState.unit.num_bedrooms) {
      const translation =
        verificationState.unit.num_bedrooms > 1
          ? translate('cx.global.amenities.beds')
          : translate('cx.global.amenities.bed');
      featureString += `${
        verificationState.unit.num_bedrooms
      } ${translation} • `;
    }
    if (verificationState.unit.num_bathrooms) {
      const translation =
        verificationState.unit.num_bathrooms > 1
          ? translate('cx.global.amenities.baths')
          : translate('cx.global.amenities.bath');
      featureString += `${
        verificationState.unit.num_bathrooms
      } ${translation} • `;
    }

    return featureString === '' ? null : (
      <TextDense>{featureString.slice(0, -3)}</TextDense>
    );
  };

  return (
    <>
      <Box padding="s">
        <FlexBox gap="s">
          <FlexBox flexShrink="0">
            <Link to={`/listings/${verificationState.slug}`}>
              {verificationState.featured_image ? (
                <img
                  src={verificationState.featured_image?.image?.small?.url}
                  alt=""
                  className="checkout__listingInfo_img"
                />
              ) : (
                <FlexBox className="checkout__listingInfo_img">
                  <IconFontAwesome name="image" />
                </FlexBox>
              )}
            </Link>
          </FlexBox>
          <FlexBox flexDirection="column">
            <Box flex="1">
              <Link to={`/listings/${verificationState.slug}`}>
                <TextDense isFullWidth textColor="dark-gray" weight="semibold">
                  {renderName()}
                </TextDense>
              </Link>
              <TextDense>{renderAddress()}</TextDense>
            </Box>
            {renderFeatures()}
          </FlexBox>
        </FlexBox>
      </Box>
      <Divider />
    </>
  );
};

export default VerificationListingInfo;
