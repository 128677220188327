import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactI18n from 'react-i18n';
import {
  Box,
  CallToActionButton,
  Divider,
  FlexBox,
  InputIncrementer,
  Spacer,
  TextDense,
  InputSelect,
  InputButtonSelect
} from '@directsoftware/ui-kit-web-admin';
import { isInclusivelyBeforeDay } from 'react-dates';
import { selectListingsDetailsSingleState } from '../../../redux/slices/listingsDetailsSingle';
import BookingDatePicker from '../single/booking-date-picker';
import BookingBreakdownV2 from './booking-breakdown-v2';
import { updateUiSlice } from '../../../redux/slices/ui';
import ReviewsAverage from './reviews-average';
import PriceAverage from './price-average';
import DeliveryLocationForm from '../../forms/delivery-location-form';

const BookingDetailsBreakdown = ({ updateDetailsSingleState }) => {
  const dispatch = useDispatch();
  const listingsDetailsState = useSelector(selectListingsDetailsSingleState);
  const listing = useSelector(state => state.listing);
  const brand = useSelector(state => state.brand);
  const translate = ReactI18n.getIntlMessage;

  const [locationType, setLocationType] = useState('single');

  const respondToDatesChange = (checkInDate, checkOutDate) => {
    if (isInclusivelyBeforeDay(checkInDate, checkOutDate)) {
      const bookingRange = [];
      const d = checkInDate.clone();
      while (isInclusivelyBeforeDay(d, checkOutDate)) {
        bookingRange.push({
          key: d.format('DD-MM-YYYY'),
          day: d.day()
        });
        d.add(1, 'days');
      }

      updateDetailsSingleState(
        {
          availability: null,
          bookingType: null,
          bookingRange,
          bookingLength: bookingRange.length - 1,
          datesParsed: true,
          pricing: null,
          checkInDate,
          checkOutDate
        },
        true
      );
    }
  };

  const respondToGuestsChange = guests => {
    updateDetailsSingleState(
      {
        availability: null,
        bookingType: null,
        pricing: null,
        guests
      },
      true
    );
  };

  const respondToDeliveryLocationChange = loc => {
    updateDetailsSingleState(
      {
        availability: null,
        bookingType: null,
        pricing: null,
        updateUrl: true,
        deliveryLocation: loc.value
      },
      true
    );
  };

  const renderDeliveryLocation = () => {
    if (listing.delivery_locations?.length > 0 && listing.vehicle.delivery) {
      return (
        <>
          <Spacer />
          <FlexBox alignItems="center" justifyContent="space-between">
            <TextDense weight="semibold" textColor="dark-gray">
              {translate(
                `cx.global.details.booking_details.delivery_location.${locationType}`
              )}
            </TextDense>
            <InputButtonSelect removeBottomSpacer>
              <InputButtonSelect.Button
                isDisabled={locationType === 'preset'}
                onClick={() => setLocationType('preset')}
              >
                {translate('cx.global.rv_fleet.preset')}
              </InputButtonSelect.Button>
              <InputButtonSelect.Button
                isDisabled={locationType === 'custom'}
                onClick={() => setLocationType('custom')}
              >
                {translate('cx.global.rv_fleet.custom')}
              </InputButtonSelect.Button>
            </InputButtonSelect>
          </FlexBox>
          {locationType === 'preset' && (
            <>
              <Spacer />
              <InputSelect
                options={listing.delivery_locations.map(loc => ({
                  label: loc.name,
                  value: loc.id
                }))}
                onChange={respondToDeliveryLocationChange}
              />
            </>
          )}
          {locationType === 'custom' && <Spacer />}
          <div>
            <DeliveryLocationForm
              listing={listing}
              respondToDeliveryLocationChange={respondToDeliveryLocationChange}
              show={locationType === 'custom'}
            />
          </div>
        </>
      );
    } else if (listing.delivery_locations?.length > 0) {
      return (
        <>
          <Spacer />
          <FlexBox alignItems="center" justifyContent="space-between">
            <TextDense weight="semibold" textColor="dark-gray">
              {translate(
                'cx.global.details.booking_details.delivery_location.single'
              )}
            </TextDense>
            <InputSelect
              options={listing.delivery_locations.map(loc => ({
                label: loc.name,
                value: loc.id
              }))}
              onChange={respondToDeliveryLocationChange}
            />
          </FlexBox>
        </>
      );
    } else if (listing.vehicle?.delivery) {
      return (
        <div>
          <DeliveryLocationForm
            listing={listing}
            respondToDeliveryLocationChange={respondToDeliveryLocationChange}
            show
          />
        </div>
      );
    }
  };

  return (
    <>
      <Box padding="s">
        <FlexBox justifyContent="space-between">
          <PriceAverage />
          {listing.reviews.length > 0 && <ReviewsAverage rightAlign />}
        </FlexBox>
        <Spacer />
        {listingsDetailsState.datesParsed && (
          <BookingDatePicker
            checkInDate={listingsDetailsState.checkInDate}
            checkOutDate={listingsDetailsState.checkOutDate}
            respondToDatesChange={respondToDatesChange}
            unitID={listing.unit.id}
            availability_calendar={listing.availability_calendar}
            defaultAvailability={listing.default_availability_changeover}
            useDesignKit
          />
        )}
        <FlexBox alignItems="center" justifyContent="space-between">
          <TextDense weight="semibold" textColor="dark-gray">
            {translate('cx.global.details.booking_details.number_of_guests')}
          </TextDense>
          <Box>
            <InputIncrementer
              onChange={value => {
                respondToGuestsChange(value);
              }}
              value={listingsDetailsState.guests}
              inputWidth="s"
              removeBottomSpacer
              min={1}
              max={listing.unit.num_sleep}
            />
          </Box>
        </FlexBox>
        {renderDeliveryLocation()}
      </Box>
      <Divider />
      <BookingBreakdownV2
        addCouponCode={code => {
          updateDetailsSingleState({ couponCode: code }, true);
        }}
        addonFeeIds={listingsDetailsState.addonFeeIds}
        availability={listingsDetailsState.availability}
        checkInDate={listingsDetailsState.checkInDate}
        checkOutDate={listingsDetailsState.checkOutDate}
        guests={listingsDetailsState.guests}
        listing={listing}
        pricing={listingsDetailsState.pricing}
        updateFees={() => {}}
        updateQuantityFee={() => {}}
        organizationId={listingsDetailsState.organizationId}
        unitId={listingsDetailsState.unitId}
        deliveryLocation={listingsDetailsState.deliveryLocation}
        customLocation={
          locationType === 'custom' ||
          (listing.delivery_locations?.length === 0 && listing.vehicle.delivery)
        }
      />
      <Box paddingHorizontal="s" paddingBottom="s">
        {brand.contact && brand.contact.email && (
          <>
            <Spacer size="xs" />
            <CallToActionButton
              variation="secondary"
              appearance="outline"
              size="dense"
              onClick={() => {
                dispatch(updateUiSlice({ openContactForm: true }));
              }}
              isFullWidth
            >
              Contact Owner
            </CallToActionButton>
            <Spacer size="s" />
          </>
        )}
        <TextDense textColor="disabled" isFullWidth textAlign="center">
          {translate(`cx.details.no_charge`)}
        </TextDense>
      </Box>
    </>
  );
};

export default BookingDetailsBreakdown;
