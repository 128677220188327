import React from 'react';
import {
  Box,
  FlexBox,
  IconFontAwesome,
  TextDense
} from '@directsoftware/ui-kit-web-admin';
import { Link } from 'react-router-dom';
import {
  buildAddress,
  buildDetailsLink,
  renderFeatures,
  renderName
} from '../listings/resources/shared-functions';

const ListingInfo = ({ stateObject }) => {
  return (
    <FlexBox gap="s">
      <FlexBox flexShrink="0">
        <Link to={buildDetailsLink(stateObject)}>
          {stateObject.featured_image ? (
            <img
              src={stateObject.featured_image.image.small.url}
              alt=""
              className="checkout__listingInfo_img"
            />
          ) : (
            <FlexBox className="checkout__listingInfo_img">
              <IconFontAwesome name="image" />
            </FlexBox>
          )}
        </Link>
      </FlexBox>
      <FlexBox flexDirection="column">
        <Box flex="1">
          <Link to={buildDetailsLink(stateObject)}>
            <TextDense isFullWidth textColor="dark-gray" weight="semibold">
              {renderName(stateObject)}
            </TextDense>
          </Link>
          <TextDense>{buildAddress(stateObject.location)}</TextDense>
        </Box>
        {renderFeatures(stateObject)}
      </FlexBox>
    </FlexBox>
  );
};

export default ListingInfo;
