import React, { useEffect, useState } from 'react';
import { Navigation } from '@directsoftware/wls-marketing-page-slices';
import { useSelector } from 'react-redux';
import { unflatten } from '../../../shared/helpers';

const HeaderV2 = () => {
  const brand = useSelector(state => state.brand);
  const [navLinks, setNavLinks] = useState(null);

  const tempGuestSpacesUrl = () => {
    /**
     * This is a temporary fix to set a default zoom for Guestspaces
     */
    return brand.organization.id === 1037
      ? '/listings/search?amenities=&geo-ne-lat=31.61388993175555&geo-ne-lon=-96.93111842041104&geo-sw-lat=28.813663930487607&geo-sw-lon=-98.72463648681729&guests=1&sort=default&zoom=9'
      : '/listings/search';
  };

  useEffect(() => {
    if (brand.menu && brand.menu.active && brand.menu.menu_items)
      setNavLinks(brand.menu.menu_items);
  }, []);

  if (!navLinks) return null;

  return (
    <Navigation
      theme={brand.v2Theme}
      siteName={brand.name}
      links={unflatten(navLinks)}
      logo={
        brand?.brand_info?.logo_image
          ? { url: brand?.brand_info?.logo_image }
          : null
      }
      logoWhite={
        brand?.logo_white_image ? { url: brand?.logo_white_image } : null
      }
      primaryColor={brand.brand_info.colors?.color_primary}
      primaryTextColor={brand.brand_info.colors?.color_primary_text}
      searchUrl={tempGuestSpacesUrl()}
    />
  );
};

export default HeaderV2;
