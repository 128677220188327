import React from 'react';
import { Box } from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import ReactI18n from 'react-i18n';
import ListingsDetailsContentBlock from './listings-details-content-block';
import ShowMoreWrapper from './show-more-wrapper';

const TermsAndConditions = () => {
  const listing = useSelector(state => state.listing);
  const translate = ReactI18n.getIntlMessage;

  return (
    <ListingsDetailsContentBlock label={translate(`cx.details.headers.rules`)}>
      <ShowMoreWrapper>
        <Box className="customHtmlStyles">
          <div
            dangerouslySetInnerHTML={{
              __html: listing.property.summary_rules
            }}
          />
        </Box>
      </ShowMoreWrapper>
    </ListingsDetailsContentBlock>
  );
};

export default TermsAndConditions;
