// Dependencies
// -----------------------------------------------
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import 'react-dates/initialize'; // Needed for rendering any react-dates components
import { isInclusivelyBeforeDay } from 'react-dates';
import moment from 'moment';
import queryString from 'query-string';
import styled from 'styled-components';
import { times, filter, sortBy, isNull } from 'lodash';

// Components
// -----------------------------------------------
import AddOns from './add-ons';
import AddOnModal from '../modals/addons';
import Booking from './booking';
import ErrorsPaymentTransaction from '../errors/payment-transaction';
import Listing from './listing';
import LocationForm from '../forms/location';
import Notification from '../miscellaneous/notification';
import PortalModal from '../modals/portal';
import Pricing from './pricing';
import Ripple from '../miscellaneous/ripple';
import StripeForm from '../stripe/index';
import CheckoutSteps from './shared/checkout-steps';
import LynnbrookPayment from '../lynnbrook/lynnbrook-payment';
import CheckoutGuestInfoForm from './shared/checkout-guest-info-form';
import CheckoutGuestInfoCollapsed from './shared/checkout-guest-info-collapsed';

// Styles
// -----------------------------------------------
const LoadingWrapper = styled.div`
  align-items: center;
  display: flex;
  min-height: 100vh;
  justify-content: center;
  width: 100%;
`;

// -----------------------------------------------
// COMPONENT->CHECKOUT ---------------------------
// -----------------------------------------------
class Checkout extends React.Component {
  // Constructor
  // ---------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      availabilityLoading: true,
      isStripeLoading: true,
      isStripeSuccessful: false,
      bookingType: null,
      checkInDate: null,
      checkOutDate: null,
      bookingDaysInclusive: null,
      bookingLength: 0,
      datesParsed: null,
      isAvailable: null,
      guests: 1,
      pricing: null,
      transactionError: null,
      addonFeeIds: [],
      brand: this.props.brand,
      brandCurrency: '',
      checkoutTotal: null,
      contractTermsAndConditions: null,
      deposits: [],
      featuredImage: {},
      fees: [],
      listing: {},
      obfuscatedAddress: '',
      property: {},
      rentalAgreement: {},
      requiredAge: null,
      slug: '',
      stripePublishableKey: '',
      stripeCustomerId: '',
      stripeIntentId: '',
      unit: {},
      verifyAddress: null,
      verifyAge: null,
      verifyImage: null,
      verifyImageDescription: '',
      verifySignature: null,
      feeQuantities: [],
      quantity: 0,
      couponCode: '',
      allCouponCodes: null,
      badCode: false,
      accountPersonCreated: false,
      isLynnbrook: this.props.brand.organization.payment_processor === 2,
      lynnbrookErrors: [],
      fetchingLynnbrookConfig: false,
      lynnbrookConfig: {
        accountTargetId: null,
        accountPersonId: null,
        sessionId: null,
        token: null
      },
      lynnbrookCardAccepted: false
    };
  }

  // Component Did Mount
  // ---------------------------------------------
  componentDidMount() {
    this.fetchBookingInfo(this.props);
    document.body.classList.add('checkout-view');
    document.body.classList.remove('listings-view');
    document.body.classList.remove('home-view');
    document.body.classList.remove('search-view');
  }

  // Update Quantity
  // ---------------------------------------------
  updateQuantity = value => {
    this.setState({ quantity: value });
  };

  // Submit Fees
  // ---------------------------------------------
  submitFees = (fee, closePortal) => {
    this.addFeeIds(fee.id, this.state.quantity);
    closePortal();
  };

  // Verify Coupon Code
  // ---------------------------------------------
  verifyCouponCode = closePortal => {
    if (isNull(this.state.allCouponCodes)) {
      this.setState({ badCode: true });
    } else if (this.state.allCouponCodes.includes(this.state.couponCode)) {
      this.addCouponCode(this.state.couponCode);
      closePortal();
    } else {
      this.setState({ badCode: true });
    }
  };

  // Fetch Booking Info
  // ---------------------------------------------
  fetchBookingInfo = props => {
    const parsedQuery = queryString.parse(location.search);
    const { couponCode, guests } = parsedQuery;
    const parsedDates = this.parseCheckInCheckOut();

    this.setState({ couponCode });
    axios
      .post(`/api/bookings/checkout/${this.props.match.params.id}`, {
        headers: { 'Content-Type': 'application/json' },
        check_in: parsedDates.check_in,
        check_out: parsedDates.check_out,
        num_guests: guests,
        coupon_code: couponCode
      })
      .then(response => {
        const data = response.data;
        this.setState(
          {
            brandCurrency: data.brand_currency,
            checkoutTotal: data.checkout_total,
            contractTermsAndConditions: data.contract_terms_and_conditions,
            deposits: data.deposits,
            featuredImage: data.featured_image,
            fees: data.fees,
            listing: data.listing,
            obfuscatedAddress: data.obfuscated_address,
            property: data.property,
            quoteIdFromDb: data.quote_id,
            rentalAgreement: data.rental_agreement,
            requiredAge: data.required_age,
            slug: data.slug,
            stripePublishableKey: data.stripe_publishable_key,
            stripeCustomerId: data.stripe_customer_id,
            stripeIntentId: data.stripe_intent_id,
            unit: data.unit,
            verifyAddress: data.verify_address,
            verifyAge: data.verify_age,
            verifyImage: data.verify_image,
            verifyImageDescription: data.verify_image_description,
            verifySignature: data.verify_signature,
            loading: false,
            location: data.location
          },
          () => {
            this.parseUrl();
            this.fetchLynnbrookTargetId();
          }
        );
        window.customJavascriptLoad();
      });
  };

  // Parse Check In Check Out
  // ---------------------------------------------
  parseCheckInCheckOut = () => {
    const parsedQuery = queryString.parse(location.search);
    const parsedCheckInDate = moment(
      parsedQuery['check-in'],
      'DD-MM-YYYY'
    ).format('DD-MM-YYYY');
    const parsedCheckOutDate = moment(
      parsedQuery['check-out'],
      'DD-MM-YYYY'
    ).format('DD-MM-YYYY');

    return { check_in: parsedCheckInDate, check_out: parsedCheckOutDate };
  };

  // Parse URL
  // ---------------------------------------------
  parseUrl = (cb = () => {}) => {
    const parsedQuery = queryString.parse(location.search);

    if (parsedQuery['check-in'] && parsedQuery['check-out']) {
      const parsedCheckInDate = moment(parsedQuery['check-in'], 'DD-MM-YYYY');
      const parsedCheckOutDate = moment(parsedQuery['check-out'], 'DD-MM-YYYY');
      let guests = parsedQuery.guests;

      if (isNaN(guests) || guests < 1) {
        guests = 1;
      } else if (guests > this.state.unit.num_sleep) {
        guests = this.state.unit.num_sleep;
      }

      const bookingDaysInclusive = [];
      const d = parsedCheckInDate.clone();

      while (isInclusivelyBeforeDay(d, parsedCheckOutDate)) {
        bookingDaysInclusive.push({
          key: d.format('DD-MM-YYYY'),
          day: d.day()
        });
        d.add(1, 'days');
      }

      const quoteId = parsedQuery.quote_id;
      this.setState(
        {
          checkInDate: moment(parsedCheckInDate),
          checkInDateRaw: parsedQuery['check-in'],
          checkOutDate: moment(parsedCheckOutDate),
          bookingDaysInclusive,
          bookingLength: bookingDaysInclusive.length - 1,
          guests,
          datesParsed: true,
          addonFeeIds: this.state.addonFeeIds,
          quoteId: quoteId || this.state.quoteIdFromDb
        },
        () => {
          this.checkAvailability();
          cb();
        }
      );
    } else {
      this.setState({
        datesParsed: false,
        isAvailable: false
      });
    }
  };

  // Check Availability
  // ---------------------------------------------
  checkAvailability = () => {
    if (isNull(this.state.unit.room_type_id)) {
      axios
        .get(
          `${process.env.DIRECT_URL}/api/v2/listings/single/${
            this.state.listing.id
          }/availability`,
          {
            headers: { 'Content-Type': 'application/json' },
            context: this,
            params: {
              unit_id: this.state.unit.id,
              booking_range: JSON.stringify(this.state.bookingDaysInclusive),
              guests: this.state.guests
            }
          }
        )
        .then(response => {
          const data = response.data;
          this.setState(
            {
              availability: data
            },
            () => {
              if (data.bookable) {
                this.fetchCouponCodes();
                this.verifyCouponCode();
                this.checkPricing();
              }
            }
          );
        })
        .catch(error => {
          console.warn(error);
        });
    } else {
      axios
        .get(
          `${process.env.DIRECT_URL}/api/v2/listings/room/${
            this.state.listing.id
          }/room_type_availability?booking_range=${JSON.stringify(
            this.state.bookingDaysInclusive
          )}&unit_id=${this.state.unit.id}&guests=${this.state.guests}`,
          {
            headers: { 'Content-Type': 'application/json' }
          }
        )
        .then(response => {
          this.setState(
            {
              availability: response.data
            },
            () => {
              if (response.data.bookable) {
                this.fetchCouponCodes();
                this.verifyCouponCode();
                this.checkPricing();
              }
            }
          );
        })
        .catch(error => {
          console.warn(error);
        });
    }
  };

  // Update Guests
  // ---------------------------------------------
  updateGuests = val => {
    this.setState({ guests: val });
  };

  // Update Fees
  // ---------------------------------------------
  updateFees = feeId => {
    let newArray = [];

    if (this.state.addonFeeIds.includes(feeId)) {
      newArray = this.state.addonFeeIds.filter(id => id !== feeId);
    } else {
      newArray = this.state.addonFeeIds.concat(feeId);
    }
    this.setState({ addonFeeIds: newArray }, this.checkPricing);
  };

  // Add Fee IDs
  // ---------------------------------------------
  addFeeIds = (feeId, quantity) => {
    const addonIds = this.state.addonFeeIds.filter(id => id !== feeId);
    times(quantity, () => addonIds.push(feeId));
    this.setState({ addonFeeIds: addonIds }, () => this.checkPricing());
    this.setState({
      feeQuantities: [
        ...this.state.feeQuantities.filter(fee => fee.id !== feeId),
        {
          id: feeId,
          quantity
        }
      ],
      quantity: 0
    });
  };

  // Check Pricing
  // ---------------------------------------------
  checkPricing = () => {
    const parsedQuery = queryString.parse(location.search);
    const guests = parsedQuery.guests;

    axios
      .get(
        `${process.env.DIRECT_URL}/api/v2/unit_pricing/${this.state.quoteId}`,
        {
          headers: { 'Content-Type': 'application/json' },
          params: {
            quote_id: this.state.quoteId,
            addon_fee_ids: this.state.addonFeeIds,
            coupon_code: this.state.couponCode,
            listing_id: this.state.listing.id,
            booking_range: JSON.stringify(this.state.bookingDaysInclusive),
            num_guests: guests
          }
        }
      )
      .then(response => {
        const data = response.data;
        this.setState({
          fees: data.fees,
          pricing: data,
          availabilityLoading: false,
          checkoutTotal: data.total
        });
      })
      .catch(error => {
        console.warn(error);
      });
  };

  // Fetch Coupon Codes
  // ---------------------------------------------
  fetchCouponCodes = () => {
    axios
      .get(
        `${process.env.DIRECT_URL}/api/v2/fetch_coupon_codes/${
          this.state.listing.id
        }`,
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      .then(res => {
        const data = res.data;
        this.setState({
          allCouponCodes: data
        });
      })
      .catch(error => {
        console.warn(error);
      });
  };

  fetchLynbrookConfig = targetId => {
    const name = `${this.state.customerFirstName} ${
      this.state.customerLastName
    }`;
    axios
      .get(
        `${process.env.DIRECT_URL}/api/v2/listings/${
          this.state.listing.id
        }/lynnbrook_config?customer_email=${
          this.state.customerEmail
        }&customer_name=${name}&customer_telephone=${
          this.state.customerTelephone
        }&target_id=${targetId}`,
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      .then(res => {
        const data = res.data;
        this.setState({
          fetchingLynnbrookConfig: false,
          accountPersonCreated: true,
          lynnbrookConfig: {
            ...this.state.lynnbrookConfig,
            accountPersonId: data.account_person_id,
            sessionId: data.session_id,
            token: process.env.APTEXX_API_TOKEN
          }
        });
      })
      .catch(error => {
        console.warn(error);
      });
  };

  fetchLynnbrookTargetId = () => {
    axios
      .get(
        `${process.env.DIRECT_URL}/api/v2/listings/${
          this.state.listing.id
        }/target_id`,
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      .then(res => {
        this.setState({
          lynnbrookConfig: {
            ...this.state.lynnbrookConfig,
            accountTargetId: res.data.target_id
          }
        });
      })
      .catch(error => {
        console.warn(error);
      });
  };

  // Add Coupon Code
  // ---------------------------------------------
  addCouponCode = code => {
    this.setState({ couponCode: code }, () => this.checkPricing());
  };

  // On Change
  // ---------------------------------------------
  onChange = (name, value) => {
    this.setState({ [name]: value });
  };

  // Set Location Attributes
  // ---------------------------------------------
  setLocationAttributes = l => {
    this.setState({ ...this.state, ...l });
  };

  checkFieldIsInvalid = field => {
    return field === '' || field === undefined;
  };

  createAccountPerson = () => {
    const {
      customerFirstName,
      customerLastName,
      customerEmail,
      customerTelephone,
      adrStreet,
      adrCity,
      adrState,
      adrPostalCode,
      adrCountry
    } = this.state;

    const errorArray = [];

    if (this.checkFieldIsInvalid(customerFirstName))
      errorArray.push('customerFirstName');
    if (this.checkFieldIsInvalid(customerLastName))
      errorArray.push('customerLastName');
    if (this.checkFieldIsInvalid(customerEmail))
      errorArray.push('customerEmail');
    if (this.checkFieldIsInvalid(customerTelephone))
      errorArray.push('customerTelephone');
    if (this.checkFieldIsInvalid(adrStreet)) errorArray.push('adrStreet');
    if (this.checkFieldIsInvalid(adrCity)) errorArray.push('adrCity');
    if (this.checkFieldIsInvalid(adrState)) errorArray.push('adrState');
    if (this.checkFieldIsInvalid(adrPostalCode))
      errorArray.push('adrPostalCode');
    if (this.checkFieldIsInvalid(adrCountry)) errorArray.push('adrCountry');

    this.setState({ lynnbrookErrors: errorArray });

    if (errorArray.length === 0) {
      this.setState({ fetchingLynnbrookConfig: true }, () => {
        this.fetchLynbrookConfig(this.state.lynnbrookConfig.accountTargetId);
      });
    }
  };

  // Render
  // ---------------------------------------------
  render() {
    const addonFees = filter(this.state.fees, ['is_addon', 'true']);
    const currency = this.state.brandCurrency;
    const sortedAddonFees = sortBy(
      addonFees,
      fee => !this.state.addonFeeIds.includes(fee.id)
    );

    return this.state.loading ? (
      <LoadingWrapper>
        <Ripple color="#50E3C2" />
      </LoadingWrapper>
    ) : (
      <>
        <CheckoutSteps />
        <main className="checkout-main">
          <Notification />
          {this.state.availability &&
          this.state.availability.bookable &&
          this.state.pricing ? (
            <section className="payment">
              {sortedAddonFees.length > 0 && (
                <div className="addons-title">Extra Add-Ons</div>
              )}
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {sortedAddonFees.map((fee, index) =>
                  fee.fee_account.quantity_fee &&
                  this.props.brand.organization.add_on_images ? (
                    <PortalModal
                      header={`${fee.name}`}
                      openByClickOn={openPortal => (
                        <div className="addons-wrapper">
                          <div className="addons-item" onClick={openPortal}>
                            <div
                              className="addons-image"
                              style={
                                fee.fee_account.fee_image
                                  ? {
                                      backgroundImage: `url(${
                                        fee.fee_account.fee_image
                                      })`
                                    }
                                  : {
                                      backgroundImage: `url('https://via.placeholder.com/360x240')`
                                    }
                              }
                            />
                            <div className="addons-info">
                              <span>
                                Add
                                {fee.name}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                      disableCloseOnOutsideClick
                      modalStyles={{ width: '30%', height: '80%' }}
                      submitAction={closePortal => (
                        <div style={{ textAlign: 'center', marginTop: '5%' }}>
                          <a
                            className="button"
                            onClick={() => this.submitFees(fee, closePortal)}
                          >
                            Submit
                          </a>
                        </div>
                      )}
                      closeOnSubmit
                    >
                      <AddOnModal
                        addonImages={
                          this.props.brand.organization.add_on_images
                        }
                        fee={fee}
                        renderDescriptionPopover={this.renderDescriptionPopover}
                        currency={currency}
                        addonFeeIds={this.state.addonFeeIds}
                        checkPricing={this.checkPricing}
                        addFeeIds={this.addFeeIds}
                        quantity={this.state.quantity}
                        updateQuantity={this.updateQuantity}
                        feeQuantities={this.state.feeQuantities}
                      />
                    </PortalModal>
                  ) : null
                )}
                {this.props.brand.organization.add_on_images && (
                  <AddOns
                    availability={this.state.availability}
                    currency={this.state.brandCurrency}
                    updateFees={this.updateFees}
                    temp_fees={this.state.fees}
                    addonFeeIds={this.state.addonFeeIds}
                  />
                )}
              </div>
              {(this.props.brand.organization.add_on_images ||
                sortedAddonFees.length > 0) && <div style={{ height: 24 }} />}
              <header className="addons-title">Guest Information</header>
              {this.state.isLynnbrook ? (
                <>
                  {!this.state.accountPersonCreated ? (
                    <>
                      <CheckoutGuestInfoForm
                        {...this.state}
                        isLynnbrook
                        onChange={(name, value) => this.onChange(name, value)}
                        onBlur={this.onBlur}
                      />
                      <LocationForm
                        setLocationAttributes={l =>
                          this.setLocationAttributes(l)
                        }
                        savedAdrStreet={this.state.adrStreet}
                        savedAdrUnit={this.state.adrUnit}
                        savedAdrCity={this.state.adrCity}
                        savedAdrState={this.state.adrState}
                        savedAdrPostalCode={this.state.adrPostalCode}
                        savedAdrCountry={this.state.adrCountry}
                        lynnbrookErrors={this.state.lynnbrookErrors}
                      />
                      <button
                        onClick={() => this.createAccountPerson()}
                        disabled={this.state.fetchingLynnbrookConfig}
                      >
                        Continue
                      </button>
                    </>
                  ) : (
                    <CheckoutGuestInfoCollapsed
                      {...this.state}
                      showGuestInfoForm={() =>
                        this.setState({ accountPersonCreated: false })
                      }
                    />
                  )}
                </>
              ) : (
                <>
                  <CheckoutGuestInfoForm
                    {...this.state}
                    onChange={(name, value) => this.onChange(name, value)}
                    onBlur={this.onBlur}
                  />
                  <LocationForm
                    setLocationAttributes={l => this.setLocationAttributes(l)}
                  />
                </>
              )}
              <div style={{ height: 24 }} />
              {this.state.isLynnbrook ? (
                <LynnbrookPayment
                  accountPersonCreated={this.state.accountPersonCreated}
                  createAccountPerson={this.createAccountPerson}
                  addonFeeIds={this.state.addonFeeIds}
                  availability={this.state.availability}
                  booking={this.props.booking}
                  bookingDaysInclusive={this.state.bookingDaysInclusive}
                  brand_info={this.props.brand.brand_info}
                  checkInDate={this.state.checkInDate}
                  checkOutDate={this.state.checkOutDate}
                  couponCode={this.state.couponCode}
                  customerEmail={this.state.customerEmail}
                  customerName={`${this.state.customerFirstName} ${
                    this.state.customerLastName
                  }`}
                  customerPostalCode={this.state.adrPostalCode}
                  customerTelephone={this.state.customerTelephone}
                  guests={this.state.guests}
                  listing={this.state.listing}
                  max_guests={this.state.unit.num_sleep}
                  pricing={this.state.pricing}
                  quoteId={this.state.quoteId}
                  rental_agreement={this.state.rentalAgreement}
                  slug={this.state.slug}
                  unit={this.state.unit}
                  updateGuests={this.updateGuests}
                  verifyImage={this.state.verifyImage}
                  verifySignature={this.state.verifySignature}
                  verifyAge={this.state.verifyAge}
                  verifyAddress={this.state.verifyAddress}
                  lynnbrookConfig={this.state.lynnbrookConfig}
                  adrStreet={this.state.adrStreet}
                  adrCity={this.state.adrCity}
                  adrState={this.state.adrState}
                  adrCountry={this.state.adrCountry}
                  adrPostalCode={this.state.adrPostalCode}
                  urlLocation={this.props.location}
                />
              ) : (
                <StripeForm
                  addonFeeIds={this.state.addonFeeIds}
                  availability={this.state.availability}
                  booking={this.props.booking}
                  bookingDaysInclusive={this.state.bookingDaysInclusive}
                  brand_info={this.props.brand.brand_info}
                  checkInDate={this.state.checkInDate}
                  checkOutDate={this.state.checkOutDate}
                  couponCode={this.state.couponCode}
                  customerEmail={this.state.customerEmail}
                  customerName={this.state.customerName}
                  customerPostalCode={this.state.adrPostalCode}
                  customerTelephone={this.state.customerTelephone}
                  guests={this.state.guests}
                  listing={this.state.listing}
                  max_guests={this.state.unit.num_sleep}
                  pricing={this.state.pricing}
                  quoteId={this.state.quoteId}
                  rental_agreement={this.state.rentalAgreement}
                  slug={this.state.slug}
                  stripeCustomerId={this.state.stripeCustomerId}
                  stripeIntentId={this.state.stripeIntentId}
                  stripePublishableKey={this.state.stripePublishableKey}
                  unit={this.state.unit}
                  updateGuests={this.updateGuests}
                  verifyImage={this.state.verifyImage}
                  verifySignature={this.state.verifySignature}
                  verifyAge={this.state.verifyAge}
                  verifyAddress={this.state.verifyAddress}
                  urlLocation={this.props.location}
                />
              )}
              <ErrorsPaymentTransaction
                errors={[this.state.transactionError]}
              />
            </section>
          ) : (
            <section className="payment">
              {this.state.isStripeSuccessful ? null : (
                <section className="fields-cc">
                  <Ripple color="#50E3C2" />
                </section>
              )}
            </section>
          )}
          <section className="information">
            <Listing
              checkInDate={this.state.checkInDate}
              checkOutDate={this.state.checkOutDate}
              featured_image={this.state.featuredImage}
              guests={this.state.guests}
              listing={this.state.listing}
              obfuscated_address={this.state.obfuscatedAddress}
              pricing={this.state.pricing}
              property={this.state.property}
              slug={this.state.slug}
              unit={this.state.unit}
            />
            <Booking
              checkInDate={this.state.checkInDate}
              checkOutDate={this.state.checkOutDate}
              guests={this.state.guests}
              nights={this.state.bookingLength}
            />
            <Pricing
              addCouponCode={this.addCouponCode}
              addFeeIds={this.addFeeIds}
              addonFeeIds={this.state.addonFeeIds}
              allCouponCodes={this.state.allCouponCodes}
              availability={this.state.availability}
              availabilityLoading={this.state.availabilityLoading}
              checkInDate={this.state.checkInDate}
              checkInDateRaw={this.state.checkInDateRaw}
              checkoutTotal={this.state.checkoutTotal}
              checkPricing={this.checkPricing}
              currency={this.state.brandCurrency}
              feeQuantities={this.state.feeQuantities}
              nights={this.state.bookingLength}
              pricing={this.state.pricing}
              temp_fees={this.state.fees}
              updateFees={this.updateFees}
              location={this.state.location}
            />
          </section>
        </main>
      </>
    );
  }
}

// Map State To Props
// -----------------------------------------------
function mapStateToProps(state) {
  return {
    brand: state.brand
  };
}

// Export
// -----------------------------------------------
export default connect(mapStateToProps)(Checkout);
