import React from 'react';
import styled from 'styled-components';

const ContactForm = styled.div`
  align-items: flex-start;
  box-pack: space-between;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const FormGroup = styled.fieldset`
  display: block;
  width: 100%;

  &.width-25 {
    width: 23%;
  }

  &.width-50 {
    width: 48%;
  }

  &.width-75 {
    width: 73%;
  }
`;
const CheckoutGuestInfoForm = props => {
  const addErrorClass = key => {
    if (props.lynnbrookErrors?.length) {
      if (props.lynnbrookErrors.includes(key)) return 'field-error';
    }

    return '';
  };

  return (
    <ContactForm>
      {props.isLynnbrook && props.lynnbrookErrors?.length > 0 && (
        <div className="error-text">
          Please check that you've filled out all information
        </div>
      )}
      {props.isLynnbrook ? (
        <>
          <FormGroup className="width-50">
            <label htmlFor="customerFirstName">
              <span>First Name</span>
            </label>
            <input
              autoComplete="firstName"
              type="text"
              name="customerFirstName"
              onChange={e => props.onChange(e.target.name, e.target.value)}
              onBlur={props.onBlur}
              value={props.customerFirstName}
              required
              className={addErrorClass('customerFirstName')}
            />
          </FormGroup>
          <FormGroup className="width-50">
            <label htmlFor="customerLastName">
              <span>Last Name</span>
            </label>
            <input
              autoComplete="lastName"
              type="text"
              name="customerLastName"
              onChange={e => props.onChange(e.target.name, e.target.value)}
              onBlur={props.onBlur}
              value={props.customerLastName}
              required
              className={addErrorClass('customerLastName')}
            />
          </FormGroup>
        </>
      ) : (
        <FormGroup>
          <label htmlFor="customerName">
            <span>Full name</span>
          </label>
          <input
            autoComplete="name"
            type="text"
            name="customerName"
            onChange={e => props.onChange(e.target.name, e.target.value)}
            onBlur={props.onBlur}
            placeholder="Jane Smith"
            value={props.customerName}
            required
          />
        </FormGroup>
      )}
      <FormGroup className="width-50">
        <label htmlFor="customerEmail">
          <span>Email</span>
        </label>
        <input
          autoComplete="email"
          type="email"
          name="customerEmail"
          onChange={e => props.onChange(e.target.name, e.target.value)}
          onBlur={props.onBlur}
          placeholder="name@email.com"
          value={props.customerEmail}
          required
          className={addErrorClass('customerEmail')}
        />
      </FormGroup>
      <FormGroup className="width-50">
        <label htmlFor="customerTelephone">
          <span>Phone</span>
        </label>
        <input
          autoComplete="tel"
          type="tel"
          name="customerTelephone"
          onChange={e => props.onChange(e.target.name, e.target.value)}
          onBlur={props.onBlur}
          placeholder="+1 (123) 123-1234"
          maxLength={20}
          value={props.customerTelephone}
          required
          className={addErrorClass('customerTelephone')}
        />
      </FormGroup>
    </ContactForm>
  );
};

export default CheckoutGuestInfoForm;
