import React from 'react';
import Iron from './reactSvg/iron';
import HairDryer from './reactSvg/hairDryer';

const bookingEngineRoutes = [
  '/listings/search',
  '/listings/map',
  '/listings/list',
  '/listings/grid',
  '/listings',
  '/checkout',
  '/my-bookings'
];

const amenities = [
  {
    name: 'AMENITIES_AIR_CONDITIONING',
    label: 'A/C',
    iconName: 'airConditioner'
  },
  {
    name: 'AMENITIES_FIREPLACE',
    label: 'Fireplace',
    iconName: 'fireplace'
  },
  {
    name: 'AMENITIES_PARKING',
    label: 'Parking',
    iconName: 'circleParking'
  },
  {
    name: 'AMENITIES_WIRELESS_INTERNET',
    label: 'WiFi',
    iconName: 'wifi'
  },
  {
    name: 'AMENITIES_HAIR_DRYER',
    label: 'Hair Dryer',
    useOldIcon: true,
    iconName: <HairDryer />
  },
  {
    name: 'AMENITIES_TOWELS',
    label: 'Towels',
    iconName: 'blanket'
  },
  {
    name: 'AMENITIES_HEATING',
    label: 'Heating',
    iconName: 'heat'
  },
  {
    name: 'AMENITIES_IRON_BOARD',
    label: 'Ironing Board',
    useOldIcon: true,
    iconName: <Iron />
  },
  {
    name: 'AMENITIES_WASHER',
    label: 'Washer',
    iconName: 'washingMachine'
  },
  {
    name: 'AMENITIES_LINENS',
    label: 'Linens',
    iconName: 'blanket'
  }
];

const spaAmenities = [
  {
    name: 'POOL_SPA_HOT_TUB',
    label: 'Hot Tub',
    iconName: 'hotTubPerson'
  },
  {
    name: 'POOL_SPA_COMMUNAL_POOL',
    label: 'Communal Pool',
    iconName: 'personSwimming'
  },
  {
    name: 'POOL_SPA_INDOOR_POOL',
    label: 'Indoor Pool',
    iconName: 'personSwimming'
  },
  {
    name: 'POOL_SPA_PRIVATE_POOL',
    label: 'Private Pool',
    iconName: 'personSwimming'
  }
];

const entertainmentAmenities = [
  {
    name: 'ENTERTAINMENT_TELEVISION',
    label: 'TV',
    iconName: 'tv'
  },
  {
    name: 'ENTERTAINMENT_VIDEO_GAMES',
    label: 'Video Games',
    iconName: 'gamepadModern'
  }
];

const diningAmenities = [
  {
    name: 'KITCHEN_DINING_KITCHEN',
    label: 'Kitchen',
    iconName: 'kitchenSet'
  },
  {
    name: 'KITCHEN_DINING_MICROWAVE',
    label: 'Microwave',
    iconName: 'microwave'
  },
  {
    name: 'KITCHEN_DINING_COFFEE_MAKER',
    label: 'Coffee',
    iconName: 'coffeePot'
  },
  {
    name: 'KITCHEN_DINING_DISHES_UTENSILS',
    label: 'Utensils',
    iconName: 'utensils'
  },
  {
    name: 'KITCHEN_DINING_OVEN',
    label: 'Oven',
    iconName: 'oven'
  },
  {
    name: 'KITCHEN_DINING_STOVE',
    label: 'Stove',
    iconName: 'fireBurner'
  }
];

const outdoorAmenities = [
  {
    name: 'OUTDOOR_GRILL',
    label: 'Grill',
    iconName: 'grillHot'
  }
];

const checkoutSteps = {
  stepOne: {
    label: 'Confirm and Pay',
    url: '/checkout'
  },
  stepTwo: {
    label: 'ID Verification',
    url: '/my-bookings/verification'
  },
  stepThree: {
    label: 'Receipt'
  }
};

const inputStyleObj = {
  fontStyle: 'normal',
  fontWeight: '400',
  fontFamily: 'proxima-nova, sans-serif',
  color: '#2d3142',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'rgba(108,117,155,.25)',
  fontSize: '14px',
  width: '100%',
  maxWidth: '500px',
  height: '36px',
  padding: '0 8px',
  letterSpacing: '0.015em',
  borderRadius: '4px'
};

const baseLynnbrookConfig = {
  fields: [
    {
      type: 'CARD_NAME',
      id: 'aptexx-card-name-container',
      style: inputStyleObj
    },
    {
      type: 'CARD_NUMBER',
      id: 'aptexx-card-number-container',
      style: inputStyleObj
    },
    {
      type: 'EXPIRATION_DATE',
      id: 'aptexx-card-expiration-date-container',
      style: inputStyleObj
    },
    {
      type: 'ZIP_CODE',
      id: 'aptexx-card-zip-code-container',
      style: inputStyleObj
    }
  ]
};

const usStates = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'District of Columbia', value: 'DC' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' }
];

export {
  bookingEngineRoutes,
  amenities,
  spaAmenities,
  entertainmentAmenities,
  diningAmenities,
  outdoorAmenities,
  checkoutSteps,
  baseLynnbrookConfig,
  usStates
};
