import React from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  FlexBox,
  InputIncrementer,
  Label
} from '@directsoftware/ui-kit-web-admin';
import { selectListingsSearchState } from '../../../redux/slices/listingsSearch';
import { calculateMaxes } from '../resources/shared-functions';

const ListingsSearchNumGuests = ({ updateSearchState, translate }) => {
  const searchState = useSelector(selectListingsSearchState);

  return (
    <FlexBox alignItems="center" justifyContent="space-between">
      <Box flex="1">
        <Label>{translate('cx.receipt.guests')}</Label>
      </Box>
      <Box>
        <InputIncrementer
          onChange={num => {
            updateSearchState({ guests: num }, true);
            calculateMaxes(searchState, updateSearchState);
          }}
          value={searchState.guests}
          inputWidth="s"
          isDense
          removeBottomSpacer
          min={1}
          max={searchState.maxGuests}
        />
      </Box>
    </FlexBox>
  );
};

export default ListingsSearchNumGuests;
