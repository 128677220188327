import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  isStripeSuccessful: false,
  listing: {},
  transactionError: null,
  availability: {},
  booking: {},
  bookingPaymentDueToday: null,
  bookingPaymentBalanceDueDate: '',
  charges: [],
  nights: 0,
  pricing: null,
  customer: {},
  featured_image: {},
  location: {},
  property: {},
  property_manager: {},
  slug: '',
  unit: {},
  bookingPaid: null,
  securityDeposit: {},
  securityDepositRequired: null,
  verified: null,
  loading: true,
  stripe_publishable_key: '',
  stripe_account_id: '',
  chargeAmount: 0,
  isSubmitted: false,
  createStripeToken: false,
  lynnbrookCardPaymentFailed: false
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    updatePaymentSlice: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetPaymentSlice: state => {
      return { ...state, ...initialState };
    }
  }
});

export const { updatePaymentSlice, resetPaymentSlice } = paymentSlice.actions;

export default paymentSlice.reducer;

export const selectPaymentState = createSelector(
  state => state.payment,
  payment => payment
);
