import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { Box, Label, Spacer, TextH2 } from '@directsoftware/ui-kit-web-admin';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#2d3142',
      border: 'none',
      background: 'none',
      fontFamily: '"Figtree", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '14px',
      fontWeight: 400,
      outline: 'none',
      borderRadius: 4,
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

const CardSectionV2 = () => {
  return (
    <>
      <TextH2>Payment</TextH2>
      <Spacer size={'s'} />
      <Spacer size={'xxs'} />
      <Label>Card Details</Label>
      <CardElement options={CARD_ELEMENT_OPTIONS} />
    </>
  );
};

export default CardSectionV2;
