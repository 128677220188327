import moment from 'moment';
import { isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates';
import queryString from 'query-string';
import { max } from 'lodash';
import { TextDense } from '@directsoftware/ui-kit-web-admin';
import React from 'react';
import ReactI18n from 'react-i18n';
import AmenitiesList from './amenities_list.json';
import VehicleAmenitiesList from './vehicle_amenities_list.json';

const translate = ReactI18n.getIntlMessage;

const parseQuery = (locationString, brand) => {
  const parsedQuery = queryString.parse(locationString);
  const queryInfo = {};
  let defaultSort = 'default';
  // Dates
  if (parsedQuery['check-in'] && parsedQuery['check-out']) {
    queryInfo.checkIn = moment(parsedQuery['check-in'], 'DD-MM-YYYY');
    queryInfo.checkOut = moment(parsedQuery['check-out'], 'DD-MM-YYYY');

    const bookingRange = [];
    const d = queryInfo.checkIn.clone();
    while (isInclusivelyBeforeDay(d, queryInfo.checkOut)) {
      bookingRange.push({
        key: d.format('DD-MM-YYYY'),
        day: d.day()
      });
      d.add(1, 'days');
    }
    queryInfo.bookingRange = bookingRange;
  }
  // Location
  if (
    parsedQuery['geo-ne-lat'] &&
    parsedQuery['geo-ne-lon'] &&
    parsedQuery['geo-sw-lat'] &&
    parsedQuery['geo-sw-lon']
  ) {
    queryInfo.geoNELat = JSON.parse(parsedQuery['geo-ne-lat']);
    queryInfo.geoNELon = JSON.parse(parsedQuery['geo-ne-lon']);
    queryInfo.geoSWLat = JSON.parse(parsedQuery['geo-sw-lat']);
    queryInfo.geoSWLon = JSON.parse(parsedQuery['geo-sw-lon']);
  } else if (parsedQuery.loc) {
    queryInfo.loc = decodeURIComponent(parsedQuery.loc);
  }
  // Num Guests
  if (parsedQuery.guests) {
    queryInfo.guests = parsedQuery.guests;
  }
  // Zoom
  if (parsedQuery.zoom) {
    queryInfo.zoom = parseInt(parsedQuery.zoom);
  } else {
    queryInfo.zoom = 14;
  }
  if (parsedQuery.amenities) {
    queryInfo.selectedAmenities = parsedQuery.amenities.split(',');
  }
  // Sort
  if (brand.organization_id === 52) {
    defaultSort = 'name';
  }
  queryInfo.sort = parsedQuery.sort || defaultSort;
  if (parsedQuery['event-search'])
    queryInfo.eventSearch = parsedQuery['event-search'];
  return queryInfo;
};

const getStringifiedQueryString = searchState => {
  const queryInfo = {};

  // Dates
  if (searchState.checkIn && searchState.checkOut) {
    queryInfo['check-in'] = searchState.checkIn.format('DD-MM-YYYY');
    queryInfo['check-out'] = searchState.checkOut.format('DD-MM-YYYY');
  }
  if (searchState.checkInDate && searchState.checkOutDate) {
    queryInfo['check-in'] = searchState.checkInDate.format('DD-MM-YYYY');
    queryInfo['check-out'] = searchState.checkOutDate.format('DD-MM-YYYY');
  }
  // Location
  if (
    searchState.geoNELat &&
    searchState.geoNELon &&
    searchState.geoSWLat &&
    searchState.geoSWLon
  ) {
    queryInfo['geo-ne-lat'] = searchState.geoNELat;
    queryInfo['geo-ne-lon'] = searchState.geoNELon;
    queryInfo['geo-sw-lat'] = searchState.geoSWLat;
    queryInfo['geo-sw-lon'] = searchState.geoSWLon;
  } else if (searchState.loc) {
    queryInfo.loc = searchState.loc;
  }
  // Num Guests
  if (searchState.guests && searchState.guests > 0) {
    queryInfo.guests = searchState.guests;
  }
  // Zoom
  if (searchState.zoom) {
    queryInfo.zoom = searchState.zoom;
  }
  // Sort
  if (searchState.sort) {
    queryInfo.sort = searchState.sort;
  }
  // Amenities
  if (searchState.selectedAmenities) {
    queryInfo.amenities = searchState.selectedAmenities.join(',');
  }
  // Coupon Code
  if (searchState.couponCode) {
    queryInfo.couponCode = searchState.couponCode;
  }
  // Addon Fee Ids
  if (searchState.addonFeeIds) {
    queryInfo.addonFeeIds = searchState.addonFeeIds.join(',');
  }
  if (searchState.room) {
    queryInfo.room = searchState.room;
  }
  if (searchState.deliveryLocation) {
    queryInfo.deliveryLocation = searchState.deliveryLocation;
  }
  const stringifiedQueryString = `?${queryString.stringify(queryInfo)}`;
  return stringifiedQueryString;
};

const getAmenitiesFromParams = amenities => {
  if (!amenities || !amenities.length) return null;
  return amenities.reduce(
    (seed, amenity) => {
      const entry = AmenitiesList[amenity];
      seed[entry[0].model] = seed[entry[0].model].concat(entry);
      return seed;
    },
    { Property: [], Unit: [] }
  );
};

const vehicleAmenitiesFromParams = amenities => {
  if (!amenities || !amenities.length) return null;
  return amenities.map(a => VehicleAmenitiesList[a][0].column);
};

const createQueryParams = (searchState, brand) => {
  return `?${queryString.stringify({
    brand_id: brand.id,
    booking_range: JSON.stringify(searchState.bookingRange),
    geo_ne_lat: searchState.geoNELat,
    geo_ne_lon: searchState.geoNELon,
    geo_sw_lat: searchState.geoSWLat,
    geo_sw_lon: searchState.geoSWLon,
    loc: searchState.loc,
    sort: searchState.sort,
    page: searchState.currentPage + 1,
    limit: 18,
    num_bedrooms: searchState.filter_numBedrooms,
    num_bathrooms: searchState.filter_numBathrooms,
    num_guests: searchState.guests,
    instant_book: searchState.filter_instantBook,
    price_low: searchState.filter_priceLow,
    price_high: searchState.filter_priceHigh,
    distance: searchState.filter_distance,
    amenities: JSON.stringify(searchState.amenities),
    event_search: searchState.eventSearch
  })}`;
};

const getListingImageUrl = result => {
  const featuredImage = result?.featured_image;
  const image = featuredImage && featuredImage.image;
  if (image) {
    if (
      !featuredImage.image_processing &&
      image.small &&
      image.small.url !== ''
    ) {
      return image.small.url;
    } else {
      return image.url;
    }
  } else {
    return false;
  }
};

const updateQueryString = searchState => {
  const stringifiedQueryString = getStringifiedQueryString(searchState);
  history.pushState(null, null, stringifiedQueryString);
};

const calculateMaxes = (searchState, updateSearchState) => {
  if (searchState.results) {
    const maxGuests = max(
      searchState.results.map(result => result.listings[0].unit.num_sleep)
    );
    const maxBedrooms = max(
      searchState.results.map(result => result.num_bedrooms)
    );
    const maxBaths = max(
      searchState.results.map(result => result.num_bathrooms)
    );
    updateSearchState({ maxGuests, maxBaths, maxBedrooms });
  }
};

const isOutsideRange = day => {
  const today = moment();
  const limitEnd = moment().add(3, 'years');
  const isBeforeToday = !isInclusivelyAfterDay(day, today);
  const isAfterLimitEnd = !isInclusivelyBeforeDay(day, limitEnd);
  return isBeforeToday || isAfterLimitEnd;
};

const renderName = stateObject => {
  if (stateObject.isVehicle) {
    return `${stateObject.unit.name}`;
  } else if (stateObject.unit.room_type_name) {
    return `${stateObject.unit.room_type_name} | ${stateObject.property.name} `;
  } else if (stateObject.property.multi_unit) {
    return `${stateObject.property.name} | ${stateObject.unit.name}`;
  } else {
    return stateObject.property.name;
  }
};

const buildDetailsLink = stateObject => {
  let link = `/listings/${stateObject.slug}?`;
  if (stateObject.checkInDate) {
    link += `&check-in=${moment(stateObject.checkInDate).format('DD-MM-YYYY')}`;
  }
  if (stateObject.checkOutDate) {
    link += `&check-out=${moment(stateObject.checkOutDate).format(
      'DD-MM-YYYY'
    )}`;
  }
  if (stateObject.guests) {
    link += `&guests=${stateObject.guests}`;
  }
  return link;
};

const renderFeatures = stateObject => {
  let featureString = '';

  if (stateObject.unit.num_sleep)
    featureString += `${translate('cx.global.amenities.sleeps')} ${
      stateObject.unit.num_sleep
    } • `;
  if (stateObject.isVehicle && stateObject.unit.num_sleep_in_beds)
    featureString += `${translate('cx.global.amenities.sleeps')} ${
      stateObject.unit.num_sleep_in_beds
    } • `;
  if (stateObject.unit.num_bedrooms) {
    const translation =
      stateObject.unit.num_bedrooms > 1
        ? translate('cx.global.amenities.beds')
        : translate('cx.global.amenities.bed');
    featureString += `${stateObject.unit.num_bedrooms} ${translation} • `;
  }
  if (stateObject.unit.num_bathrooms) {
    const translation =
      stateObject.unit.num_bathrooms > 1
        ? translate('cx.global.amenities.baths')
        : translate('cx.global.amenities.bath');
    featureString += `${stateObject.unit.num_bathrooms} ${translation} • `;
  }

  return featureString === '' ? null : (
    <TextDense>{featureString.slice(0, -3)}</TextDense>
  );
};

const buildAddress = (loc, isTwoLines = false) => {
  const address = [];
  const address2 = [];

  if (loc.adr_street) {
    address.push(loc.adr_street);
  }
  if (loc.adr_unit) {
    address.push(loc.adr_unit);
  }
  if (loc.adr_city) {
    if (isTwoLines) address2.push(loc.adr_city);
    else address.push(loc.adr_city);
  }
  if (loc.adr_state) {
    if (isTwoLines) address2.push(loc.adr_state);
    else address.push(loc.adr_state);
  }
  if (loc.adr_postal_code) {
    if (isTwoLines) address2.push(loc.adr_postal_code);
    else address.push(loc.adr_postal_code);
  }
  if (loc.adr_country) {
    if (isTwoLines) address2.push(loc.adr_country);
    else address.push(loc.adr_country);
  }

  if (isTwoLines) {
    return (
      <>
        {address.join(', ')}
        <br />
        {address2.join(', ')}
      </>
    );
  }

  return address.join(', ');
};

const smallImageDefault = img => {
  const image = img.image;
  if (image && !img.image_processing && image.small && image.small.url !== '') {
    const imgSmallDefault = JSON.parse(JSON.stringify(img));
    imgSmallDefault.image.url = image.small.url;
    return imgSmallDefault;
  } else {
    return img;
  }
};

const buildDateDisplay = (startDate, endDate) => {
  const startYear = moment(startDate).format('YYYY');
  const endYear = moment(endDate).format('YYYY');
  const startMonth = moment(startDate).format('MMM');
  const startDay = moment(startDate).format('D');
  const endMonth = moment(endDate).format('MMM');
  const endDay = moment(endDate).format('D');

  if (startYear === endYear) {
    return startMonth === endMonth
      ? `${startMonth} ${startDay} - ${endDay}, ${startYear}`
      : `${startMonth} ${startDay} - ${endMonth} ${endDay}, ${startYear}`;
  } else {
    return `${startMonth} ${startDay}, ${startYear} - ${endMonth} ${endDay}, ${endYear}`;
  }
};

export {
  parseQuery,
  getStringifiedQueryString,
  getAmenitiesFromParams,
  vehicleAmenitiesFromParams,
  createQueryParams,
  getListingImageUrl,
  updateQueryString,
  calculateMaxes,
  isOutsideRange,
  renderName,
  buildDetailsLink,
  renderFeatures,
  buildAddress,
  smallImageDefault,
  buildDateDisplay
};
