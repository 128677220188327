import React, { useEffect, useState } from 'react';
import ReactI18n from 'react-i18n';
import { useSelector } from 'react-redux';
import {
  IconFontAwesome,
  InfoPanel,
  Spacer
} from '@directsoftware/ui-kit-web-admin';
import { selectReceiptState } from '../../../redux/slices/receipt';
import { selectPaymentState } from '../../../redux/slices/payment';

const ConfirmationHeader = ({ usePaymentState }) => {
  const receiptState = usePaymentState
    ? useSelector(selectPaymentState)
    : useSelector(selectReceiptState);
  const brand = useSelector(state => state.brand);
  const translate = ReactI18n.getIntlMessage;
  const [localPanelText, setLocalPanelText] = useState('');
  const [localPanelColor, setLocalPanelColor] = useState('gray');
  const [localIcon, setLocalIcon] = useState('times');
  const [localDescription, setLocalDescription] = useState(null);
  const [showInfoPanel, setShowInfoPanel] = useState(false);

  const parseLynbrookNotes = () => {
    if (receiptState.booking.notes.length > 0) {
      const failedPayment = receiptState.booking.notes.filter(note =>
        note.message.includes('Lynnbrook Card Error')
      );
      return failedPayment[0].message.replace('Lynnbrook Card Error: ', '');
    }
    return false;
  };

  useEffect(
    () => {
      if (usePaymentState) {
        if (receiptState.lynnbrookCardPaymentFailed) {
          setLocalPanelText('Payment Failure');
          setLocalDescription(
            'We could not complete your payment with the card you provided. Please check the card info and try again.'
          );
          setLocalPanelColor('red');
          setLocalIcon('times');
          setShowInfoPanel(true);
        } else {
          setShowInfoPanel(false);
        }
      } else if (receiptState.booking.cancelled) {
        setLocalPanelText(translate(`cx.receipt.booking_status.canceled`));
        setLocalPanelColor('gray');
        setLocalIcon('times');
        setShowInfoPanel(true);
      } else if (receiptState.booking.confirmed) {
        setLocalPanelText(
          receiptState.verified
            ? translate(`cx.receipt.booking_status.verified`)
            : translate(`cx.receipt.booking_status.confirmed`)
        );
        setLocalPanelColor('green');
        setLocalIcon('checkCircle');
        setShowInfoPanel(true);
      } else {
        setLocalPanelText(translate(`cx.receipt.booking_status.unconfirmed`));
        setLocalPanelColor('yellow');
        setLocalIcon('exclamationTriangle');
        setShowInfoPanel(true);
        if (
          brand.organization.payment_processor === 2 &&
          receiptState.payment_failure
        ) {
          setLocalPanelText(`${parseLynbrookNotes()}`);
          setLocalDescription(
            `The property manager will contact you ASAP. In the meantime, your booking dates have been blocked off for you until this is resolved.`
          );
        }
      }
    },
    [receiptState.booking, receiptState.lynnbrookCardPaymentFailed]
  );

  if (!showInfoPanel) return null;

  return (
    <>
      <InfoPanel
        color={localPanelColor}
        headline={localPanelText}
        description={localDescription}
        icon={<IconFontAwesome name={localIcon} />}
      />
      {usePaymentState && <Spacer />}
    </>
  );
};

export default ConfirmationHeader;
