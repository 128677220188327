import React from 'react';
import { Box, Divider, Spacer } from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import CheckoutPricingFees from './checkout-pricing-fees';
import CheckoutPricingRates from './checkout-pricing-rates';
import CheckoutPricingAddons from './checkout-pricing-addons';
import CheckoutPricingCouponCode from './checkout-pricing-coupon-code';
import CheckoutPricingTotalAndPayment from './checkout-pricing-total-and-payment';
import { useDetectMobile } from '../../../../shared/hooks/useDetectMobile';

const CheckoutPricingInfo = ({ updateCheckoutState }) => {
  const brand = useSelector(state => state.brand);
  const { isLargeTabletOrGreater } = useDetectMobile();

  return (
    <>
      <Box
        padding={isLargeTabletOrGreater ? 's' : null}
        paddingVertical={isLargeTabletOrGreater ? null : 's'}
      >
        <CheckoutPricingRates />
        <CheckoutPricingFees />
        <Spacer size="m" />
        <CheckoutPricingAddons />
        <CheckoutPricingCouponCode updateCheckoutState={updateCheckoutState} />
      </Box>
      <Divider />
      <CheckoutPricingTotalAndPayment />
    </>
  );
};

export default CheckoutPricingInfo;
