import React from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  DisplayLineItem,
  FlexBox,
  IconFontAwesome,
  Spacer,
  TextBody,
  TextDense,
  Tooltip
} from '@directsoftware/ui-kit-web-admin';
import { filter, sortBy } from 'lodash';
import { selectCheckoutState } from '../../../redux/slices/checkout';
import { formatCurrency } from '../../../../shared/helpers';

const CheckoutPricingAddons = () => {
  const checkoutState = useSelector(selectCheckoutState);
  const brand = useSelector(state => state.brand);
  const addonFees = filter(checkoutState.fees, ['is_addon', 'true']);
  const sortedAddonFees = sortBy(addonFees, ['name']);

  const renderFeeQuantity = feeId => {
    return checkoutState.feeQuantities.find(feeQ => feeQ.id === feeId);
  };

  const renderFeeNonQuantity = feeId => {
    return checkoutState.addonFeeIds.find(feeQ => feeQ === feeId);
  };

  const renderFeeValue = fee => {
    const hasLosVariableValue =
      fee.additional_data.los_ranges_at_creation &&
      fee.additional_data.los_ranges_at_creation.length > 0;
    return hasLosVariableValue
      ? fee.value * renderFeeQuantity(fee.id).quantity
      : fee.value;
  };

  return (
    checkoutState.addonFeeIds.length > 0 && (
      <>
        <TextBody weight="semibold" textColor="dark-gray">
          Extra Add-Ons
        </TextBody>
        <Spacer size="xs" />
        {sortedAddonFees.map((fee, index) => {
          if (fee.fee_account.quantity_fee) {
            const showFee =
              renderFeeQuantity(fee.id) &&
              renderFeeQuantity(fee.id).quantity > 0;
            return showFee ? (
              <DisplayLineItem
                key={`addon-${index}`}
                label={
                  <FlexBox gap="s" alignItems="center">
                    {fee.description && (
                      <Box>
                        <Tooltip
                          wrapperClassName="addonFee__tooltip"
                          tipContent={<TextDense>{fee.description}</TextDense>}
                        >
                          <IconFontAwesome
                            name="questionCircle"
                            iconStyle="regular"
                          />
                        </Tooltip>
                      </Box>
                    )}
                    {`${fee.name} x ${renderFeeQuantity(fee.id).quantity}`}
                  </FlexBox>
                }
                value={formatCurrency(renderFeeValue(fee), brand.currency)}
                collapseOnMobile={false}
                padding="xxs"
                labelIsRegularWeight
                removeBottomBorder
              />
            ) : (
              <React.Fragment key={`addon-${index}`} />
            );
          } else {
            const showFee = renderFeeNonQuantity(fee.id);
            return showFee ? (
              <DisplayLineItem
                key={`addon-${index}`}
                label={
                  <FlexBox gap="s" alignItems="center">
                    {fee.description && (
                      <Box>
                        <Tooltip
                          wrapperClassName="addonFee__tooltip"
                          tipContent={<TextDense>{fee.description}</TextDense>}
                        >
                          <IconFontAwesome
                            name="questionCircle"
                            iconStyle="regular"
                          />
                        </Tooltip>
                      </Box>
                    )}
                    {fee.name}
                  </FlexBox>
                }
                value={formatCurrency(fee.value, brand.currency)}
                collapseOnMobile={false}
                padding="xxs"
                labelIsRegularWeight
                removeBottomBorder
              />
            ) : (
              <React.Fragment key={`addon-${index}`} />
            );
          }
        })}
        <Spacer size="m" />
      </>
    )
  );
};

export default CheckoutPricingAddons;
