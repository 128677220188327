import React from 'react';
import { useSelector } from 'react-redux';
import {
  FlexBox,
  Box,
  Spacer,
  TextH2,
  TextBody
} from '@directsoftware/ui-kit-web-admin';
import { formatCurrency } from '../../../../shared/helpers';

const Usages = () => {
  const usages = useSelector(state => state.listing.usages);
  const organization = useSelector(state => state.brand.organization);

  if (!usages || usages.length === 0) return null;

  return (
    <>
      <Spacer size="l" />
      <FlexBox>
        {usages.map((usage, idx) => (
          <Box flex="1" key={`usage-${idx}`}>
            <TextH2 isFullWidth>{usage.name}</TextH2>
            <Spacer />
            <TextBody isFullWidth>
              {`${formatCurrency(
                usage.calculation_amount,
                organization.currency
              )} per ${usage.usage_type === 0 ? 'mile' : 'hour'}`}
            </TextBody>
            {usage.amount_free > 0 && (
              <TextBody>
                {`${usage.amount_free} ${
                  usage.usage_type === 0 ? 'mile(s)' : 'hour(s)'
                } included`}
              </TextBody>
            )}
          </Box>
        ))}
      </FlexBox>
    </>
  );
};

export default Usages;
