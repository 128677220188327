import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  idPhoto: null,
  signature: null,
  age_verified: false,
  address_verified: false,
  terms_accepted: false,
  adrStreet: '',
  adrUnit: '',
  adrCity: '',
  adrState: '',
  adrCountry: '',
  adrPostalCode: '',
  availability: {},
  booking: {},
  charges: [],
  customer: {},
  customerVerifiedAddress: null,
  customerVerifiedAge: null,
  customerVerifiedId: null,
  customerVerifiedSignature: null,
  featured_image: {},
  listing: {},
  location: {},
  property: {},
  rentalAgreement: {},
  propertyManager: {},
  slug: '',
  unit: {},
  verify_id: null,
  verify_id_description: '',
  verify_signature: null,
  verify_address: null,
  verify_age: null,
  required_age: null,
  contractTermsAndConditions: null
};

const contractSlice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    updateContractSlice: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetContractSlice: state => {
      return { ...state, ...initialState };
    }
  }
});

export const {
  updateContractSlice,
  resetContractSlice
} = contractSlice.actions;

export default contractSlice.reducer;

export const selectContractState = createSelector(
  state => state.contract,
  contract => contract
);
