import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Divider,
  PageStateMessage,
  Panel,
  Spacer,
  FlexBox,
  TextH2
} from '@directsoftware/ui-kit-web-admin';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import CheckoutSteps from '../checkout/shared/checkout-steps';
import { updateUiSlice } from '../../redux/slices/ui';
import {
  selectReceiptState,
  updateReceiptSlice
} from '../../redux/slices/receipt';
import PropertyManagerInfo from './shared/property-manager-info';
import DepositInfo from './shared/deposit-info';
import FooterFinal from '../layout/footer-final';
import { useDetectMobile } from '../../../shared/hooks/useDetectMobile';
import RemainingTotal from '../shared/remaining-total';
import PaymentBreakdown from '../shared/payment-breakdown';
import ReceiptSummary from './shared/receipt-summary';
import CancellationPolicy from '../listing/shared-v2/cancellation-policy';
import LocationMap from '../listing/shared-v2/location-map';
import ReceiptBookingInfo from './shared/receipt-booking-info';
import DeliveryLocationMap from './shared/delivery-location-map';

const ReceiptV2 = props => {
  const { isMobile, isLargeDesktop } = useDetectMobile();
  const dispatch = useDispatch();
  const receiptState = useSelector(selectReceiptState);
  const [loading, setLoading] = useState(true);

  const updateReceiptState = data => {
    dispatch(updateReceiptSlice(data));
  };

  const fetchData = () => {
    axios
      .post(
        `${process.env.DIRECT_URL}/api/v2/my-bookings/receipt/${
          props.match.params.booking_code
        }`
      )
      .then(response => {
        updateReceiptState({ ...response.data });
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const rightColumnContent = () => {
    return (
      <>
        <Panel removeBorder={isMobile}>
          <Box
            paddingHorizontal="s"
            paddingTop={isMobile ? null : 's'}
            paddingBottom="s"
          >
            <TextH2>Price Breakdown</TextH2>
            <Spacer size="m" />
            <PaymentBreakdown stateObject={receiptState} />
          </Box>
          <Divider />
          <Box padding="s">
            <RemainingTotal
              priceTotal={receiptState.booking.price_total}
              pricePaid={receiptState.booking.price_paid}
            />
          </Box>
          {receiptState.securityDepositRequired &&
            receiptState.securityDeposit && (
              <>
                <Divider />
                <Box padding="s">
                  <DepositInfo />
                </Box>
              </>
            )}
        </Panel>
        <Spacer />
        {receiptState.property_manager && (
          <Panel>
            <PropertyManagerInfo />
          </Panel>
        )}
      </>
    );
  };

  useEffect(() => {
    dispatch(updateUiSlice({ checkoutActiveStep: 'stepThree' }));
    fetchData();
  }, []);

  return (
    <>
      <Container variation="extended-wls">
        <CheckoutSteps removeBottomSpacer={isMobile} />
      </Container>
      <Container variation={isMobile ? 'full-width' : 'extended-wls'}>
        {loading ? (
          <PageStateMessage
            iconProps={{ name: 'spinner', spin: true }}
            headline="Getting your booking details..."
          />
        ) : (
          <>
            <FlexBox
              className="detailsLayout newReceipt"
              flexDirection={isLargeDesktop ? 'row' : 'column'}
            >
              <Box flex="1" className="detailsLayout__left">
                <ReceiptSummary />
                <Spacer size={isMobile ? 's' : 'l'} />
                <Box
                  padding={isMobile ? 's' : null}
                  style={isMobile ? { paddingBottom: 0 } : null}
                >
                  <ReceiptBookingInfo />
                  {receiptState.listing.refund_policy && (
                    <CancellationPolicy listingState={receiptState.listing} />
                  )}
                  {receiptState.isVehicle && receiptState.delivery_location && (
                    <DeliveryLocationMap shortMap />
                  )}
                  <LocationMap locationState={receiptState.location} shortMap />
                </Box>
              </Box>
              {isLargeDesktop ? (
                <Box className="detailsLayout__right">
                  <Box className="detailsLayout__bookingSidebar">
                    {rightColumnContent()}
                  </Box>
                </Box>
              ) : (
                rightColumnContent()
              )}
            </FlexBox>
          </>
        )}
      </Container>
      <Spacer size="xxl" />
      <FooterFinal />
    </>
  );
};

export default ReceiptV2;
