import React from 'react';
import {
  Box,
  FlexBox,
  FlexBoxGrid,
  IconFontAwesome,
  Spacer,
  TextBody,
  TextDense,
  TextH2
} from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import StarRating from './star-rating';

const Reviews = () => {
  const listing = useSelector(state => state.listing);
  const brand = useSelector(state => state.brand);

  const getAverage = () => {
    let totalRating = 0;
    listing.reviews.forEach(review => {
      totalRating += review.rating;
    });
    return (totalRating / listing.reviews.length)
      .toFixed(2)
      .replace(/[.,]00$/, '');
  };

  return (
    <>
      <FlexBox gap="xs" alignItems="center">
        <IconFontAwesome name="star" />
        <TextH2 textColor="dark-gray">
          {`${getAverage()} avg. - ${listing.reviews.length} Reviews`}
        </TextH2>
      </FlexBox>
      <Spacer size="m" />
      <FlexBoxGrid columns={2} gap="l">
        {listing.reviews.map((review, idx) => (
          <Box key={`review-${idx}`}>
            <TextBody weight="semibold" textColor="dark-gray">
              {review.name}
            </TextBody>
            <FlexBox alignItems="center" gap="xs">
              <StarRating rating={review.rating} />
              <TextDense textColor="light-gray" weight="regular">
                {moment(review.reviewed_date || review.created_at).format(
                  brand.displayFormat === 'DD/MM/YYYY'
                    ? 'DD MMM, YYYY'
                    : 'MMM DD, YYYY'
                )}
              </TextDense>
            </FlexBox>
            <Spacer />
            <Box className="customHtmlStyles">
              <div dangerouslySetInnerHTML={{ __html: review.body }} />
            </Box>
            {review.response && (
              <>
                <Spacer />
                <FlexBox gap="m">
                  <Box paddingTop="xxs">
                    <IconFontAwesome
                      name="arrowTurnUp"
                      flip="horizontal"
                      color="#dcdde3"
                    />
                  </Box>
                  <Box>
                    <TextBody textColor="dark-gray" weight="semibold">
                      The Manager Replied
                    </TextBody>
                    <Box className="customHtmlStyles">
                      <div
                        dangerouslySetInnerHTML={{ __html: review.response }}
                      />
                    </Box>
                  </Box>
                </FlexBox>
              </>
            )}
          </Box>
        ))}
      </FlexBoxGrid>
    </>
  );
};

export default Reviews;
