import React, { useState, useEffect, useRef } from 'react';
import Geosuggest from 'react-geosuggest';
import Script from 'react-load-script';
import ReactI18n from 'react-i18n';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectListingsDetailsSingleState,
  updateListingsDetailsSingleSlice
} from '../../redux/slices/listingsDetailsSingle';
import Spinner from '../listings/resources/spinner';
import { Label, Spacer, FlexBox, TextDense } from '@directsoftware/ui-kit-web-admin';

const DeliveryLocationForm = ({ listing, show, respondToDeliveryLocationChange }) => {
  const dispatch = useDispatch();
  const adrGeoSuggest = useRef(null);
  const listingsDetailsState = useSelector(selectListingsDetailsSingleState);

  const [state, setState] = useState({
    name: null,
    vehicle: listing.vehicle,
    location: listing.location,
    googlePlaceId: '',
    adrStreet: null,
    adrUnit: null,
    adrCity: null,
    adrState: null,
    adrCountry: null,
    adrPostalCode: null,
    adrCountryShort: null,
    setGeoInputFocused: false,
    inRange: false
  });

  const unsetDeliveryLocation = () => {
    if (listingsDetailsState.deliveryLocation) {
      dispatch(
        updateListingsDetailsSingleSlice({
          ...listingsDetailsState,
          deliveryLocation: null
        })
      );
    }
  };

  const findAddressPart = (adrComponents, adr_part) => {
    return adrComponents.find(e => {
      return e.types[0] === adr_part;
    });
  };

  const updateOnSuggestSelected = suggest => {
    try {
      const { lat, lng } = suggest.location;
      const businessName = suggest.gmaps.name;
      const businessPlaceId = suggest.placeId;
      const adrComponents = suggest.gmaps.address_components;
      const adrPostalCode = findAddressPart(adrComponents, 'postal_code');
      const adrCountry = findAddressPart(adrComponents, 'country');
      const adrState = findAddressPart(
        adrComponents,
        'administrative_area_level_1'
      );
      const adrCity = findAddressPart(adrComponents, 'locality');
      const adrStreetName = findAddressPart(adrComponents, 'route');
      const adrStreetNumber = findAddressPart(
        adrComponents,
        'street_number'
      );
      const maxDistance = parseFloat(state.vehicle.delivery_base_miles || 0) + parseFloat(state.vehicle.delivery_overage_mile_limit || 0)
      const distance = haversineDistance(lat, lng, state.location.geo_latitude, state.location.geo_longitude, maxDistance);
      const inRange = distance <= maxDistance;
      let price = 0;
      if (inRange) {
        const coverageFee = Math.max(distance - parseInt(state.vehicle.delivery_base_miles || 0), 0) * parseFloat(state.vehicle.delivery_overage_rate || 0)
        price = (parseFloat(state.vehicle.delivery_base_fee || 0) + coverageFee).toFixed(2);
      }
      const customLocation = {
        name: businessName,
        googlePlaceId: businessPlaceId,
        adrStreet: `${adrStreetNumber ? adrStreetNumber.long_name : ''} ${adrStreetName ? adrStreetName.long_name : ''
          }`,
        adrUnit: '',
        adrCity: adrCity ? adrCity.long_name : '',
        adrState: adrState ? adrState.long_name : '',
        adrCountry: adrCountry ? adrCountry.long_name : '',
        adrPostalCode: adrPostalCode ? adrPostalCode.long_name : '',
        adrCountryShort: adrCountry ? adrCountry.short_name : '',
        geo_latitude: lat,
        geo_longitude: lng,
        inRange,
        price
      };

      setState({ ...state, ...customLocation });
      if (inRange) {
        sessionStorage.setItem('customLocation', JSON.stringify(customLocation));
        respondToDeliveryLocationChange({ value: JSON.stringify(customLocation) });
      }
    } catch (e) {
      setState({
        ...state,
        adrStreet: ''
      });
    }
  };

  const haversineDistance = (lat1, lon1, lat2, lon2, max) => {
    if (!(lat1 && lon1 && lat2 && lon2)) {
      return max + 1;
    }
    const toRadians = degrees => {
      return degrees * (Math.PI / 180);
    };

    const R = 3958.8; // Earth's radius in miles

    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c;

    return distance;
  };

  const renderInRange = () => {
    const { adrStreet, inRange } = state;
    if (adrStreet) {
      return (
        <>
          <Spacer size="xs" />
          <FlexBox justifyContent="center">
            {inRange ? (
              <Label color="success">In delivery range</Label>
            ) : (
              <Label color="danger">Out of delivery range</Label>
            )}
          </FlexBox>
        </>
      );
    } else {
      return null;
    }
  };

  const renderGeoSuggestedAddress = () => {
    const { name } = state;
    const translate = ReactI18n.getIntlMessage;
    return (
      <div>
        <TextDense weight="semibold" textColor="dark-gray">
          {translate(`global.actions.search`) + " " + translate(`cx.details.location`)}
        </TextDense>
        <Geosuggest
          className={`geoSuggest__textInputWrapper${state.setGeoInputFocused ? ' focused' : ''
            }`}
          inputClassName="geoSuggest__textInput"
          suggestsClassName="geoSuggest__suggestsWrapper"
          suggestItemClassName="geoSuggest__suggestItem"
          placeholder="123 Road St."
          types={['geocode', 'establishment']}
          suggestsHiddenClassName="hidden"
          initialValue={name}
          ref={adrGeoSuggest}
          onSuggestSelect={updateOnSuggestSelected}
          onFocus={() => {
            setState({ ...state, setGeoInputFocused: true });
          }}
          onBlur={() => {
            setState({ ...state, setGeoInputFocused: false });
          }}
        />
        {renderInRange()}
      </div>
    );
  };

  return <>{show && renderGeoSuggestedAddress()}</>;
};

export default DeliveryLocationForm;
