import React from 'react';
import {
  AmenitiesGrid,
  DecorativeHeadline,
  FeaturedListings,
  GridContentBlock,
  Hero,
  MediaContentBlock,
  LongText
} from '@directsoftware/wls-marketing-page-slices';
import { useSelector } from 'react-redux';

const SliceMachine = ({ slice, theme }) => {
  const brand = useSelector(state => state.brand);
  const sliceData = slice.slice_data;

  const tempGuestSpacesCustomParams = () => {
    /**
     * This is a temporary fix to set a default zoom for Guestspaces
     */
    return brand.organization.id === 1037
      ? [
          'geo-ne-lat=31.61388993175555',
          'geo-ne-lon=-96.93111842041104',
          'geo-sw-lat=28.813663930487607',
          'geo-sw-lon=-98.72463648681729',
          'sort=default',
          'zoom=9'
        ]
      : [];
  };

  const rebuildHeroImageGallery = array => {
    const convertedObject = JSON.parse(array);
    return Object.keys(convertedObject).map(img => {
      return {
        url: convertedObject[img].fileImageSrc,
        alt_text: convertedObject[img].fileCaption
      };
    });
  };

  const renderSlice = () => {
    switch (slice.slice_type) {
      case 6:
      case 'brand_page_slice_long_text':
        return (
          <LongText
            theme={theme}
            hasNarrowContainer={sliceData.has_narrow_container}
            marginTop={sliceData.margin_top}
            marginBottom={sliceData.margin_bottom}
            primaryColor={brand.brand_info.colors?.color_primary}
            secondaryColor={brand.brand_info.colors?.color_secondary}
            html={sliceData.html}
          />
        );
      case 5:
      case 'brand_page_slice_amenities':
        return (
          <AmenitiesGrid
            theme={theme}
            hasNarrowContainer={sliceData.has_narrow_container}
            headline={sliceData.headline}
            description={sliceData.description}
            amenities={sliceData.grid_items}
            useCarouselOnMobile={sliceData.use_carousel_on_mobile}
            marginTop={sliceData.margin_top}
            marginBottom={sliceData.margin_bottom}
            secondaryColor={brand.brand_info.colors?.color_secondary}
            secondaryTextColor={brand.brand_info.colors?.color_secondary_text}
          />
        );
      case 4:
      case 'brand_page_slice_featured_listings':
        return (
          <FeaturedListings
            theme={theme}
            hasNarrowContainer={sliceData.has_narrow_container}
            headline={sliceData.headline}
            description={sliceData.description}
            listings={sliceData.listings_data}
            marginTop={sliceData.margin_top}
            marginBottom={sliceData.margin_bottom}
            secondaryColor={brand.brand_info.colors?.color_secondary}
            secondaryTextColor={brand.brand_info.colors?.color_secondary_text}
          />
        );
      case 2:
      case 'brand_page_slice_media_content_block':
        return (
          <MediaContentBlock
            theme={theme}
            hasNarrowContainer={sliceData.has_narrow_container}
            headline={sliceData.headline}
            description={sliceData.description}
            layoutType={sliceData.layout_type}
            image={{
              url: sliceData.bg_image,
              alt_text: sliceData.bg_image_alt_text
            }}
            callToActionLabel={sliceData.call_to_action_label}
            callToActionUrl={sliceData.call_to_action_url}
            marginTop={sliceData.margin_top}
            marginBottom={sliceData.margin_bottom}
            primaryColor={brand.brand_info.colors?.color_primary}
            primaryTextColor={brand.brand_info.colors?.color_primary_text}
            secondaryColor={brand.brand_info.colors?.color_secondary}
            secondaryTextColor={brand.brand_info.colors?.color_secondary_text}
          />
        );
      case 1:
      case 'brand_page_slice_headline':
        return (
          <DecorativeHeadline
            theme={theme}
            hasNarrowContainer={sliceData.has_narrow_container}
            headlineText={sliceData.headline}
            marginTop={sliceData.margin_top}
            marginBottom={sliceData.margin_bottom}
            secondaryColor={brand.brand_info.colors?.color_secondary}
          />
        );
      case 3:
      case 'brand_page_slice_grid_content_block':
        return (
          <GridContentBlock
            theme={theme}
            hasNarrowContainer={sliceData.has_narrow_container}
            headline={sliceData.headline}
            description={sliceData.description}
            columns={sliceData.grid_items || []}
            columnGap={sliceData.column_gap}
            imageAspectRatio={sliceData.image_aspect_ratio}
            numberOfColumns={sliceData.column_amount}
            useImagesAsIcons={sliceData.use_image_as_icons}
            useCarouselOnMobile={sliceData.use_carousel_on_mobile}
            marginTop={sliceData.margin_top}
            marginBottom={sliceData.margin_bottom}
            secondaryColor={brand.brand_info.colors?.color_secondary}
            secondaryTextColor={brand.brand_info.colors?.color_secondary_text}
          />
        );
      case 0:
      case 'brand_page_slice_hero':
        return (
          <Hero
            theme={theme}
            hasNarrowContainer={sliceData.has_narrow_container}
            description={sliceData.description}
            headline={sliceData.headline}
            bgImageGallery={rebuildHeroImageGallery(sliceData.bg_image_gallery)}
            bgImage={sliceData.bg_image}
            bgImageAltText={sliceData.bg_image_alt_text}
            bgType={sliceData.bg_type}
            layoutType={sliceData.layout_type}
            bgImageGalleryAutoPlay={sliceData.bg_image_gallery_autoplay}
            isHome={sliceData.is_home}
            bgVideo={sliceData.bg_video}
            searchLink="/listings/search"
            isVehicle={brand.isRvFleet}
            primaryColor={brand.brand_info.colors?.color_primary}
            primaryTextColor={brand.brand_info.colors?.color_primary_text}
            customParams={tempGuestSpacesCustomParams()}
          />
        );
      default:
        return null;
    }
  };

  return <>{renderSlice()}</>;
};

SliceMachine.propTypes = {};

export default SliceMachine;
