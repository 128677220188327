import React from 'react';
import ReactI18n from 'react-i18n';
import { useSelector } from 'react-redux';
import { Box } from '@directsoftware/ui-kit-web-admin';
import ListingsDetailsContentBlock from '../shared-v2/listings-details-content-block';
import ShowMoreWrapper from '../shared-v2/show-more-wrapper';

const Accommodations = () => {
  const listing = useSelector(state => state.listing);
  const translate = ReactI18n.getIntlMessage;

  return (
    <ListingsDetailsContentBlock
      label={translate(`cx.details.headers.accomodations`)}
      id="details-rules"
    >
      <ShowMoreWrapper>
        <Box className="customHtmlStyles">
          <div
            dangerouslySetInnerHTML={{
              __html: listing.property.summary_accommodations
            }}
          />
        </Box>
      </ShowMoreWrapper>
    </ListingsDetailsContentBlock>
  );
};

export default Accommodations;
