import React from 'react';
import { Box, Container, FlexBox, IconButton, IconFontAwesome, TextBody } from '@directsoftware/ui-kit-web-admin';
import classnames from 'classnames';
import { Gallery, Item } from 'react-photoswipe-gallery';

const PhotoGallery = ({images, reveal, title, onClose}) => {
  const onBeforeOpen = (pswpInstance) => {
    pswpInstance.on('beforeOpen', () => {
      const ds = pswpInstance?.options?.dataSource
      if (Array.isArray(ds)) {
        for (let idx = 0, len = ds.length; idx < len; idx++) {
          const item = ds[idx]
          const img = new Image()
          img.onload = () => {
            item.width = img.naturalWidth
            item.height = img.naturalHeight
            pswpInstance?.refreshSlideContent(idx)
          }
          img.src = item.src
        }
      }
    })
  }

  return images && images.length > 0 && (
    <Box
      className={classnames('photoGrid__thumbnailGrid', {
        '-reveal': reveal
      })}
    >
      <FlexBox flexDirection="column" style={{ height: '100%' }}>
        <Box>
          <Container
            variation="fluid-wls"
            className="photoGrid__thumbnailGrid_nav"
          >
            <FlexBox
              justifyContent="space-between"
              paddingVertical="xs"
              alignItems="center"
              gap="s"
            >
              <IconButton
                onClick={onClose}
                variation="secondary"
                appearance="ghost"
              >
                <IconFontAwesome name="chevronLeft" />
              </IconButton>
              <FlexBox flex="1" paddingRight="l" justifyContent="center">
                <TextBody
                  weight="semibold"
                  textAlign="center"
                  isFullWidth
                >
                  {title}
                </TextBody>
              </FlexBox>
            </FlexBox>
          </Container>
        </Box>
        <FlexBox
          paddingVertical="m"
          flexDirection="column"
          flex="1"
          className="photoGrid__thumbnailGrid_scrollArea"
        >
          <Box className="photoGrid__thumbnailGrid_content">
            <FlexBox flexWrap="wrap" gap="xs">
              <Gallery
                options={{ bgOpacity: 0.9, initialZoomLevel: 'fit' }}
                onBeforeOpen={onBeforeOpen}
              >
                {images.map((img, idx) => {
                  return (
                    <Item
                      key={`image-${idx}`}
                      original={img.src}
                    >
                      {({ ref, open }) => (
                        <Box className="photoGrid__thumbnailGrid_item">
                          <Box className="photoGrid__thumbnailGrid_thumbnail">
                            <img
                              src={img.src}
                              alt=""
                              onClick={open}
                              ref={ref}
                            />
                          </Box>
                        </Box>
                      )}
                    </Item>
                  );
                })}
              </Gallery>
            </FlexBox>
          </Box>
        </FlexBox>
      </FlexBox>
    </Box>
  );
};

export default PhotoGallery;
