import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import {
  Box,
  Container,
  FlexBox,
  Spacer
} from '@directsoftware/ui-kit-web-admin';
import { useDetectMobile } from '../../../shared/hooks/useDetectMobile';
import { selectUiState, updateUiSlice } from '../../redux/slices/ui';
import {
  parseQuery,
  updateQueryString
} from '../listings/resources/shared-functions';
import PhotoGrid from './shared-v2/photo-grid';
import ListingName from './shared-v2/listing-name';
import ListingSubHeadline from './shared-v2/listing-subheadline';
import FeatureList from './shared-v2/feature-list';
import DetailsTabNav from './shared-v2/details-tab-nav';
import Description from './shared-v2/description';
import AmenitiesList from './shared-v2/amenities-list';
import TermsAndConditions from './shared-v2/terms-and-conditions';
import LocationMap from './shared-v2/location-map';
import UnitTypes from './multi-v2/unit-types';
import Meta from './meta';
import Accommodations from './multi-v2/accommodations';
import {
  selectListingsDetailsMultiState,
  updateListingsDetailsMultiSlice
} from '../../redux/slices/listingsDetailsMulti';
import FooterFinal from '../layout/footer-final';

const MultiV2 = () => {
  const { isMobile } = useDetectMobile();
  const dispatch = useDispatch();
  const brand = useSelector(state => state.brand);
  const listing = useSelector(state => state.listing);
  const listingsDetailsState = useSelector(selectListingsDetailsMultiState);
  const { listingsDetailsChanged } = useSelector(selectUiState);
  const [localPricings, setLocalPricings] = useState({});
  const [localAvailabilities, setLocalAvailabilities] = useState({});

  const updateDetailsMultiState = (data, isFilterChange = false) => {
    dispatch(updateListingsDetailsMultiSlice(data));
    if (isFilterChange)
      dispatch(updateUiSlice({ listingsDetailsChanged: true }));
  };

  const handleBrowserState = () => {
    const queryInfo = parseQuery(location.search, brand);

    updateDetailsMultiState(
      {
        bookingRange: queryInfo.bookingRange || null,
        bookingLength: queryInfo.bookingRange
          ? queryInfo.bookingRange.length - 1
          : 0,
        checkIn: queryInfo.checkIn || null,
        checkOut: queryInfo.checkOut || null,
        guests: queryInfo.guests || 1,
        isDirty: true,
        datesParsed: true
      },
      true
    );
  };

  const getPricings = () => {
    const pricings = {};

    listing.units.map(unit => {
      const p = getPricing(unit.listing.id);
      p.then(data => {
        pricings[unit.unit.id] = data;
        setLocalPricings(prevState => ({ ...prevState, ...pricings }));
      }).catch(err => {
        console.error(err);
      });
    });
  };

  // Get Pricing
  // ---------------------------------------------
  const getPricing = listingID => {
    const getPricingPromise = new Promise((resolve, reject) => {
      axios
        .get(
          `${
            process.env.DIRECT_URL
          }/api/v2/listings/multi/${listingID}/pricing`,
          {
            headers: { 'Content-Type': 'application/json' },
            context: this,
            params: {
              booking_range: JSON.stringify(listingsDetailsState.bookingRange),
              addon_fee_ids: listingsDetailsState.addonFeeIds
            }
          }
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
    return getPricingPromise;
  };

  const getAvailabilities = () => {
    const availabilities = {};

    listing.units.map(unit => {
      const p = getAvailability(unit.listing.id, unit.unit.id);
      p.then(data => {
        availabilities[unit.unit.id] = data;
        setLocalAvailabilities(prevState => ({
          ...prevState,
          ...availabilities
        }));
      }).catch(err => {
        console.error(err);
      });
    });
  };

  const getAvailability = (listingID, unitID) => {
    const getAvailability = new Promise((resolve, reject) => {
      axios
        .get(
          `${
            process.env.DIRECT_URL
          }/api/v2/listings/multi/${listingID}/availability`,
          {
            headers: { 'Content-Type': 'application/json' },
            params: {
              unit_id: unitID,
              booking_range: JSON.stringify(listingsDetailsState.bookingRange),
              guests: listingsDetailsState.guests
            }
          }
        )
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
    return getAvailability;
  };

  useEffect(
    () => {
      updateDetailsMultiState({
        pricings: localPricings,
        availabilities: localAvailabilities
      });
    },
    [localAvailabilities, localPricings]
  );

  useEffect(
    () => {
      if (listingsDetailsChanged) {
        dispatch(updateUiSlice({ listingsDetailsChanged: false }));
        if (listingsDetailsState.bookingRange) {
          getAvailabilities();
          getPricings();
          updateQueryString(listingsDetailsState);
        }
      }
    },
    [listingsDetailsState, listingsDetailsChanged]
  );

  useEffect(() => {
    handleBrowserState();
    window.onpopstate = handleBrowserState;
    if (window.location.hash) {
      const id = window.location.hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) element.scrollIntoView();
    }
  }, []);

  return (
    <>
      <Meta />
      {!isMobile && <Spacer size="m" />}
      <Container variation={isMobile ? 'full-width' : 'fluid-wls'}>
        <PhotoGrid />
      </Container>
      <Spacer size="m" />
      <Container variation="extended-wls">
        <FlexBox className="detailsLayout">
          <Box flex="1">
            <ListingName />
            <Spacer size="xxs" />
            <ListingSubHeadline />
            <Spacer />
            <FeatureList />
            {!isMobile && <Spacer size="m" />}
            <DetailsTabNav isMultiUnit />
            <Spacer size="l" />
            <Box id="details-overview" />
            <Description />
            <Box id="details-units" />
            <UnitTypes updateDetailsMultiState={updateDetailsMultiState} />
            <AmenitiesList />
            <TermsAndConditions />
            {listing.property.summary_accommodations && <Accommodations />}
          </Box>
        </FlexBox>
        <Box id="details-location" />
        <LocationMap />
        <Spacer size="l" />
      </Container>
      {/* <Container variation="fluid-wls" className="footer-main">
        asd
      </Container> */}
      <FooterFinal />
    </>
  );
};

export default MultiV2;
