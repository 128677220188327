import React, { useState } from 'react';
import {
  Box,
  Divider,
  FlexBox,
  InputIncrementer,
  InputButtonSelect,
  Label,
  PopoverForm,
  Spacer,
  TextBody,
  TextH2,
  InputSelect,
  TextDense
} from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import ReactI18n from 'react-i18n';
import moment from 'moment';
import { isInclusivelyBeforeDay } from 'react-dates';
import { selectCheckoutState } from '../../../redux/slices/checkout';
import BookingDatePicker from '../../listing/single/booking-date-picker';
import DeliveryLocationForm from '../../forms/delivery-location-form';

const TripDetails = ({ updateCheckoutState }) => {
  const brand = useSelector(state => state.brand);
  const checkoutState = useSelector(selectCheckoutState);
  const vehicle = checkoutState.property;
  const translate = ReactI18n.getIntlMessage;

  const [locationType, setLocationType] = useState('single');

  const formatDate = date => {
    return brand.date_format === 'DD/MM/YYYY'
      ? moment(date).format('DD MMM, YYYY')
      : moment(date).format('MMM DD, YYYY');
  };

  const respondToDatesChange = (checkInDate, checkOutDate) => {
    if (isInclusivelyBeforeDay(checkInDate, checkOutDate)) {
      const bookingRange = [];
      const d = checkInDate.clone();
      while (isInclusivelyBeforeDay(d, checkOutDate)) {
        bookingRange.push({
          key: d.format('DD-MM-YYYY'),
          day: d.day()
        });
        d.add(1, 'days');
      }

      updateCheckoutState(
        {
          availability: null,
          bookingType: null,
          bookingRange,
          bookingLength: bookingRange.length - 1,
          datesParsed: true,
          pricing: null,
          checkInDate,
          checkOutDate,
          updateUrl: true
        },
        true
      );
    }
  };

  const respondToGuestsChange = guests => {
    updateCheckoutState(
      {
        availability: null,
        bookingType: null,
        pricing: null,
        guests,
        updateUrl: true
      },
      true
    );
  };

  const respondToDeliveryLocationChange = loc => {
    updateCheckoutState(
      {
        availability: null,
        bookingType: null,
        pricing: null,
        updateUrl: true,
        deliveryLocation: loc.value
      },
      true
    );
  };

  const renderDeliveryLocation = () => {
    if (checkoutState.delivery_locations?.length > 0 && checkoutState.property.delivery) {
      return (
        <>
          <FlexBox alignItems="center" justifyContent="space-between" gap="s">
            <TextDense weight="semibold" textColor="dark-gray">
              {translate(
                `cx.global.details.booking_details.delivery_location.${locationType}`
              )}
            </TextDense>
            <InputButtonSelect removeBottomSpacer>
              <InputButtonSelect.Button
                isDisabled={locationType === 'preset'}
                onClick={() => setLocationType('preset')}
              >
                {translate('cx.global.rv_fleet.preset')}
              </InputButtonSelect.Button>
              <InputButtonSelect.Button
                isDisabled={locationType === 'custom'}
                onClick={() => setLocationType('custom')}
              >
                {translate('cx.global.rv_fleet.custom')}
              </InputButtonSelect.Button>
            </InputButtonSelect>
          </FlexBox>
          {locationType === 'preset' && (
            <>
              <Spacer />
              <InputSelect
                options={checkoutState.delivery_locations.map(loc => ({
                  label: loc.name,
                  value: loc.id
                }))}
                onChange={respondToDeliveryLocationChange}
              />
            </>
          )}
          {locationType === 'custom' && <Spacer />}
          <div>
            <DeliveryLocationForm
              listing={{vehicle: checkoutState.property, location: checkoutState.location}}
              respondToDeliveryLocationChange={respondToDeliveryLocationChange}
              show={locationType === 'custom'}
            />
          </div>
        </>
      );
    } else if (checkoutState.delivery_locations?.length > 0) {
      return (
        <>
          <Spacer />
          <FlexBox alignItems="center" justifyContent="space-between">
            <TextDense weight="semibold" textColor="dark-gray">
              {translate(
                'cx.global.details.booking_details.delivery_location.single'
              )}
            </TextDense>
            <InputSelect
              options={checkoutState.delivery_locations.map(loc => ({
                label: loc.name,
                value: loc.id
              }))}
              onChange={respondToDeliveryLocationChange}
            />
          </FlexBox>
        </>
      );
    } else if (checkoutState.property?.delivery) {
      return (
        <div>
          <DeliveryLocationForm
            listing={{vehicle: checkoutState.property, location: checkoutState.location}}
            respondToDeliveryLocationChange={respondToDeliveryLocationChange}
            show
          />
        </div>
      );
    }
  };

  return (
    <>
      <TextH2>Trip Details</TextH2>
      <Spacer size="xs" />
      <FlexBox
        alignItems="center"
        justifyContent="space-between"
        style={{ paddingTop: 12, paddingBottom: 12 }}
      >
        <Box>
          <TextBody isFullWidth weight="semibold" textColor="dark-gray">
            {`${formatDate(checkoutState.checkInDate)} — ${formatDate(
              checkoutState.checkOutDate
            )}`}
          </TextBody>
          <TextBody>
            {translate(
              `global.parsers.num_nights.${checkoutState.bookingLength > 1 ? 'plural' : 'single'
              }`,
              { nights: checkoutState.bookingLength }
            )}
          </TextBody>
        </Box>
        {checkoutState.datesParsed && checkoutState.availability && (
          <Box>
            <PopoverForm
              triggerLabel="Change"
              triggerProps={{
                appearance: 'ghost',
                customButtonColor: brand.brand_info.colors?.color_primary,
                customTextColor: brand.brand_info.colors?.color_primary_text
              }}
              hideActionBar
              dropdownWidth="s"
              dropdownAlignment="bottomRight"
              switchToBottomSheetAt="mobile"
              mobileBottomSheetProps={{
                snapPoints: ({ maxHeight }) => maxHeight - 56
              }}
            >
              <BookingDatePicker
                label={
                  brand.isRvFleet
                    ? 'Pick up & Drop Dates'
                    : 'Check-in & Check-out Dates'
                }
                checkInDate={checkoutState.checkInDate}
                checkOutDate={checkoutState.checkOutDate}
                respondToDatesChange={respondToDatesChange}
                unitID={checkoutState.unit.id}
                availability_calendar={
                  checkoutState.availability.availability_calendar
                }
                defaultAvailability={
                  checkoutState.availability.default_availability_changeover
                }
                useDesignKit
              />
            </PopoverForm>
          </Box>
        )}
      </FlexBox>
      <Divider />
      <FlexBox
        alignItems="center"
        style={{ paddingTop: 12, paddingBottom: 12 }}
      >
        <Box flex="1">
          <TextBody isFullWidth weight="semibold" textColor="dark-gray">
            {`${checkoutState.guests} ${checkoutState.guests > 1 ? 'Guests' : 'Guest'
              }`}
          </TextBody>
          <TextBody>
            {`${translate('cx.global.amenities.sleeps')} ${checkoutState.unit
              .num_sleep || checkoutState.unit.num_sleep_in_beds}`}
          </TextBody>
        </Box>
        <Box>
          <PopoverForm
            triggerLabel="Change"
            triggerProps={{
              appearance: 'ghost',
              customButtonColor: brand.brand_info.colors?.color_primary,
              customTextColor: brand.brand_info.colors?.color_primary_text
            }}
            hideActionBar
            dropdownWidth="s"
            dropdownAlignment="bottomRight"
            mobileBottomSheetProps={{
              snapPoints: ({ maxHeight }) => maxHeight - 56
            }}
          >
            <>
              <Label>
                {translate(
                  'cx.global.details.booking_details.number_of_guests'
                )}
              </Label>
              <InputIncrementer
                onChange={value => {
                  respondToGuestsChange(value);
                }}
                value={checkoutState.guests}
                removeBottomSpacer
                min={1}
                max={checkoutState.unit.num_sleep}
              />
            </>
          </PopoverForm>
        </Box>
      </FlexBox>
      <Divider />
      {checkoutState.delivery_locations?.length > 0 && (
        <FlexBox
          alignItems="center"
          style={{ paddingTop: 12, paddingBottom: 12 }}
        >
          <Box flex="1">
            <TextBody isFullWidth weight="semibold" textColor="dark-gray">
              {`${translate(
                'cx.global.details.booking_details.delivery_location.single'
              )}`}
            </TextBody>
            <TextBody>
              {
                checkoutState.delivery_locations.find(
                  loc => loc.id == checkoutState.deliveryLocation
                )?.name || checkoutState.deliveryLocation && JSON.parse(checkoutState.deliveryLocation)?.name
              }
            </TextBody>
          </Box>
          <Box>
            <PopoverForm
              triggerLabel="Change"
              triggerProps={{
                appearance: 'ghost'
              }}
              hideActionBar
              dropdownWidth="s"
              dropdownAlignment="bottomRight"
              mobileBottomSheetProps={{
                snapPoints: ({ maxHeight }) => maxHeight - 56
              }}
            >
              <>
                {renderDeliveryLocation()}
              </>
            </PopoverForm>
          </Box>
        </FlexBox>
      )}
      <Spacer size="l" />
    </>
  );
};

export default TripDetails;
