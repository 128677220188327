import React, { useEffect, useState } from 'react';
import {
  FlexBox,
  CallToActionLink,
  IconFontAwesome,
  Divider,
  Spacer,
  TextCaption,
  Pill
} from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import ReactI18n from 'react-i18n';
import { buildDetailsLink } from '../../listings/resources/shared-functions';
import { selectReceiptState } from '../../../redux/slices/receipt';

const ReceiptSummaryConfirmation = props => {
  const receiptState = useSelector(selectReceiptState);
  const brand = useSelector(state => state.brand);
  const translate = ReactI18n.getIntlMessage;
  const [pillColor, setPillColor] = useState('green');
  const [pillLabel, setPillLabel] = useState('CONFIRMED');
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const usePaymentState = false;

  const parseLynbrookNotes = () => {
    if (receiptState.booking.notes.length > 0) {
      const failedPayment = receiptState.booking.notes.filter(note =>
        note.message.includes('Lynnbrook Card Error')
      );
      return failedPayment[0].message.replace('Lynnbrook Card Error: ', '');
    }
    return false;
  };

  useEffect(
    () => {
      if (usePaymentState) {
        if (receiptState.lynnbrookCardPaymentFailed) {
          setPillLabel('Payment Failure');
          setConfirmationMessage(
            'We could not complete your payment with the card you provided. Please check the card info and try again.'
          );
          setPillColor('red');
        }
      } else if (receiptState.booking.cancelled) {
        setPillLabel(translate(`cx.receipt.booking_status_short.canceled`));
        setPillColor('gray');
      } else if (receiptState.booking.confirmed) {
        setPillLabel(
          receiptState.verified
            ? translate(`cx.receipt.booking_status_short.verified`)
            : translate(`cx.receipt.booking_status_short.confirmed`)
        );
        setPillColor('green');
      } else {
        setPillLabel(translate(`cx.receipt.booking_status_short.unconfirmed`));
        setPillColor('yellow');
        if (
          brand.organization.payment_processor === 2 &&
          receiptState.payment_failure
        ) {
          setPillLabel(`${parseLynbrookNotes()}`);
          setConfirmationMessage(
            `The property manager will contact you ASAP. In the meantime, your booking dates have been blocked off for you until this is resolved.`
          );
        }
      }
    },
    [receiptState.booking, receiptState.lynnbrookCardPaymentFailed]
  );

  return (
    <>
      <FlexBox justifyContent="space-between" alignItems="center">
        <Pill color={pillColor}>{pillLabel}</Pill>
        <CallToActionLink
          href={buildDetailsLink(receiptState)}
          appendIcon={<IconFontAwesome name="externalLinkAlt" />}
          appearance="ghost"
          size="dense"
          openNewTab
        >
          Listing
        </CallToActionLink>
      </FlexBox>
      {confirmationMessage !== '' && (
        <>
          <TextCaption>{confirmationMessage}</TextCaption>
          <Divider paddingTop="xs" paddingBottom="xs" />
        </>
      )}
      <Spacer size="xs" />
    </>
  );
};

ReceiptSummaryConfirmation.propTypes = {};

export default ReceiptSummaryConfirmation;
