import React from 'react';
import {
  FlexBox,
  IconFontAwesome,
  IconLink,
  Spacer,
  TextH1,
  TextH2
} from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import { useDetectMobile } from '../../../shared/hooks/useDetectMobile';

const PageHeader = ({ backLink, label }) => {
  const brand = useSelector(state => state.brand);
  const { isDesktop } = useDetectMobile();

  return (
    <>
      {isDesktop && <Spacer size="m" />}
      <FlexBox className="checkoutSteps__navbar" alignItems="center">
        <FlexBox gap="xs" alignItems="center">
          {backLink && (
            <IconLink
              href={backLink}
              appearance="ghost"
              customButtonColor={brand.brand_info.colors?.color_primary}
              customTextColor={brand.brand_info.colors?.color_primary_text}
            >
              <IconFontAwesome name="arrowLeft" />
            </IconLink>
          )}
          {isDesktop ? <TextH1>{label}</TextH1> : <TextH2>{label}</TextH2>}
        </FlexBox>
      </FlexBox>
      <Spacer size="m" />
    </>
  );
};

export default PageHeader;
