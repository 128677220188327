import React, { useState } from 'react';
import {
  Badge,
  Box,
  CallToActionButton,
  IconButton,
  IconFontAwesome,
  Modal
} from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import { selectListingsSearchState } from '../../../redux/slices/listingsSearch';
import VehiclesSearchAmenities from './vehicles-search-amenities';
import ListingsSearchAmenities from './listings-search-amenities';
import { useDetectMobile } from '../../../../shared/hooks/useDetectMobile';
import {
  getAmenitiesFromParams,
  vehicleAmenitiesFromParams
} from '../resources/shared-functions';

const ListingsSearchFilterAmenities = ({ updateSearchState }) => {
  const { isMobile } = useDetectMobile();
  const searchState = useSelector(selectListingsSearchState);
  const brand = useSelector(state => state.brand);
  const [showFilters, setShowFilters] = useState(false);

  return (
    <>
      <Box setPositionRelative>
        {isMobile ? (
          <IconButton
            onClick={() => {
              setShowFilters(true);
            }}
            variation="secondary"
            appearance="outline"
            size="dense"
          >
            <IconFontAwesome name="grid" iconStyle="regular" />
          </IconButton>
        ) : (
          <CallToActionButton
            onClick={() => {
              setShowFilters(true);
            }}
            variation="secondary"
            appearance="outline"
            size="dense"
            prependIcon={<IconFontAwesome name="grid" iconStyle="regular" />}
          >
            Amenities
          </CallToActionButton>
        )}
        {searchState.selectedAmenities.length > 0 && (
          <Box className="searchFilterBadge">
            <Badge
              badgeContent={searchState.selectedAmenities.length}
              isInline
            />
          </Box>
        )}
      </Box>
      <Modal
        title="Amenities"
        reveal={showFilters}
        headerIcon={
          <IconFontAwesome name="grid" iconStyle="regular" color="white" />
        }
        size="m"
        closeOnClick={() => setShowFilters(false)}
        fullHeight
      >
        <Modal.Content contentIsScrollable>
          <Box paddingVertical="s" paddingHorizontal="xs">
            {brand.isRvFleet ? (
              <VehiclesSearchAmenities updateSearchState={updateSearchState} />
            ) : (
              <ListingsSearchAmenities updateSearchState={updateSearchState} />
            )}
          </Box>
        </Modal.Content>
        <Modal.Footer
          primaryOnClick={() => {
            if (searchState.isDirty) {
              updateSearchState(
                {
                  amenities: brand.isRvFleet
                    ? vehicleAmenitiesFromParams(searchState.selectedAmenities)
                    : getAmenitiesFromParams(searchState.selectedAmenities),
                  selectedAmenities: searchState.selectedAmenities,
                  isDirty: false
                },
                true
              );
              setShowFilters(false);
            }
          }}
          primaryLabel="Apply"
          primaryIsDisabled={!searchState.isDirty}
          secondaryOnClick={() => {
            updateSearchState(
              { amenities: [], selectedAmenities: [], isDirty: false },
              true
            );
            setShowFilters(false);
          }}
          secondaryLabel="Clear"
          secondaryIsDisabled={searchState.selectedAmenities.length === 0}
          primaryCustomButtonColor={brand.brand_info.colors?.color_primary}
          primaryCustomTextColor={brand.brand_info.colors?.color_primary_text}
        />
      </Modal>
    </>
  );
};

export default ListingsSearchFilterAmenities;
