import React from 'react';
import { useSelector } from 'react-redux';
import { selectUiState } from '../../redux/slices/ui';
import ListingV2 from './listing-v2';
import Listing from './index';

const ListingSwitch = props => {
  const ui = useSelector(selectUiState);

  return ui.isBookingEngine ? <ListingV2 {...props} /> : <Listing {...props} />;
};

export default ListingSwitch;
