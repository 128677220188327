import React, { Component } from 'react';
import ReactI18n from 'react-i18n';
import Link from '../links/link';

class MyComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const translate = ReactI18n.getIntlMessage;

    return (
      <>
        {this.props.chargeAmount > 0 ? (
          <button
            ref={submitBtn => {
              this.submitBtn = submitBtn;
            }}
            onClick={this.props.handleSubmit}
            disabled={this.props.disableCta}
          >
            Process Payment
          </button>
        ) : (
          <button
            id="card-button"
            ref={submitBtn => {
              this.submitBtn = submitBtn;
            }}
            onClick={this.props.handleSubmit}
            disabled={this.props.disableCta}
          >
            {this.props.availability.instant_booking
              ? translate(`cx.global.book.long`)
              : translate(`cx.global.book_inquiry.long`)}
          </button>
        )}
        <small
          className="ancillary"
          dangerouslySetInnerHTML={{
            __html: translate(`cx.global.book_confirm.rules`, {
              property_url: `/listings/${this.props.slug}`
            })
          }}
        />
        {this.props.rental_agreement ? (
          <small className="ancillary">
            <span>{translate(`cx.global.book_confirm.contract`)}</span>
            <Link to={this.props.rental_agreement.short_url} target="_blank">
              {translate(`cx.global.book_confirm.contract_link`)}
            </Link>
            .
          </small>
        ) : null}
      </>
    );
  }
}

MyComponent.propTypes = {};

export default MyComponent;
