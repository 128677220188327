import React from 'react';
import { FlexBox, Spacer } from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import ReactI18n from 'react-i18n';
import FeatureListItem from './feature-list-item';
import { useDetectMobile } from '../../../../shared/hooks/useDetectMobile';

const FeatureList = () => {
  const { isMobile } = useDetectMobile();
  const listing = useSelector(state => state.listing);
  const translate = ReactI18n.getIntlMessage;

  return (
    <>
      <FlexBox
        alignItems={isMobile ? 'flex-start' : 'center'}
        justifyContent="space-between"
        flexDirection={isMobile ? 'column' : 'row'}
      >
        {isMobile && (
          <FlexBox
            alignItems="center"
            gap="m"
            renderAs="ul"
            className="detailsFeatureList"
          >
            {listing.location && (
              <FeatureListItem
                iconName="mapMarkerAlt"
                label={`${listing.location.adr_city}, ${
                  listing.location.adr_state
                }`}
              />
            )}
            {listing.unit.unit_type && (
              <FeatureListItem
                iconName="house"
                label={translate(
                  `global.property_type.${listing.property.property_type}`
                )}
              />
            )}
          </FlexBox>
        )}
        <FlexBox
          alignItems="center"
          gap={isMobile ? 'm' : 'm'}
          renderAs="ul"
          className="detailsFeatureList"
        >
          {listing.location && !isMobile && (
            <FeatureListItem
              iconName="mapMarkerAlt"
              label={`${listing.location.adr_city}, ${
                listing.location.adr_state
              }`}
            />
          )}
          {listing.unit.unit_type && !isMobile && (
            <FeatureListItem
              iconName="house"
              label={translate(
                `global.property_type.${listing.property.property_type}`
              )}
            />
          )}
          {!listing.multi_unit && (
            <>
              {listing.unit.num_bedrooms >= 0 && (
                <FeatureListItem
                  iconName="bed"
                  label={translate(
                    `global.parsers.num_bedrooms_short.${
                      listing.unit.num_bedrooms > 1 ? 'plural' : 'single'
                    }`,
                    { num: listing.unit.num_bedrooms }
                  ).replace('+', '')}
                />
              )}
              {listing.unit.num_bathrooms >= 0 && (
                <FeatureListItem
                  iconName="shower"
                  label={translate(
                    `global.parsers.num_bathrooms_short.${
                      listing.unit.num_bathrooms > 1 ? 'plural' : 'single'
                    }`,
                    { num: listing.unit.num_bathrooms }
                  ).replace('+', '')}
                />
              )}
              {listing.unit.num_sleep && (
                <FeatureListItem
                  iconName="users"
                  label={translate(
                    `global.parsers.num_guests.${
                      listing.unit.num_sleep > 1 ? 'plural' : 'single'
                    }`,
                    { num: listing.unit.num_sleep }
                  ).replace('+', '')}
                />
              )}
            </>
          )}
        </FlexBox>
      </FlexBox>
      {isMobile && <Spacer size="m" />}
    </>
  );
};

export default FeatureList;
