import React from 'react';
import { useSelector } from 'react-redux';
import ReactI18n from 'react-i18n';
import { Box } from '@directsoftware/ui-kit-web-admin';
import ShowMoreWrapper from './show-more-wrapper';
import ListingsDetailsContentBlock from './listings-details-content-block';

const RentalRules = () => {
  const listing = useSelector(state => state.listing);
  const translate = ReactI18n.getIntlMessage;

  return (
    <ListingsDetailsContentBlock
      label={translate(`cx.details.headers.accomodations`)}
      id="details-rules"
    >
      <ShowMoreWrapper>
        <Box className="customHtmlStyles">
            <div
              dangerouslySetInnerHTML={{
                __html: listing.property.summary_accommodations
              }}
            />
        </Box>
      </ShowMoreWrapper>
    </ListingsDetailsContentBlock>
  );
};

export default RentalRules;
