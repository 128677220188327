import React from 'react';
import { Box } from '@directsoftware/ui-kit-web-admin';
import PhotoGridItem from './photo-grid-item';

const PhotoGridPreviewLayout1 = ({ images, onClick }) => {
  return (
    <Box className="photoGrid__colLarge -full">
      <PhotoGridItem src={images[0].src} onClick={onClick} />
    </Box>
  );
};

export default PhotoGridPreviewLayout1;
