import React, { useEffect, useState } from 'react';
import { Footer } from '@directsoftware/wls-marketing-page-slices';

import { useSelector } from 'react-redux';
import HtmlContactForm from '../shared/html-contact-form';

const FooterV2 = props => {
  const brand = useSelector(state => state.brand);
  const [contactInfo, setContactInfo] = useState(null);
  const [socialLinks, setSocialLinks] = useState([]);
  const [showContactForm, setShowContactForm] = useState(false);

  const contactInfoCheck = () => {
    return brand.contact && brand.contact.email;
  };

  const setSendMessage = () => {
    let sendMessageObject = {
      headline: 'Send a Message',
      description:
        "We'd love to hear from you! Send us a message with any questions, comments, our thoughts and we'll reply to you ASAP.",
      ctaLabel: 'Contact Us',
      onSendMessage: () => setShowContactForm(true)
    };

    if (brand.brand_info.send_message) {
      if (brand.brand_info.send_message?.headline) {
        sendMessageObject = {
          ...sendMessageObject,
          headline: brand.brand_info.send_message.headline
        };
      }
      if (brand.brand_info.send_message?.description) {
        sendMessageObject = {
          ...sendMessageObject,
          description: brand.brand_info.send_message.description
        };
      }
      if (brand.brand_info.send_message?.cta_label) {
        sendMessageObject = {
          ...sendMessageObject,
          ctaLabel: brand.brand_info.send_message.cta_label
        };
      }
    }

    return sendMessageObject;
  };

  useEffect(() => {
    let contactObject = null;
    if (brand.contact) {
      if (brand.contact.email !== '')
        contactObject = {
          ...contactObject,
          emailAddress: brand.contact.email
        };
      if (brand.contact?.phone_primary?.number !== '')
        contactObject = {
          ...contactObject,
          phone: brand.contact.phone_primary.number
        };
      setContactInfo(contactObject);
    }
    if (brand.brand_info.social) {
      const socialLinkArray = [];
      for (const [key, value] of Object.entries(brand.brand_info.social)) {
        if (value) {
          const keyName = key.replace('social_', '');
          socialLinkArray.push({
            url: `https://${value}`,
            destination: keyName
          });
        }
      }
      setSocialLinks(socialLinkArray);
    }
  }, []);

  return (
    <>
      <Footer
        contactInfo={contactInfo || null}
        bookNow={{
          headline:
            brand.brand_info?.book_now?.headline ||
            'Not Just an Ordinary Place to Stay',
          description:
            brand.brand_info?.book_now?.description ||
            'We believe that having a home away from home is the epitome of a life of luxury. A life based on modern convenience, undisturbed space, and peace of mind.',
          ctaLabel:
            brand.brand_info?.book_now?.cta_label || 'Book Your Stay Now'
        }}
        sendMessage={contactInfoCheck() ? setSendMessage() : null}
        socialLinks={socialLinks}
        siteMapLink="/sitemap.xml"
        siteName={brand.name}
        logo={
          brand?.brand_info?.logo_image
            ? { url: brand?.brand_info?.logo_image }
            : null
        }
        logoWhite={
          brand?.logo_white_image ? { url: brand?.logo_white_image } : null
        }
        primaryColor={brand.brand_info.colors?.color_primary}
        primaryTextColor={brand.brand_info.colors?.color_primary_text}
        secondaryColor={brand.brand_info.colors?.color_secondary}
        secondaryTextColor={brand.brand_info.colors?.color_secondary_text}
      />
      {contactInfoCheck() && (
        <HtmlContactForm
          reveal={showContactForm}
          closeOnClick={() => setShowContactForm(false)}
        />
      )}
    </>
  );
};

FooterV2.propTypes = {};

export default FooterV2;
