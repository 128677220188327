import React from 'react';
import { useSelector } from 'react-redux';
import { selectUiState } from '../../redux/slices/ui';
import Verification from './index';
import VerificationV2 from './verification-v2';

const VerificationSwitch = (props) => {
  const ui = useSelector(selectUiState);

  return ui.isBookingEngine ? <VerificationV2 {...props} /> : <Verification {...props} />;
};

export default VerificationSwitch;
