import React from 'react';
import {
  Container,
  FlexBox,
  IconFontAwesome,
  Spacer
} from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import { useDetectMobile } from '../../../shared/hooks/useDetectMobile';

const FooterFinal = () => {
  const { isMobile, isTouch } = useDetectMobile();
  const brand = useSelector(state => state.brand);

  const renderCreditCards = () => {
    const cardArray = [];
    if (brand.footer?.credit_cards) {
      Object.keys(brand.footer?.credit_cards).forEach(key => {
        if (key === 'visa') cardArray.push(<IconFontAwesome name="ccVisa" />);
        if (key === 'mastercard')
          cardArray.push(<IconFontAwesome name="ccMastercard" />);
        if (key === 'discover')
          cardArray.push(<IconFontAwesome name="ccDiscover" />);
        if (key === 'american_express')
          cardArray.push(<IconFontAwesome name="ccAmex" />);
      });
    }
    return cardArray;
  };

  return (
    <Container variation="fluid-wls" className="footer-final">
      <FlexBox
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems="center"
        style={isMobile ? null : { height: 56 }}
        gap={isMobile ? 's' : null}
        paddingVertical={isMobile ? 'm' : null}
      >
        <FlexBox flex="1">
          {brand.brand_info.copyright
            ? brand.brand_info.copyright
            : '© 2022 Vacation Rental Partners Inc. All Rights Reserved.'}
        </FlexBox>
        <FlexBox justifyContent="center" flex="1">
          Built and powered by
          <a
            href="https://directsoftware.com/"
            target="_blank"
            rel="nofollow noreferrer"
            className="direct-link"
          >
            Direct
          </a>
        </FlexBox>
        <FlexBox
          flex="1"
          className="footer-final-icons"
          gap="xs"
          justifyContent="flex-end"
        >
          {renderCreditCards()}
        </FlexBox>
      </FlexBox>
      {isTouch && <Spacer size="xxl" />}
    </Container>
  );
};

export default FooterFinal;
