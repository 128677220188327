import React, { useEffect, useState } from 'react';
import {
  Box,
  FlexBoxGrid,
  IconFontAwesome,
  Modal
} from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import ListingsDetailsContentBlock from './listings-details-content-block';
import IconCardItem from './icon-card-item';
import CardButtonItem from './card-button-item';
import {
  amenities,
  outdoorAmenities,
  spaAmenities,
  diningAmenities,
  entertainmentAmenities
} from '../../../../shared/data';
import AmenitiesListGroup from './amenities-list-group';
import { useDetectMobile } from '../../../../shared/hooks/useDetectMobile';

const AmenitiesList = ({ unit }) => {
  const { isMobile } = useDetectMobile();
  const listing = useSelector(state => state.listing);
  const [openAmenityModal, setOpenAmenityModal] = useState(false);
  const [featuredAmenities, setFeaturedAmenities] = useState([]);

  useEffect(() => {
    const featured = [];
    let amenitiesCounter = 0;
    let diningAmenitiesCounter = 0;
    let spaAmenitiesCounter = 0;

    amenities.forEach(amenity => {
      if (amenitiesCounter < 3) {
        if (
          listing.unit.features_amenities[amenity.name] &&
          listing.unit.features_amenities[amenity.name].value
        ) {
          amenitiesCounter += 1;
          featured.push(amenity);
        }
      }
    });
    diningAmenities.forEach(amenity => {
      if (diningAmenitiesCounter < 3) {
        if (listing.unit.features_dining[amenity.name].value) {
          diningAmenitiesCounter += 1;
          featured.push(amenity);
        }
      }
    });
    outdoorAmenities.forEach(amenity => {
      if (diningAmenitiesCounter < 3) {
        if (listing.unit.features_outdoor[amenity.name].value) {
          diningAmenitiesCounter += 1;
          featured.push(amenity);
        }
      }
    });
    spaAmenities.forEach(amenity => {
      if (spaAmenitiesCounter < 3) {
        if (listing.unit.features_spa[amenity.name].value) {
          spaAmenitiesCounter += 1;
          featured.push(amenity);
        }
      }
    });
    entertainmentAmenities.forEach(amenity => {
      if (spaAmenitiesCounter < 3) {
        if (listing.unit.features_entertainment[amenity.name].value) {
          spaAmenitiesCounter += 1;
          featured.push(amenity);
        }
      }
    });
    setFeaturedAmenities(featured);
  }, []);

  return (
    <>
      <ListingsDetailsContentBlock label="Amenities">
        {listing.multi_unit || unit ? (
          <>
            {unit ? (
              <>
                <AmenitiesListGroup
                  features={unit.features_accommodations}
                  features_label="features_accommodations"
                />
                <AmenitiesListGroup
                  features={unit.features_amenities}
                  features_label="features_amenities"
                />
                <AmenitiesListGroup
                  features={unit.features_dining}
                  features_label="features_dining"
                />
                <AmenitiesListGroup
                  features={unit.features_entertainment}
                  features_label="features_entertainment"
                />
                <AmenitiesListGroup
                  features={unit.features_outdoor}
                  features_label="features_outdoor"
                />
                <AmenitiesListGroup
                  features={unit.features_spa}
                  features_label="features_spa"
                />
                <AmenitiesListGroup
                  features={unit.features_suitability}
                  features_label="features_suitability"
                />
                <AmenitiesListGroup
                  features={unit.features_themes}
                  features_label="features_themes"
                />
              </>
            ) : (
              <>
                <AmenitiesListGroup
                  features={listing.property.features_adventure}
                  features_label="features_adventure"
                />
                <AmenitiesListGroup
                  features={listing.property.features_attractions}
                  features_label="features_attractions"
                />
                <AmenitiesListGroup
                  features={listing.property.features_car}
                  features_label="features_car"
                />
                <AmenitiesListGroup
                  features={listing.property.features_leisure}
                  features_label="features_leisure"
                />
                <AmenitiesListGroup
                  features={listing.property.features_local}
                  features_label="features_local"
                />
                <AmenitiesListGroup
                  features={listing.property.features_location}
                  features_label="features_location"
                  removeBottomSpacer
                />
              </>
            )}
          </>
        ) : (
          <FlexBoxGrid columns={isMobile ? 2 : 4} gap="m" keepColumnsOnTouch>
            {featuredAmenities.map((amenity, idx) => (
              <IconCardItem
                key={`amenity-icon-card-${idx}`}
                iconNames={[
                  { name: amenity.iconName, useOldIcon: amenity.useOldIcon }
                ]}
                listItems={[amenity.label]}
                useOldIcon
              />
            ))}
            <CardButtonItem
              label="Show All"
              iconName="plus"
              onClick={() => setOpenAmenityModal(true)}
            />
          </FlexBoxGrid>
        )}
      </ListingsDetailsContentBlock>
      {!listing.multi_unit && (
        <Modal
          reveal={openAmenityModal}
          closeOnClick={() => setOpenAmenityModal(false)}
          title="Amenities"
          headerIcon={<IconFontAwesome name="grid" color="white" />}
          fullHeight
        >
          <Modal.Content contentIsScrollable>
            <Box padding="s">
              <AmenitiesListGroup
                features={listing.unit.features_accommodations}
                features_label="features_accommodations"
              />
              <AmenitiesListGroup
                features={listing.property.features_adventure}
                features_label="features_adventure"
              />
              <AmenitiesListGroup
                features={listing.unit.features_amenities}
                features_label="features_amenities"
              />
              <AmenitiesListGroup
                features={listing.property.features_attractions}
                features_label="features_attractions"
              />
              <AmenitiesListGroup
                features={listing.property.features_car}
                features_label="features_car"
              />
              <AmenitiesListGroup
                features={listing.unit.features_dining}
                features_label="features_dining"
              />
              <AmenitiesListGroup
                features={listing.unit.features_entertainment}
                features_label="features_entertainment"
              />
              <AmenitiesListGroup
                features={listing.property.features_leisure}
                features_label="features_leisure"
              />
              <AmenitiesListGroup
                features={listing.property.features_local}
                features_label="features_local"
              />
              <AmenitiesListGroup
                features={listing.property.features_location}
                features_label="features_location"
              />
              <AmenitiesListGroup
                features={listing.unit.features_outdoor}
                features_label="features_outdoor"
              />
              <AmenitiesListGroup
                features={listing.unit.features_spa}
                features_label="features_spa"
              />
              <AmenitiesListGroup
                features={listing.unit.features_suitability}
                features_label="features_suitability"
              />
              <AmenitiesListGroup
                features={listing.unit.features_themes}
                features_label="features_themes"
              />
            </Box>
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};

export default AmenitiesList;
