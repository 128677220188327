import React from 'react';
import { Box, Panel, TextDense } from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { selectUiState } from '../../../redux/slices/ui';
import { renderPrice } from '../../../../shared/helpers';

const MapMarker = ({ onClick, listingId, result }) => {
  const { highlightMapMarkerId } = useSelector(selectUiState);
  return (
    <Box renderAs="button" onClick={onClick} className="mapMarker">
      <Panel
        addShadow
        paddingHorizontal="xs"
        className={classnames('mapMarker__button', {
          highlight: highlightMapMarkerId === listingId
        })}
      >
        <TextDense
          weight="semibold"
          textColor="dark-gray"
          className={classnames('mapMarker__buttonText', {
            mapMarker__highlightText: highlightMapMarkerId === listingId
          })}
          isFullWidth
        >
          {renderPrice(result, true)}
        </TextDense>
      </Panel>
    </Box>
  );
};

export default MapMarker;
