import { connect } from 'react-redux';

import ListingSwitch from '../../components/listing/listing-switch';

function mapStateToProps(state, props) {
  return {
    listing: state.listings.length
      ? state.listings.find(id => (id = props.match.params.id))
      : {}
  };
}

export default connect(mapStateToProps)(ListingSwitch);
