import React from 'react';
import { Container, FlexBox } from '@directsoftware/ui-kit-web-admin';
import ListingsSearchDateRange from '../atoms/listings-search-date-range';
import { useDetectMobile } from '../../../../shared/hooks/useDetectMobile';
import ListingsSearchSort from '../atoms/listings-search-sort';
import ListingsSearchFilterAmenities from '../atoms/listings-search-filter-amenities';
import ListingsSearchFilterFeatures from '../atoms/listings-search-filter-features';

const ListingsSearchFilterBar = ({ updateSearchState, translate }) => {
  const { isMobile } = useDetectMobile();

  return (
    <>
      <Container variation="fluid-wls">
        <FlexBox alignItems="center" className="filter-bar" gap="xs">
          <ListingsSearchDateRange
            updateSearchState={updateSearchState}
            translate={translate}
          />
          <ListingsSearchFilterFeatures
            updateSearchState={updateSearchState}
            translate={translate}
          />
          <ListingsSearchFilterAmenities
            updateSearchState={updateSearchState}
          />
          {!isMobile && (
            <ListingsSearchSort
              translate={translate}
              updateSearchState={updateSearchState}
            />
          )}
        </FlexBox>
      </Container>
    </>
  );
};

export default ListingsSearchFilterBar;
