import React from 'react';
import {
  Box,
  FlexBox,
  IconFontAwesome,
  TextBody
} from '@directsoftware/ui-kit-web-admin';

const FeatureListItem = ({ iconName, label }) => {
  return (
    <FlexBox renderAs="li" alignItems="center" gap="xs">
      <Box className="list-item-icon-size">
        <IconFontAwesome name={iconName} iconStyle="regular" />
      </Box>
      <TextBody>{label}</TextBody>
    </FlexBox>
  );
};

export default FeatureListItem;
