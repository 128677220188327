import React from 'react';
import {
  Box,
  Divider,
  FlexBox,
  Spacer
} from '@directsoftware/ui-kit-web-admin';
import Skeleton from 'react-loading-skeleton';

const CheckoutLeftSkeleton = () => {
  return (
    <Box>
      <Skeleton height={21} width={100} />
      <Spacer size="m" />
      <Skeleton height={16} width="30%" />
      <Skeleton height={16} width={100} />
      <Divider padding="xs" />
      <Skeleton height={16} width="30%" />
      <Skeleton height={16} width={100} />
      <Spacer />
      <Divider />
      <Spacer size="l" />
      <Skeleton height={21} width={100} />
      <Spacer size="m" />
      <Skeleton height={16} width="30%" />
      <Skeleton height={16} width={100} />
      <Divider padding="xs" />
      <Skeleton height={16} width="30%" />
      <Skeleton height={16} width={100} />
      <Spacer />
      <Divider />
      <Spacer size="l" />
      <Skeleton height={21} width={100} />
      <Spacer size="m" />
      <Skeleton height={40} />
      <Spacer size="xs" />
      <Spacer size="xxs" />
      <FlexBox gap="s">
        <Box flex="1" style={{ height: 40 }}>
          <Skeleton height="100%" />
        </Box>
        <Box flex="1" style={{ height: 40 }}>
          <Skeleton height="100%" />
        </Box>
      </FlexBox>
      <Spacer size="l" />
      <Skeleton height={21} width={100} />
      <Spacer size="m" />
      <Skeleton height={40} />
      <Spacer size="xs" />
      <Spacer size="xxs" />
      <FlexBox gap="s">
        <Box flex="1" style={{ height: 40 }}>
          <Skeleton height="100%" />
        </Box>
        <Box flex="1" style={{ height: 40 }}>
          <Skeleton height="100%" />
        </Box>
      </FlexBox>
      <Spacer size="xs" />
      <Spacer size="xxs" />
      <FlexBox gap="s">
        <Box flex="1" style={{ height: 40 }}>
          <Skeleton height="100%" />
        </Box>
        <Box flex="1" style={{ height: 40 }}>
          <Skeleton height="100%" />
        </Box>
      </FlexBox>
      <Spacer size="l" />
      <Skeleton height={21} width={100} />
      <Spacer size="m" />
      <Skeleton height={40} />
      <Spacer size="xs" />
      <Spacer size="xxs" />
      <FlexBox gap="s">
        <Box flex="1" style={{ height: 40 }}>
          <Skeleton height="100%" />
        </Box>
        <Box flex="1" style={{ height: 40 }}>
          <Skeleton height="100%" />
        </Box>
      </FlexBox>
      <Spacer size="xs" />
      <Spacer size="xxs" />
      <FlexBox gap="s">
        <Box flex="1" style={{ height: 40 }}>
          <Skeleton height="100%" />
        </Box>
        <Box flex="1" style={{ height: 40 }}>
          <Skeleton height="100%" />
        </Box>
      </FlexBox>
      <Spacer size="xl" />
      <Skeleton height={40} />
    </Box>
  );
};

export default CheckoutLeftSkeleton;
