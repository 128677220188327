import React from 'react';
import {
  Panel,
  BottomSheet,
  FlexBox,
  Box,
  CallToActionButton,
  TextH2
} from '@directsoftware/ui-kit-web-admin';
import { useDispatch, useSelector } from 'react-redux';
import BookingDetailsBreakdown from '../single-v2/booking-details-breakdown';
import { selectUiState, updateUiSlice } from '../../../redux/slices/ui';
import { useDetectMobile } from '../../../../shared/hooks/useDetectMobile';
import ReviewsAverage from '../single-v2/reviews-average';
import PriceAverage from '../single-v2/price-average';
import HtmlContactForm from '../../shared/html-contact-form';

const BookingSidebar = ({ updateDetailsSingleState }) => {
  const dispatch = useDispatch();
  const { isMobile, isLargeDesktop, height } = useDetectMobile();
  const brand = useSelector(state => state.brand);
  const { openContactForm, openMobileBookingDetails } = useSelector(
    selectUiState
  );
  const listing = useSelector(state => state.listing);

  return (
    <>
      {isLargeDesktop ? (
        <Panel addShadow>
          <BookingDetailsBreakdown
            updateDetailsSingleState={updateDetailsSingleState}
          />
        </Panel>
      ) : (
        <>
          <FlexBox alignItems="center" justifyContent="space-between">
            <FlexBox gap="l">
              <PriceAverage />
              {!isMobile && listing.reviews.length > 0 && <ReviewsAverage />}
            </FlexBox>
            <CallToActionButton
              onClick={() =>
                dispatch(updateUiSlice({ openMobileBookingDetails: true }))
              }
              customButtonColor={brand.brand_info.colors?.color_primary}
              customTextColor={brand.brand_info.colors?.color_primary_text}
            >
              Book This Listing
            </CallToActionButton>
          </FlexBox>
          <Box className="detailsLayout__bottomSheet">
            <BottomSheet
              size="form"
              reveal={openMobileBookingDetails}
              header={<TextH2>Book this Listing</TextH2>}
              snapPoints={({ maxHeight }) => maxHeight - 56}
              onDismiss={() =>
                dispatch(updateUiSlice({ openMobileBookingDetails: false }))
              }
            >
              <Box style={{ minHeight: height - 120 }}>
                <BookingDetailsBreakdown
                  updateDetailsSingleState={updateDetailsSingleState}
                />
              </Box>
            </BottomSheet>
          </Box>
        </>
      )}
      <HtmlContactForm
        reveal={openContactForm}
        closeOnClick={() => dispatch(updateUiSlice({ openContactForm: false }))}
        listingName={listing.property.name}
      />
    </>
  );
};

export default BookingSidebar;
