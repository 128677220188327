import React, { useEffect, useState } from 'react';
import {
  Box,
  CallToActionButton,
  IconFontAwesome,
  Panel
} from '@directsoftware/ui-kit-web-admin';
import { useDispatch, useSelector } from 'react-redux';
import { selectUiState, updateUiSlice } from '../../../redux/slices/ui';
import PhotoGridPreviewLayout1 from './photo-grid-preview-layout-1';
import PhotoGridPreviewLayout3 from './photo-grid-preview-layout-3';
import PhotoGridPreviewLayout5 from './photo-grid-preview-layout-5';
import PhotoGallery from './photo-gallery';
import PhotoGridSkeleton from '../skeletons/photo-grid-skeleton';

const PhotoGrid = () => {
  const dispatch = useDispatch();
  const listing = useSelector(state => state.listing);
  const brand = useSelector(state => state.brand);
  const { showDetailsThumbnailGrid } = useSelector(selectUiState);
  const [images, setImages] = useState(null);
  const [previewGridImages, setPreviewGridImages] = useState([]);

  const setupImages = () => {
    const images = [];
    for (let i = 0; i < listing.property_images.length; i++) {
      const image = listing.property_images[i];
      const image_obj = {};
      image_obj.src = image.url.original;
      image_obj.src_xl = image.url.large;
      image_obj.thumbnail = image.url.tiny;
      image_obj.caption = image.label;
      images.push(image_obj);
    }
    return images;
  };

  const manageThumbnails = open => {
    dispatch(updateUiSlice({ showDetailsThumbnailGrid: open }));
  };

  useEffect(
    () => {
      if (images) setPreviewGridImages(images.slice(0, 5));
    },
    [images]
  );

  useEffect(() => {
    setImages(setupImages());
  }, []);

  return images ? (
    <>
      <Box className="photoGrid__wrapper" setPositionRelative>
        <Box className="photoGrid__wrapper_inside" setPositionRelative>
          <Box className="photoGrid__wrapper_row">
            {previewGridImages && previewGridImages.length > 0 ? (
              <>
                {previewGridImages.length < 3 && (
                  <PhotoGridPreviewLayout1
                    images={previewGridImages}
                    onClick={() => manageThumbnails(true)}
                  />
                )}
                {(previewGridImages.length === 3 ||
                  previewGridImages.length === 4) && (
                  <PhotoGridPreviewLayout3
                    images={previewGridImages}
                    onClick={() => manageThumbnails(true)}
                  />
                )}
                {previewGridImages.length === 5 && (
                  <PhotoGridPreviewLayout5
                    images={previewGridImages}
                    onClick={() => manageThumbnails(true)}
                  />
                )}
              </>
            ) : (
              <Box>
                <IconFontAwesome name="image" />
              </Box>
            )}
          </Box>
        </Box>
        {previewGridImages.length > 1 && (
          <Panel className="photoGrid__viewImages" addShadow>
            <CallToActionButton
              onClick={() => manageThumbnails(true)}
              appearance="ghost"
              prependIcon={<IconFontAwesome name="image" />}
              customButtonColor={brand.brand_info.colors?.color_primary}
              customTextColor={brand.brand_info.colors?.color_primary_text}
            >
              View Images
            </CallToActionButton>
          </Panel>
        )}
      </Box>
      <PhotoGallery
        images={images}
        reveal={showDetailsThumbnailGrid}
        title={listing.room_type_name || listing.property.name}
        onClose={() => manageThumbnails(false)}
      />
    </>
  ) : (
    <PhotoGridSkeleton />
  );
};

export default PhotoGrid;
