import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactI18n from 'react-i18n';
import {
  Box,
  Divider,
  FlexBox,
  Spacer,
  TextH1
} from '@directsoftware/ui-kit-web-admin';
import { selectListingsDetailsMultiState } from '../../../redux/slices/listingsDetailsMulti';
import FeatureListItem from '../shared-v2/feature-list-item';
import Description from '../shared-v2/description';
import BathroomsList from '../shared-v2/bathrooms-list';
import BedroomsList from '../shared-v2/bedrooms-list';
import ListingsDetailsContentBlock from '../shared-v2/listings-details-content-block';
import MultiUnitAvailability from '../multi/multi-unit-availability';
import AmenitiesList from '../shared-v2/amenities-list';

const ChildUnitModalContent = () => {
  const { unitDataForModal: unitData } = useSelector(
    selectListingsDetailsMultiState
  );
  const [unit, setUnit] = useState(null);
  const [unitDescription, setUnitDescription] = useState('');
  const translate = ReactI18n.getIntlMessage;

  useEffect(
    () => {
      if (unitData) {
        setUnit(unitData.unit);
        setUnitDescription(
          unitData.unit.summary_description ||
            unitData.unit.summary_description_plain_text ||
            ''
        );
      } else {
        setUnit(null);
        setUnitDescription('');
      }
    },
    [unitData]
  );

  return !unitData || !unit ? null : (
    <Box padding="s">
      <TextH1>{unit.name}</TextH1>
      <FlexBox flexDirection="row" alignItems="center" gap="m" renderAs="ul">
        <FeatureListItem
          iconName="bed"
          label={`${unit.num_bedrooms} ${translate(
            'cx.global.amenities.beds'
          )}`}
        />
        <FeatureListItem
          iconName="shower"
          label={`${unit.num_bathrooms} ${translate(
            'cx.global.amenities.baths'
          )}`}
        />
        <FeatureListItem
          iconName="users"
          label={`${translate('cx.global.amenities.sleeps')} ${
            unit.num_sleep_in_beds
          }`}
        />
      </FlexBox>
      <Spacer />
      <Divider />
      <Spacer />
      {unitDescription !== '' && <Description description={unitDescription} />}
      <BathroomsList />
      <BedroomsList />
      <AmenitiesList unit={unit} />
      <ListingsDetailsContentBlock
        label={translate(`cx.details.headers.availability`)}
      >
        <MultiUnitAvailability
          availability_calendar={unitData.availability_calendar}
          default_availability={unitData.default_availability_changeover}
          booking_calendar={unitData.booking_calendar}
          useDesignKit
        />
      </ListingsDetailsContentBlock>
    </Box>
  );
};

export default ChildUnitModalContent;
