import React from 'react';

const PhotoGridItem = ({ src, onClick }) => {
  return (
    <button onClick={onClick} className="photoGrid__item">
      <img src={src} alt="" />
    </button>
  );
};

export default PhotoGridItem;
