import React from 'react';
import { useSelector } from 'react-redux';
import Header from './header';
import { selectUiState } from '../../redux/slices/ui';
import HeaderV2 from './header-v2';
import HeaderBookingEngine from './header-booking-engine';

const HeaderSwitch = () => {
  const ui = useSelector(selectUiState);

  const renderHeader = () => {
    if (ui.cmsVersion === 'v2') {
      if (ui.isBookingEngine) return <HeaderBookingEngine />;
      return <HeaderV2 />;
    }
    return <Header />;
  };

  return renderHeader();
};

export default HeaderSwitch;
