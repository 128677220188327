// Dependencies
// -----------------------------------------------
import React from 'react';
import ReactI18n from 'react-i18n';
import { InputText } from '@directsoftware/ui-kit-web-admin';

// -----------------------------------------------
// COMPONENT->COUPON-MODAL -----------------------
// -----------------------------------------------
export default class CouponModal extends React.PureComponent {
  // Render
  // ---------------------------------------------
  render() {
    const translate = ReactI18n.getIntlMessage;

    return this.props.useDesignKit ? (
      <InputText
        onChange={e => this.props.updateCouponCode(e.target.value)}
        value={this.props.couponCode}
        name="coupon_code"
        placeholder="Coupon Code"
        isError={this.props.badCode}
        description={
          this.props.badCode ? translate(`cx.details.bad_code`) : null
        }
      />
    ) : (
      <>
        <input
          name="coupon_code"
          value={this.props.couponCode}
          onChange={e => this.props.updateCouponCode(e.target.value)}
          placeholder="Coupon Code"
          style={this.props.badCode ? { border: '2px solid red' } : {}}
        />
        {this.props.badCode && (
          <p style={{ color: 'red', marginRight: '50%' }}>
            {translate(`cx.details.bad_code`)}
          </p>
        )}
      </>
    );
  }
}
