import React from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Divider,
  IconFontAwesome,
  PopoverForm,
  Spacer
} from '@directsoftware/ui-kit-web-admin';
import ListingsSearchNumGuests from './listings-search-num-guests';
import ListingsSearchNumBeds from './listings-search-num-beds';
import ListingsSearchNumBaths from './listings-search-num-baths';
import ListingsSearchPrice from './listings-search-price';
import ListingsSearchDistance from './listings-search-distance';
import { useDetectMobile } from '../../../../shared/hooks/useDetectMobile';

const ListingsSearchFilterFeatures = ({ updateSearchState, translate }) => {
  const { isMobile } = useDetectMobile();
  const brand = useSelector(state => state.brand);

  return (
    <Box>
      <PopoverForm
        triggerLabel={
          isMobile ? (
            <IconFontAwesome name="barsFilter" iconStyle="regular" />
          ) : (
            'Filters'
          )
        }
        triggerProps={{
          prependIcon: isMobile ? null : (
            <IconFontAwesome name="barsFilter" iconStyle="regular" />
          ),
          size: 'dense',
          variation: 'secondary',
          appearance: 'outline'
        }}
        triggerType={isMobile ? 'icon-button' : 'call-to-action-button'}
        dropdownWidth="m"
        hideActionBar
      >
        <Box paddingHorizontal="xs" paddingTop="xs">
          <ListingsSearchNumGuests
            updateSearchState={updateSearchState}
            translate={translate}
          />
          <Spacer size="s" />
          <ListingsSearchNumBeds
            updateSearchState={updateSearchState}
            translate={translate}
          />
          <Spacer size="s" />
          {!brand.isRvFleet &&
            <ListingsSearchNumBaths
              updateSearchState={updateSearchState}
              translate={translate}
            />}
          <Divider padding="s" />
          <ListingsSearchPrice updateSearchState={updateSearchState} />
          <ListingsSearchDistance
            updateSearchState={updateSearchState}
            translate={translate}
          />
        </Box>
      </PopoverForm>
    </Box>
  );
};

export default ListingsSearchFilterFeatures;
