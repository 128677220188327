import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AmenitiesListGroup from '../shared-v2/amenities-list-group';
import vehicleAmenitiesList from '../../listings/resources/vehicle_amenities_list.json';

const AmenitiesList = ({ removeBottomSpacer }) => {
  const listing = useSelector(state => state.listing);
  const [amenities, setAmenities] = useState([]);

  const setFilteredAmenities = () => {
    const amenitiesWithValue = {};
    Object.entries(vehicleAmenitiesList).map(pair => {
      const valueWithValue = pair[1][0];
      valueWithValue.value = listing.vehicle[valueWithValue.column];
      amenitiesWithValue[pair[0]] = valueWithValue;
    });
    setAmenities(amenitiesWithValue);
  };

  useEffect(() => {
    setFilteredAmenities();
  }, []);

  return (
    <AmenitiesListGroup
      features={amenities}
      features_label="vehicle_amenities"
      removeBottomSpacer={removeBottomSpacer}
    />
  );
};

export default AmenitiesList;
