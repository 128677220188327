import React from 'react';
import {
  Box,
  FlexBox,
  IconFontAwesome,
  TextDense,
  TextH2
} from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import { getAverage } from '../../../../shared/helpers';

const ReviewsAverage = ({ rightAlign }) => {
  const listing = useSelector(state => state.listing);
  const brand = useSelector(state => state.brand);

  return (
    <Box>
      <FlexBox
        justifyContent={rightAlign ? 'flex-end' : 'flex-start'}
        alignItems="center"
        gap="xs"
      >
        <IconFontAwesome
          name="star"
          color={brand.brand_info.colors?.color_decorative_icon}
        />
        <TextH2 textColor="dark-gray" className="reset-line-height">
          {getAverage(listing)}
        </TextH2>
      </FlexBox>
      <TextDense>{`${listing.reviews.length} review(s)`}</TextDense>
    </Box>
  );
};

export default ReviewsAverage;
