import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import {
  Box,
  ChannelLogo,
  FlexBox,
  IconButton,
  IconFontAwesome,
  Panel,
  StandByOverlay
} from '@directsoftware/ui-kit-web-admin';
import SliceMachine from '../shared/slice-machine';
import { useDetectMobile } from '../../../shared/hooks/useDetectMobile';
import Meta from './meta';

const PageV2 = props => {
  const brand = useSelector(state => state.brand);
  const { isMobile } = useDetectMobile();
  const [pageLoading, setPageLoading] = useState(true);
  const [pageSlices, setPageSlices] = useState([]);
  const [pageInfo, setPageInfo] = useState(null);
  const [showBanner, setShowBanner] = useState(false);
  const setBrand = () => {
    axios
      .get(`/api/pages/${props.match.params.page_slug}?brand=${brand.id}`)
      .then(async response => {
        setPageSlices(response.data.slices);
        setPageInfo(response.data.page);
        setPageLoading(false);
        window.customJavascriptLoad();
      });
  };

  useEffect(
    () => {
      setBrand();
    },
    [props]
  );

  useEffect(
    () => {
      if (
        brand &&
        (brand.id === 200000000575 ||
          brand.id === 200000000572 ||
          brand.id === 200000000576)
      ) {
        setTimeout(() => {
          setShowBanner(true);
        }, 5000);
      }
    },
    [brand]
  );

  if (pageLoading) return <StandByOverlay position="fixed" />;

  return pageSlices.length > 0 ? (
    <>
      <Meta page={pageInfo} />
      {pageSlices.map((slice, idx) => (
        <SliceMachine
          key={`slice-${idx}`}
          slice={slice}
          theme={brand.v2Theme}
        />
      ))}
      {showBanner && (
        <Panel
          addShadow
          className="demoBadge"
          paddingVertical={isMobile ? 's' : 'xs'}
        >
          <FlexBox
            gap="s"
            alignItems={isMobile ? 'flex-start' : 'center'}
            paddingHorizontal="s"
          >
            {!isMobile && (
              <Box>
                <ChannelLogo
                  channel="direct"
                  variation="full"
                  presetHeight="m"
                />
              </Box>
            )}
            <Box flex="1">
              {isMobile && (
                <Box paddingBottom="s">
                  <ChannelLogo
                    channel="direct"
                    variation="full"
                    presetHeight="m"
                  />
                </Box>
              )}
              {`${brand.name} is a demo site for `}
              <a
                href="https://directsoftware.com"
                target="_blank"
                style={{ color: brand.brand_info.colors?.color_primary }}
                rel="noreferrer"
              >
                Direct
              </a>
              {
                ' that you can interact with. It’s not a real rental site, but you can '
              }
              <a
                href="https://directsoftware.com/products/direct-booking-websites"
                target="_blank"
                style={{ color: brand.brand_info.colors?.color_primary }}
                rel="noreferrer"
              >
                easily create one like it
              </a>
              {` for your company.`}
            </Box>
            <IconButton
              onClick={() => {
                setShowBanner(false);
              }}
              size="dense"
              variation="secondary"
              appearance="ghost"
            >
              <IconFontAwesome name="times" />
            </IconButton>
          </FlexBox>
        </Panel>
      )}
    </>
  ) : null;
};

PageV2.propTypes = {};

export default PageV2;
