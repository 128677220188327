import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import ReactI18n from 'react-i18n';
import { reject, isNull } from 'lodash';
import {
  Box,
  CallToActionButton,
  CallToActionLink,
  DisplayLineItem,
  Divider,
  FlexBox,
  IconFontAwesome,
  Modal,
  Spacer,
  TextH2,
  Tooltip
} from '@directsoftware/ui-kit-web-admin';
import CouponModal from '../single/coupon-modal';
import { formatCurrency } from '../../../../shared/helpers';
import BookingErrorsV2 from './booking-errors-v2';

class BookingBreakdownV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numFees: 1,
      couponCode: null,
      allCouponCodes: null,
      badCode: false,
      listing: null,
      roomType: false,
      openCouponModal: false
    };
  }

  componentDidMount = () => {
    this.findListingAndRoomType();
    this.fetchCouponCodes();
  };

  buildGoToCheckoutUrl = () => {
    const checkInDateFormatted = this.props.checkInDate.format('DD-MM-YYYY');
    const checkOutDateFormatted = this.props.checkOutDate.format('DD-MM-YYYY');
    const addonFeeIds = this.props.addonFeeIds
      ? this.props.addonFeeIds.join(',')
      : [];
    const listingId = this.state.roomType
      ? this.state.listing.id
      : this.props.listing.id;
    const goToCheckoutUrl = `${'/checkout/' +
      `${this.props.listing.slug
      }/`}${listingId}?check-in=${checkInDateFormatted}&check-out=${checkOutDateFormatted}&guests=${this.props.guests
      }&addonFeeIds=${addonFeeIds}&couponCode=${this.state.couponCode}${(this.props.customLocation && `&customLocation=true`) || (this.props.deliveryLocation ? `&deliveryLocation=${this.props.deliveryLocation}`: '')}`;
    return goToCheckoutUrl;
  };

  findListingAndRoomType = () => {
    const brandId = this.props.listing.brand_id;
    const unitId = this.props.unitId;

    if (unitId === undefined) {
      this.setState({ roomType: false });
    } else {
      axios
        .get(
          `${process.env.DIRECT_URL
          }/api/v2/listings/single/find_listing/${brandId}/${unitId}`,
          {
            headers: { 'Content-Type': 'application/json' },
            context: this
          }
        )
        .then(response => {
          this.setState({
            listing: response.data,
            roomType: true
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  renderDescriptionPopover(description) {
    return (
      <figure className="line-item-description">
        <i />
        <span>{description}</span>
      </figure>
    );
  }

  renderDiscount(discount) {
    const translate = ReactI18n.getIntlMessage;
    const currency = this.props.listing.currency;

    return (
      <DisplayLineItem
        collapseOnMobile={false}
        removeBottomBorder
        label={translate(`global.parsers.discount.${discount[0]}`, {
          value: discount[1]
        })}
        value={` - ${formatCurrency(discount[2], currency)}`}
        padding="xxs"
        labelIsRegularWeight
      />
    );
  }

  renderPricing() {
    const translate = ReactI18n.getIntlMessage;
    const currency = this.props.listing.currency;
    const mandatoryFees = reject(
      this.props.pricing.fees,
      fee => fee.is_addon === 'true' || fee.value === 0
    );
    const avg_nightly_price_style =
      this.props.pricing.average_nightly_promotional_price <
      this.props.pricing.average_nightly_price;

    return (
      <>
        <Box className="screenreader">
          <Box>{translate(`cx.global.label`)}</Box>
          <Box>{translate(`cx.global.price`)}</Box>
        </Box>
        <Box padding="s">
          <DisplayLineItem
            removeBottomBorder
            collapseOnMobile={false}
            labelClassName={
              avg_nightly_price_style ? 'crossed-out-red-text' : null
            }
            label={`${translate(`global.parsers.currency_avg.${currency}`, {
              value: Math.round(this.props.pricing.average_nightly_price)
            })} x ${translate(
              `global.parsers.num_nights.${this.props.availability.length_of_stay > 1 ? 'plural' : 'single'
              }`,
              { nights: this.props.availability.length_of_stay }
            )}`}
            valueClassName={
              avg_nightly_price_style ? 'crossed-out-red-text' : null
            }
            value={formatCurrency(
              this.props.pricing.subtotal.toFixed(2),
              currency
            )}
            padding="xxs"
            labelIsRegularWeight
          />
          {this.props.pricing.promotional_subtotal <
            this.props.pricing.subtotal && (
              <DisplayLineItem
                removeBottomBorder
                collapseOnMobile={false}
                label={`${translate(`global.parsers.currency_avg.${currency}`, {
                  value: Math.round(
                    this.props.pricing.average_nightly_promotional_price
                  )
                })} x ${translate(
                  `global.parsers.num_nights.${this.props.availability.length_of_stay > 1
                    ? 'plural'
                    : 'single'
                  }`,
                  { nights: this.props.availability.length_of_stay }
                )}`}
                value={formatCurrency(
                  this.props.pricing.promotional_subtotal.toFixed(2),
                  currency
                )}
                padding="xxs"
                labelIsRegularWeight
              />
            )}
          {this.props.pricing.los_discount[0] &&
            this.props.pricing.los_discount[1] > 0
            ? this.renderDiscount(this.props.pricing.los_discount)
            : null}
          {mandatoryFees &&
            mandatoryFees.map((fee, index) => (
              <DisplayLineItem
                collapseOnMobile={false}
                key={`fee-${index}`}
                removeBottomBorder
                label={
                  fee.description ? (
                    <Tooltip tipContent={fee.description}>
                      {`${fee.name} `}
                      <IconFontAwesome name="questionCircle" />
                    </Tooltip>
                  ) : (
                    fee.name
                  )
                }
                value={formatCurrency(
                  parseFloat(fee.value).toFixed(2),
                  currency
                )}
                padding="xxs"
                labelIsRegularWeight
              />
            ))}
          <DisplayLineItem
            removeBottomBorder
            collapseOnMobile={false}
            label={translate(`cx.global.taxes`)}
            value={formatCurrency(
              this.props.pricing.taxes.toFixed(2),
              currency
            )}
            padding="xxs"
            labelIsRegularWeight
          />
          {this.props.pricing.gameday_pricing > 0 && (
            <DisplayLineItem
              removeBottomBorder
              collapseOnMobile={false}
              label="Processing Fee"
              value={formatCurrency(
                this.props.pricing.gameday_pricing.toFixed(2),
                currency
              )}
              padding="xxs"
              labelIsRegularWeight
            />
          )}
        </Box>
        <Divider />
        <Box padding="s">
          <FlexBox justifyContent="space-between" alignItems="center">
            <TextH2>{translate(`cx.global.total`)}</TextH2>
            <TextH2>
              {formatCurrency(
                this.props.pricing.promotional_total < this.props.pricing.total
                  ? this.props.pricing.promotional_total.toFixed(2)
                  : this.props.pricing.total.toFixed(2),
                currency
              )}
            </TextH2>
          </FlexBox>
        </Box>
      </>
    );
  }

  fetchCouponCodes = () => {
    axios
      .get(
        `${process.env.DIRECT_URL}/api/v2/fetch_coupon_codes/${this.props.listing.id
        }`,
        {
          headers: { 'Content-Type': 'application/json' },
          context: this
        }
      )
      .then(response => {
        this.setState({ allCouponCodes: response.data });
      })
      .catch(error => {
        console.log(error);
      });
  };

  updateCouponCode = couponCode => {
    this.setState({ couponCode, badCode: false });
  };

  verifyCouponCode = () => {
    if (isNull(this.state.allCouponCodes)) {
      this.setState({ badCode: true });
    } else if (
      this.state.allCouponCodes.includes(this.state.couponCode.toLowerCase())
    ) {
      this.props.addCouponCode(this.state.couponCode);
      this.setState({ openCouponModal: false });
    } else {
      this.setState({ badCode: true });
    }
  };

  render() {
    const translate = ReactI18n.getIntlMessage;
    const currency = this.props.currency;
    const notVehicle = !this.props.listing?.vehicle
    return (
      <>
        {this.props.availability &&
          this.props.availability.bookable &&
          this.props.pricing ? (
          <>
            {this.renderPricing()}
            <Box paddingHorizontal="s">
              <CallToActionButton
                onClick={() => this.setState({ openCouponModal: true })}
                appearance="ghost"
                isFullWidth
                prependIcon={<IconFontAwesome name="plus" />}
                customButtonColor={
                  this.props.brand.brand_info.colors?.color_primary
                }
                customTextColor={
                  this.props.brand.brand_info.colors?.color_primary_text
                }
              >
                {translate(`cx.details.apply_coupon`)}
              </CallToActionButton>
              <Spacer size="xs" />
              <CallToActionLink
                href={this.buildGoToCheckoutUrl()}
                isFullWidth
                size="large"
                customButtonColor={
                  this.props.brand.brand_info.colors?.color_primary
                }
                customTextColor={
                  this.props.brand.brand_info.colors?.color_primary_text
                }
              >
                {this.props.availability.instant_booking
                  ? translate(`cx.global.book.long`)
                  : translate(`cx.details.no_charge`)}
              </CallToActionLink>
            </Box>
          </>
        ) : (
          <BookingErrorsV2
            availability={this.props.availability}
            checkInDate={this.props.checkInDate}
            checkOutDate={this.props.checkOutDate}
          />
        )}
        <Modal
          reveal={this.state.openCouponModal}
          title="Apply Coupon"
          closeOnClick={() => {
            this.setState({ openCouponModal: false });
          }}
          size="form"
        >
          <Modal.Content contentIsScrollable>
            <Box padding="s">
              <CouponModal
                badCode={this.state.badCode}
                updateCouponCode={this.updateCouponCode}
                useDesignKit
              />
            </Box>
          </Modal.Content>
          <Modal.Footer
            primaryOnClick={() => this.verifyCouponCode()}
            primaryLabel="Apply"
            primaryIsDisabled={
              !this.state.couponCode || this.state.couponCode === ''
            }
            secondaryOnClick={() => {
              this.setState({ openCouponModal: false });
            }}
            secondaryLabel="Cancel"
          />
        </Modal>
      </>
    );
  }
}

// Map State To Props
// -----------------------------------------------
function mapStateToProps(state) {
  return {
    listing: state.listing ? state.listing : {},
    brand: state.brand
  };
}

// Export
// -----------------------------------------------
export default connect(mapStateToProps)(BookingBreakdownV2);
