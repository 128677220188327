import React from 'react';
import { useSelector } from 'react-redux';
import { FlexBoxGrid, Label, Box } from '@directsoftware/ui-kit-web-admin';
import ListingsDetailsContentBlock from '../shared-v2/listings-details-content-block';
import AmenitiesList from './amenities-list';

const Details = () => {
  const vehicle = useSelector(state => state.listing.vehicle);
  const types = [
    'Class A Motorhome',
    'Class B Camping Van',
    'Class C Motorhome',
    'Fifth Wheel',
    'Pop Up Camper',
    'Toy Hauler',
    'Travel Trailer'
  ];

  const renderDetails = () => {
    const details = [];
    details.push({
      label: 'Weight',
      values: [
        `Cargo ${vehicle.cargo_weight} lbs`,
        `Dry ${vehicle.dry_weight} lbs`,
        `Gross ${vehicle.gross_weight} lbs`
      ]
    });
    if (vehicle.vehicle_type < 3) {
      details.push({
        label: 'Cruise Control',
        values: [vehicle.cruise_control ? 'Yes' : 'No']
      });
      details.push({
        label: 'Fuel Capacity',
        values: [`${vehicle.fuel_capacity} gallons`]
      });
      details.push({
        label: 'Fuel Consumption',
        values: [`${vehicle.fuel_consumption} miles per gallons`]
      });
      details.push({ label: 'Fuel Type', values: [vehicle.fuel_type] });
      details.push({ label: 'Transmission', values: [vehicle.transmission] });
      details.push({
        label: 'Number of Seatbelts',
        values: [vehicle.seat_belts]
      });
      details.push({ label: 'Engine', values: [vehicle.engine] });
      details.push({
        label: 'Power Steering',
        values: [vehicle.power_steering ? 'Yes' : 'No']
      });
    } else {
      details.push({
        label: 'Hitch Provided',
        values: [vehicle.hitch_provided ? 'Yes' : 'No']
      });
      if (vehicle.hitch_provided) {
        details.push({ label: 'Hitch Weight', values: [vehicle.hitch_weight] });
        details.push({
          label: 'Weight Distributing Hitch',
          values: [vehicle.hitch_weight]
        });
      }
      details.push({
        label: 'Trailer Connector Adapter Provided',
        values: [vehicle.trailer_connector_adapter_provided ? 'Yes' : 'No']
      });
      if (vehicle.hitch_provided) {
        details.push({
          label: 'Trailer Ball Details',
          values: [
            `Trailer Connector Type ${vehicle.trailer_connector_type}`,
            `Trailer Ball Size ${vehicle.trailer_ball_size}"`
          ]
        });
      }
      if (vehicle.dual_battery) {
        details.push({ label: '', values: ['Yes'] });
      }
    }
    if (vehicle.electric_service) {
      details.push({
        label: 'Electric Service',
        values: [`${vehicle.electric_service} amps`]
      });
    }
    return details;
  };

  return (
    <ListingsDetailsContentBlock label="Vehicle Details">
      <FlexBoxGrid columns={2} gap="m">
        <Box>
          <Label>Make & Model</Label>
          <Box>{`${vehicle.year} ${vehicle.make} ${vehicle.model}`}</Box>
        </Box>
        <Box>
          <Label>Type</Label>
          <Box>{types[vehicle.vehicle_type - 1]}</Box>
        </Box>
        <Box>
          <Label>Capacity</Label>
          <Box>{vehicle.num_sleep_in_beds}</Box>
        </Box>
        <Box>
          <Label>Slides</Label>
          <Box>{vehicle.slides}</Box>
        </Box>
        <Box>
          <Label>Size</Label>
          <Box>{`Height ${vehicle.height} ft`}</Box>
          <Box>{`Length ${vehicle.length} ft`}</Box>
        </Box>
        {renderDetails().map(e => (
          <Box>
            <Label>{e.label}</Label>
            {e.values.map(v => (
              <Box>{v}</Box>
            ))}
          </Box>
        ))}
      </FlexBoxGrid>
      <AmenitiesList removeBottomSpacer />
    </ListingsDetailsContentBlock>
  );
};

export default Details;
