import React, { useEffect, useState } from 'react';
import {
  Box,
  CallToActionButton,
  CallToActionLink,
  Divider,
  FlexBox,
  IconFontAwesome,
  Panel,
  Spacer,
  TextCaption,
  TextDense,
  TextH2
} from '@directsoftware/ui-kit-web-admin';
import ReactI18n from 'react-i18n';
import { useDispatch, useSelector } from 'react-redux';
import FeatureListItem from '../shared-v2/feature-list-item';
import PhotoGallery from '../shared-v2/photo-gallery';
import {
  selectListingsDetailsMultiState,
  updateListingsDetailsMultiSlice
} from '../../../redux/slices/listingsDetailsMulti';
import { useDetectMobile } from '../../../../shared/hooks/useDetectMobile';
import { updateUiSlice } from '../../../redux/slices/ui';

const UnitTypeItem = ({ unitData, pricing, availability }) => {
  const { isMobile } = useDetectMobile();
  const dispatch = useDispatch();
  const listingsDetailsState = useSelector(selectListingsDetailsMultiState);
  const [openPhotoGallery, setOpenPhotoGallery] = useState(false);
  const [unitImages, setUnitImages] = useState([]);
  const translate = ReactI18n.getIntlMessage;
  const unit = unitData.unit;
  const listing = unitData.listing;
  const average_default_nightly_price = parseFloat(
    unitData.average_default_nightly_price
  ).toFixed(0);

  const buildGoToCheckoutUrl = () => {
    const checkInDateFormatted = listingsDetailsState.checkIn
      ? listingsDetailsState.checkIn.format('DD-MM-YYYY')
      : '';
    const checkOutDateFormatted = listingsDetailsState.checkOut
      ? listingsDetailsState.checkOut.format('DD-MM-YYYY')
      : '';
    const addonFeeIds = listingsDetailsState.addonFeeIds
      ? listingsDetailsState.addonFeeIds.join(',')
      : [];
    const goToCheckoutUrl =
      `/checkout/${unitData.listing.id}` +
      `?check-in=${checkInDateFormatted}` +
      `&check-out=${checkOutDateFormatted}` +
      `&guests=${listingsDetailsState.guests}` +
      `&addonFeeIds=${addonFeeIds}`;
    return goToCheckoutUrl;
  };

  const setupImages = imagesArray => {
    const images = [];
    for (let i = 0; i < imagesArray.length; i++) {
      const image = imagesArray[i];
      const image_obj = {};
      image_obj.src = image.url.original;
      image_obj.src_xl = image.url.large;
      image_obj.thumbnail = image.url.tiny;
      image_obj.caption = image.label;
      images.push(image_obj);
    }
    return images;
  };

  useEffect(() => {
    setUnitImages(setupImages(unitData.images));
  }, []);

  return (
    <>
      <Panel addShadow className="temp-unit-item">
        <FlexBox flexDirection="column" style={{ height: '100%' }}>
          <Box padding="s" flex="1">
            {unitImages.length > 0 ? (
              <Box
                renderAs="button"
                onClick={
                  unitImages.length === 0
                    ? null
                    : () => setOpenPhotoGallery(true)
                }
                setPositionRelative
                className="photoItem -isButton"
              >
                <img src={unitImages[0].src} alt="" />
              </Box>
            ) : (
              <Box setPositionRelative className="photoItem">
                <Box className="photoItem__icon">
                  <IconFontAwesome name="image" />
                </Box>
              </Box>
            )}
            <Spacer />
            <Box isTextTruncateWrapper>
              <TextH2 textColor="dark-gray" truncate={!isMobile}>
                {unit.name}
              </TextH2>
            </Box>
            <FlexBox
              flexDirection="row"
              alignItems="center"
              gap="m"
              renderAs="ul"
            >
              <FeatureListItem
                iconName="bed"
                label={`${unit.num_bedrooms} ${translate(
                  'cx.global.amenities.beds'
                )}`}
              />
              <FeatureListItem
                iconName="shower"
                label={`${unit.num_bathrooms} ${translate(
                  'cx.global.amenities.baths'
                )}`}
              />
              <FeatureListItem
                iconName="users"
                label={`${translate('cx.global.amenities.sleeps')} ${
                  unit.num_sleep_in_beds
                }`}
              />
            </FlexBox>
            <Spacer />
            <CallToActionButton
              onClick={() => {
                dispatch(updateUiSlice({ openChildUnitModal: true }));
                dispatch(
                  updateListingsDetailsMultiSlice({
                    unitDataForModal: unitData
                  })
                );
              }}
              size="dense"
              customButtonColor="#F1F1F4"
              customTextColor="#505775"
            >
              More info
            </CallToActionButton>
          </Box>
          <Divider />
          <FlexBox paddingHorizontal="s" paddingTop="s" paddingBottom="xs">
            <Box flex="1">
              <TextH2
                textColor="dark-gray"
                isFullWidth
                className="reset-line-height"
              >
                {pricing && pricing.total
                  ? translate(`global.parsers.currency.${listing.currency}`, {
                      value: pricing.average_nightly_price
                    })
                  : translate(`global.parsers.currency.${listing.currency}`, {
                      value: parseFloat(average_default_nightly_price).toFixed(
                        0
                      )
                    })}
              </TextH2>
              <TextDense>
                {translate('cx.global.pricing.avg_per_night')}
              </TextDense>
            </Box>
            <FlexBox flex="1" flexDirection="column" alignItems="flex-end">
              <CallToActionLink href={buildGoToCheckoutUrl()}>
                {availability?.instant_booking
                  ? translate(`cx.global.book.long`)
                  : translate(`cx.global.book_inquiry.long`)}
              </CallToActionLink>
              <TextCaption textColor="disabled">
                {translate(`cx.details.no_charge`)}
              </TextCaption>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </Panel>
      <PhotoGallery
        images={unitImages}
        reveal={openPhotoGallery}
        onClose={() => setOpenPhotoGallery(false)}
        title={unit.name}
      />
    </>
  );
};

export default UnitTypeItem;
