import React from 'react';
import { TextBody, TextH2 } from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import { useDetectMobile } from '../../../../shared/hooks/useDetectMobile';

const ListingSubHeadline = () => {
  const { isMobile } = useDetectMobile();
  const listing = useSelector(state => state.listing);

  return isMobile ? (
    <TextBody textColor="dark-gray">
      {listing.property.summary_headline}
    </TextBody>
  ) : (
    <TextH2 textColor="dark-gray" weight="regular">
      {listing.property.summary_headline}
    </TextH2>
  );
};

export default ListingSubHeadline;
