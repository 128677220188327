import React from 'react';
import { Spacer, TextH2 } from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import { selectVerificationState } from '../../../redux/slices/verification';
import SignatureCaptureV2 from '../../inputs/signature-capture-v2';

const VerificationSignature = ({ updateVerificationState }) => {
  const verificationState = useSelector(selectVerificationState);

  return (
    <>
      <TextH2>Electronic Signature</TextH2>
      <Spacer />
      <SignatureCaptureV2
        booking={verificationState.booking}
        afterDelete={() => updateVerificationState({ signature: null })}
        afterSave={signature => updateVerificationState({ signature })}
      />
      <Spacer size="l" />
    </>
  );
};

export default VerificationSignature;
