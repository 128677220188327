import React from 'react';
import { TextH1, TextH2 } from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import { useDetectMobile } from '../../../../shared/hooks/useDetectMobile';

const ListingName = () => {
  const { isMobile } = useDetectMobile();
  const listing = useSelector(state => state.listing);

  return isMobile ? (
    <TextH2 textColor="dark-gray">{listing.property.name}</TextH2>
  ) : (
    <TextH1 textColor="dark-gray">{listing.property.name}</TextH1>
  );
};

export default ListingName;
