import React from 'react';
import { useSelector } from 'react-redux';
import { selectUiState } from '../../redux/slices/ui';
import PageV2 from './page-v2';
import Pages from './index';

const PageSwitch = props => {
  const ui = useSelector(selectUiState);

  return ui.cmsVersion === 'v2' ? <PageV2 {...props} /> : <Pages {...props} />;
};

export default PageSwitch;
