import React from 'react';
import { useSelector } from 'react-redux';
import { selectUiState } from '../../redux/slices/ui';
import ContractV2 from './contract-v2';
import NewContract from './new';

const ContractSwitch = props => {
  const ui = useSelector(selectUiState);

  return ui.isBookingEngine ? (
    <ContractV2 {...props} />
  ) : (
    <NewContract {...props} />
  );
};

export default ContractSwitch;
