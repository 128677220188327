import React from 'react';
import { useSelector } from 'react-redux';
import { selectUiState } from '../../redux/slices/ui';
import Home from './index';
import HomeV2 from './home-v2';

const HomeSwitch = props => {
  const ui = useSelector(selectUiState);

  return ui.cmsVersion === 'v2' ? <HomeV2 {...props} /> : <Home {...props} />;
};

export default HomeSwitch;
