import React from 'react';

const HairDryer = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -46 512 512"
      style={{
        enableBackground: 'new 0 0 512 512'
      }}
      {...props}
    >
      <path d="M411.969 40c-29.68 0-54.399 21.66-59.168 50h-70.852c-5.527 0-10 4.48-10 10s4.473 10 10 10h70.852c4.77 28.34 29.488 50 59.168 50 33.09 0 60.011-26.922 60.011-60S445.06 40 411.97 40zm0 100c-22.059 0-40.008-17.941-40.008-40s17.95-40 40.008-40c22.062 0 40.011 17.941 40.011 40s-17.949 40-40.011 40zm0 0" />
      <path d="M415.602 0h-38.653c-.539 0-1.078.04-1.61.129L70.84 49.73 26.33 34.898a20.05 20.05 0 0 0-18.02 2.75A20.056 20.056 0 0 0 0 53.871v92.258c0 6.41 3.11 12.473 8.309 16.223a20.082 20.082 0 0 0 11.71 3.77c2.122 0 4.25-.34 6.301-1.02l44.508-14.832 281.133 46v20.742c-22.8 4.636-40.012 24.84-40.012 48.988s17.211 44.352 40.012 48.988V360c0 33.078 26.918 60 60.02 60 33.078 0 60-26.922 60-60V180.988a96.501 96.501 0 0 0 11.847-10.5C502 151.57 512 126.54 512 100c0-55.14-43.25-100-96.398-100zM60.012 132.79 20 146.128v-92.25L60.012 67.21zm291.949 161.5c-11.652-4.13-20.012-15.25-20.012-28.29s8.36-24.16 20.012-28.29zM451.98 360c0 22.059-17.942 40-40.02 40-22.063 0-40-17.941-40-40V199.54l2.02.331c.53.09 1.07.129 1.609.129h40.012c12.363 0 25.054-2.398 36.378-7.43zm17.417-203.36a77.61 77.61 0 0 1-12.976 10.88C444.21 175.68 430.09 180 415.602 180h-39.204l-12.84-2.102L80.013 131.5v-63L377.762 20h37.84C457.73 20 492 55.89 492 100c0 21.352-8.02 41.46-22.602 56.64-3.93 4.098 14.582-15.18 0 0zm0 0" />
      <path d="M241.96 110c-5.523 0-10.007-4.477-10.007-10s4.473-10 9.996-10h.012c5.523 0 10 4.477 10 10s-4.477 10-10 10zm0 0" />
    </svg>
  );
};

export default HairDryer;
