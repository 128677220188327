import React from 'react';
import styled from 'styled-components';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import axios from 'axios';
import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react'
import { useDispatch, useSelector } from 'react-redux';
import Ripple from './components/miscellaneous/ripple';
import ScrollToTop from './components/miscellaneous/scroll-to-top';
import HeaderSwitch from './components/layout/header-switch';
import Listings from './redux/containers/listings';
import Listing from './redux/containers/listing';
import Review from './components/reviews/new';
import SignIn from './components/guests/sign-in';
import SignUp from './components/guests/sign-up';
import NoMatch from './components/NoMatch';
import * as brandAction from './redux/action/brand';
import { updateUiSlice } from './redux/slices/ui';
import CheckoutSwitch from './components/checkout/checkout-switch';
import VerificationSwitch from './components/verification/verification-switch';
import ReceiptSwitch from './components/receipt/receipt-switch';
import PaymentSwitch from './components/payment/payment-switch';
import ContractSwitch from './components/contract/contract-switch';
import LayoutManager from './components/layout/layout-manager';
import FooterSwitch from './components/layout/footer-switch';
import PageSwitch from './components/pages/page-switch';
import HomeSwitch from './components/home/home-switch';

const LoadingWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100%;
`;

const MainWrapper = styled.main`
  margin: 0 !important;
`;

const App = () => {
  const dispatch = useDispatch();
  const { brandLoaded } = useSelector(state => state.ui);

  if (!brandLoaded) {
    axios.get('/api/organizations').then(async res => {
      dispatch(brandAction.setBrand(res.data));
      dispatch(
        updateUiSlice({
          cmsVersion: res.data.cms_version === 1 ? 'v2' : 'legacy',
          brandLoaded: true
        })
      );
    });
  }

  const config = {
    apiKey: process.env.HONEYBADGER_API_KEY,
    environment: process.env.NODE_ENV,
    revision: process.env.SOURCE_VERSION
  };

  const honeybadger = Honeybadger.configure(config);


  return !brandLoaded ? (
    <LoadingWrapper>
      <Ripple color="#50E3C2" />
    </LoadingWrapper>
  ) : (
    <HoneybadgerErrorBoundary honeybadger={honeybadger}>
      <Router>
        <LayoutManager>
          <ScrollToTop>
            <HeaderSwitch />
            <MainWrapper className="cx-main listings_details theme-default_mixed">
              <Switch>
                <Route path="/checkout/:slug/:id" component={CheckoutSwitch} />
                <Route path="/checkout/:id" component={CheckoutSwitch} />
                <Route path="/listings/search" component={Listings} />
                <Route path="/listings/list" component={Listings} />
                <Route path="/listings/grid" component={Listings} />
                <Route path="/listings/map" component={Listings} />
                <Route path="/listings/:listing_slug" component={Listing} />
                <Redirect from="/listings" to="/listings/search" />
                <Route path="/pages/:page_slug" component={PageSwitch} />
                <Route
                  path="/my-bookings/verification/:slug/:booking_code"
                  component={VerificationSwitch}
                />
                <Route
                  path="/my-bookings/verification/:booking_code"
                  component={VerificationSwitch}
                />
                <Route
                  path="/my-bookings/new-contract/:booking_code"
                  component={ContractSwitch}
                />
                <Route
                  path="/my-bookings/receipt/:slug/:booking_code"
                  component={ReceiptSwitch}
                />
                <Route
                  path="/my-bookings/receipt/:booking_code"
                  component={ReceiptSwitch}
                />
                <Route
                  path="/my-bookings/payment/:booking_code"
                  component={PaymentSwitch}
                />
                <Route
                  path="/my-bookings/:booking_code"
                  component={PaymentSwitch}
                />
                <Route path="/reviews/new/:booking_code" component={Review} />
                <Route path="/sign_in" component={SignIn} />
                <Route path="/sign_up" component={SignUp} />
                <Route exact path="/" component={HomeSwitch} />
                <Route component={NoMatch} />
              </Switch>
            </MainWrapper>
            <FooterSwitch />
          </ScrollToTop>
        </LayoutManager>
      </Router>
    </HoneybadgerErrorBoundary>
  );
};

export default App;
