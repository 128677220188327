import React, { useEffect, useState } from 'react';
import { InputDateRange } from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import { isInclusivelyBeforeDay } from 'react-dates';
import {
  calculateMaxes,
  isOutsideRange,
  parseQuery
} from '../resources/shared-functions';
import { selectListingsSearchState } from '../../../redux/slices/listingsSearch';
import { useDetectMobile } from '../../../../shared/hooks/useDetectMobile';

const ListingsSearchDateRange = ({ updateSearchState, translate }) => {
  const brand = useSelector(state => state.brand);
  const { isLargeDesktop, isMobile } = useDetectMobile();
  const dateFormat = useSelector(state => state.brand.date_format);
  const searchState = useSelector(selectListingsSearchState);
  const [localStartDate, setLocalStartDate] = useState(null);
  const [localEndDate, setLocalEndDate] = useState(null);
  const [localFocusedInput, setLocalFocusedInput] = useState(null);

  const adjustDateFormat = () => {
    if (dateFormat === 'DD/MM/YYYY') {
      return dateFormat
        .replace('/', ' ')
        .replace('/', ' ')
        .replace('MM', 'MMM');
    }

    return dateFormat
      .replace('/', ' ')
      .replace('/', ' ')
      .replace('MM', 'MMM')
      .replace('DD', 'DD,');
  };

  const respondToDatesChange = (checkInDate, checkOutDate) => {
    if (isInclusivelyBeforeDay(checkInDate, checkOutDate)) {
      const bookingRange = [];
      const d = checkInDate.clone();
      while (isInclusivelyBeforeDay(d, checkOutDate)) {
        bookingRange.push({
          key: d.format('DD-MM-YYYY'),
          day: d.day()
        });
        d.add(1, 'days');
      }
      updateSearchState(
        {
          bookingRange,
          bookingLength: bookingRange.length - 1,
          checkIn: checkInDate,
          checkOut: checkOutDate
        },
        true
      );
      calculateMaxes(searchState, updateSearchState);
    } else if (!checkInDate && !checkOutDate) {
      updateSearchState(
        {
          bookingRange: null,
          bookingLength: 0,
          checkIn: null,
          checkOut: null
        },
        true
      );
    }
  };

  useEffect(() => {
    const queryInfo = parseQuery(location.search, brand);
    if (queryInfo.checkIn) setLocalStartDate(queryInfo.checkIn);
    if (queryInfo.checkOut) setLocalEndDate(queryInfo.checkOut);
  }, []);

  return (
    <InputDateRange
      startDatePlaceholderText={brand.isRvFleet ? translate(`cx.global.pick_up`) : translate(`cx.global.check_in`)}
      endDatePlaceholderText={brand.isRvFleet ? translate(`cx.global.drop_off`) : translate(`cx.global.check_out`)}
      focusedInput={localFocusedInput}
      startDate={localStartDate}
      endDate={localEndDate}
      onDatesChange={({ startDate, endDate }) => {
        setLocalStartDate(startDate);
        setLocalEndDate(endDate);
        respondToDatesChange(startDate, endDate);
      }}
      onFocusChange={focusedInput => setLocalFocusedInput(focusedInput)}
      isDense
      inputWidth={isLargeDesktop ? 'xl' : 'l'}
      removeBottomSpacer
      isOutsideRange={isOutsideRange}
      displayFormat={adjustDateFormat()}
      orientation={isMobile ? 'vertical' : 'horizontal'}
      withFullScreenPortal={isMobile}
      showClearDates
      readOnly
    />
  );
};

export default ListingsSearchDateRange;
