import {connect} from 'react-redux'

import SearchSwitch from '../../components/listings/search-switch';

function mapStateToProps(state) {
    return {
        listings: state.listings.length ? state.listings : []
    };
}

export default connect(mapStateToProps)(SearchSwitch)
