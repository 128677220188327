import React from 'react';
import { TextDense, Box, Spacer } from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import ListingsDetailsContentBlock from '../../listing/shared-v2/listings-details-content-block';
import { buildAddress } from '../../listings/resources/shared-functions';
import { selectReceiptState } from '../../../redux/slices/receipt';
import MapMarker from '../../listing/map-marker';

const DeliveryLocationMap = ({ shortMap }) => {
  const brand = useSelector(state => state.brand);
  const receiptState = useSelector(selectReceiptState);

  return (
    <ListingsDetailsContentBlock label="Delivery Location">
      <TextDense>
        {buildAddress(receiptState.delivery_location, true)}
      </TextDense>
      <Spacer />
      <Box style={shortMap ? { height: '25vh' } : { height: '50vh' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: brand.google_maps_api_key }}
          center={[
            receiptState.delivery_location.geo_latitude,
            receiptState.delivery_location.geo_longitude
          ]}
          zoom={12}
          options={{
            gestureHandling: 'greedy',
            mapTypeControl: false,
            panControl: false,
            scrollwheel: false
          }}
          resetBoundsOnResize={false}
          onChange={() => {}}
        >
          <MapMarker
            lat={receiptState.delivery_location.geo_latitude}
            lng={receiptState.delivery_location.geo_longitude}
          />
        </GoogleMapReact>
      </Box>
    </ListingsDetailsContentBlock>
  );
};

DeliveryLocationMap.propTypes = {};

export default DeliveryLocationMap;
