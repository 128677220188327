import React from 'react';
import { Box, Spacer, TextBody } from '@directsoftware/ui-kit-web-admin';
import GoogleMapReact from 'google-map-react';
import { useSelector } from 'react-redux';
import ListingsDetailsContentBlock from './listings-details-content-block';
import MapMarker from '../map-marker';

const LocationMap = ({ locationState, shortMap }) => {
  const brand = useSelector(state => state.brand);
  const listing = locationState
    ? { location: locationState }
    : useSelector(state => state.listing);

  return (
    <ListingsDetailsContentBlock label="Listing Location">
      <TextBody>
        {`This listing is located in ${listing.location.adr_city}, ${
          listing.location.adr_state
        }. The listing's full
        address will be made available to you upon booking.`}
      </TextBody>
      <Spacer />
      <Box style={shortMap ? { height: '25vh' } : { height: '50vh' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: brand.google_maps_api_key }}
          center={[
            listing.location.geo_latitude,
            listing.location.geo_longitude
          ]}
          zoom={12}
          options={{
            gestureHandling: 'greedy',
            mapTypeControl: false,
            panControl: false,
            scrollwheel: false
          }}
          resetBoundsOnResize={false}
          onChange={() => {}}
        >
          <MapMarker
            lat={listing.location.geo_latitude}
            lng={listing.location.geo_longitude}
          />
        </GoogleMapReact>
      </Box>
    </ListingsDetailsContentBlock>
  );
};

export default LocationMap;
