import React, { useCallback, useState } from 'react';
import {
  Box,
  InputSlider,
  Label,
  Spacer
} from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { selectListingsSearchState } from '../../../redux/slices/listingsSearch';
import { formatCurrency } from '../../../../shared/helpers';

const ListingsSearchPrice = ({ updateSearchState }) => {
  const searchState = useSelector(selectListingsSearchState);
  const [filterPriceLow, setFilterPriceLow] = useState(searchState.min_price);
  const [filterPriceHigh, setFilterPriceHigh] = useState(searchState.max_price);
  const brand = useSelector(state => state.brand);

  const updateSearch = filterState => {
    setFilterPriceLow(filterState.filter_priceLow);
    setFilterPriceHigh(filterState.filter_priceHigh);

    debouncedUpdateSearchState(filterState);
  };

  const debouncedUpdateSearchState = useCallback(
    debounce(
      (filterState) => {
        updateSearchState(filterState, true);
      },
      300
    ),
    []
  );

  return (
    searchState.max_price &&
    searchState.min_price !== null && (
      <Box paddingBottom="xs">
        <Label>Price</Label>
        <Spacer size="xxs" />
        <InputSlider
          isRange
          showRangeMinMax
          customColor={brand.brand_info.colors?.color_primary}
          onChange={value => {
            updateSearch(
              {
                filter_priceLow: value[0],
                filter_priceHigh: value[1]
              }
            );
          }}
          min={searchState.min_price}
          max={searchState.max_price}
          minDisplay={formatCurrency(
            filterPriceLow,
            brand.currency,
            null,
            true
          )}
          maxDisplay={formatCurrency(
            filterPriceHigh,
            brand.currency,
            null,
            true
          )}
          value={[filterPriceLow, filterPriceHigh]}
        />
      </Box>
    )
  );
};

export default ListingsSearchPrice;
