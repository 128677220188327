import React from 'react';

const Iron = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        enableBackground: 'new 0 0 512 512'
      }}
      viewBox="0 0 512 512"
      {...props}
    >
      <path d="M423.385 167.385h-185.6c-27.96 0-50.708 22.748-50.708 50.708 0 15.473 12.588 28.062 28.062 28.062h208.246c21.717 0 39.385-17.668 39.385-39.385s-17.669-39.385-39.385-39.385zm0 59.077H215.138c-4.615 0-8.369-3.754-8.369-8.369 0-17.102 13.914-31.015 31.015-31.015h185.6c10.858 0 19.692 8.834 19.692 19.692s-8.833 19.692-19.691 19.692z" />
      <path d="M449.644 108.308H270.109a270.32 270.32 0 0 0-120.596 28.413c-8.136-8.433-19.335-13.289-31.36-13.289-24.084 0-43.677 19.593-43.677 43.677a43.778 43.778 0 0 0 4.897 20.084c-39.659 39.583-66.256 90.674-75.584 145.934A272.219 272.219 0 0 0 0 378.417v15.429c0 5.438 4.408 9.846 9.846 9.846h492.308c5.438 0 9.846-4.408 9.846-9.846V170.663c0-34.382-27.973-62.355-62.356-62.355zm-331.49 34.816c4.65 0 9.08 1.321 12.855 3.722a269.668 269.668 0 0 0-7.151 4.476l-.39.251c-1.372.888-2.735 1.79-4.09 2.704-.4.269-.8.537-1.197.807a255.352 255.352 0 0 0-3.169 2.198c-.633.444-1.266.886-1.895 1.336-.778.556-1.549 1.121-2.321 1.687-.855.624-1.709 1.247-2.557 1.883-.48.359-.954.728-1.433 1.09a274.957 274.957 0 0 0-3.284 2.523l-.179.143a268.626 268.626 0 0 0-8.467 6.92l-.018.015a24.216 24.216 0 0 1-.688-5.769c0-13.226 10.758-23.986 23.984-23.986zm374.154 142.414h-39.385c-5.438 0-9.846 4.408-9.846 9.846s4.408 9.846 9.846 9.846h39.385v19.692H137.846c-5.438 0-9.846 4.408-9.846 9.846s4.408 9.846 9.846 9.846h354.462V384H19.692v-5.583c0-1.613.02-3.226.05-4.839.01-.505.026-1.011.038-1.517a272.253 272.253 0 0 1 .177-5.075c.051-1.122.11-2.245.176-3.366.03-.509.057-1.018.09-1.526.099-1.534.21-3.067.337-4.598l.017-.227c.135-1.608.289-3.213.454-4.817.05-.482.105-.964.159-1.446a258.83 258.83 0 0 1 .602-5.056c.058-.445.122-.889.182-1.334H78.77c5.438 0 9.846-4.408 9.846-9.846s-4.408-9.846-9.846-9.846H25.421c10.863-50.208 36.319-94.165 71.458-127.737a9.81 9.81 0 0 0 1.902-1.385 251.216 251.216 0 0 1 52.716-37.974 9.837 9.837 0 0 0 1.563-1.054C188.116 138.328 228.002 128 270.109 128h179.535c23.524 0 42.663 19.139 42.663 42.663v114.875z" />
      <path d="M117.967 332.849a9.99 9.99 0 0 0-.571-1.841 9.654 9.654 0 0 0-.896-1.703 10.019 10.019 0 0 0-1.231-1.497 10.02 10.02 0 0 0-3.191-2.127 9.55 9.55 0 0 0-1.851-.561 9.696 9.696 0 0 0-3.84 0c-.63.118-1.25.315-1.841.561a9.631 9.631 0 0 0-1.703.906 9.38 9.38 0 0 0-1.497 1.221 9.435 9.435 0 0 0-1.221 1.497 9.96 9.96 0 0 0-1.664 5.464 9.96 9.96 0 0 0 1.664 5.464 9.38 9.38 0 0 0 1.221 1.497c.453.453.955.866 1.497 1.221.532.354 1.103.66 1.703.906a9.252 9.252 0 0 0 1.841.561c.63.128 1.28.197 1.92.197.64 0 1.29-.069 1.92-.197a9.55 9.55 0 0 0 1.851-.561 9.949 9.949 0 0 0 1.694-.906c.542-.354 1.044-.768 1.497-1.221s.866-.955 1.231-1.497c.354-.532.65-1.103.896-1.703a9.99 9.99 0 0 0 .571-1.841c.128-.63.187-1.28.187-1.92s-.059-1.29-.187-1.92zM433.044 293.465a9.99 9.99 0 0 0-.571-1.841 9.631 9.631 0 0 0-.906-1.703 9.38 9.38 0 0 0-1.221-1.497 9.38 9.38 0 0 0-1.497-1.221 9.768 9.768 0 0 0-1.703-.906 9.252 9.252 0 0 0-1.841-.561 9.696 9.696 0 0 0-3.84 0c-.63.118-1.25.315-1.841.561a9.631 9.631 0 0 0-1.703.906 9.38 9.38 0 0 0-1.497 1.221 9.435 9.435 0 0 0-1.221 1.497 9.768 9.768 0 0 0-.906 1.703 9.252 9.252 0 0 0-.561 1.841 9.698 9.698 0 0 0-.197 1.92c0 .64.069 1.29.197 1.92.118.63.315 1.25.561 1.851.246.591.551 1.162.906 1.694a9.38 9.38 0 0 0 1.221 1.497 9.38 9.38 0 0 0 1.497 1.221c.532.354 1.103.66 1.703.906a9.252 9.252 0 0 0 1.841.561c.63.128 1.28.197 1.92.197s1.29-.069 1.92-.197a9.252 9.252 0 0 0 1.841-.561 9.631 9.631 0 0 0 1.703-.906 9.38 9.38 0 0 0 1.497-1.221 10.02 10.02 0 0 0 2.698-5.042c.128-.63.187-1.28.187-1.92s-.059-1.29-.187-1.92z" />
    </svg>
  );
};

export default Iron;
