import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  Box,
  CallToActionButton,
  Container,
  Divider,
  FlexBox,
  Panel,
  Spacer,
  TextCaption,
  TextH2
} from '@directsoftware/ui-kit-web-admin';
import ReactI18n from 'react-i18n';
import { Link } from 'react-router-dom';
import {
  selectContractState,
  updateContractSlice
} from '../../redux/slices/contract';
import { selectUiState, updateUiSlice } from '../../redux/slices/ui';
import PageHeader from '../shared/page-header';
import { useDetectMobile } from '../../../shared/hooks/useDetectMobile';
import ListingInfo from '../shared/listing-info';
import PaymentBreakdown from '../shared/payment-breakdown';
import RemainingTotal from '../shared/remaining-total';
import SignatureCaptureV2 from '../inputs/signature-capture-v2';

const ContractV2 = props => {
  const { isLargeTabletOrGreater } = useDetectMobile();
  const dispatch = useDispatch();
  const contractState = useSelector(selectContractState);
  const { contractStateChanged } = useSelector(selectUiState);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loading, setLoading] = useState(true);
  const translate = ReactI18n.getIntlMessage;

  const updateContractState = (data, isFilterChange = false) => {
    dispatch(updateContractSlice(data));
    if (isFilterChange) dispatch(updateUiSlice({ contractStateChanged: true }));
  };

  const postData = () => {
    const data = { booking_id: contractState.booking.id };
    if (contractState.signature) {
      data.signature_id = contractState.signature.id;
    }

    if (contractState.idPhoto) {
      data.id_photo_id = contractState.idPhoto.id;
    }

    if (contractState.address_verified) {
      data.location = {
        adr_street: contractState.adrStreet,
        adr_unit: contractState.adrUnit,
        adr_city: contractState.adrCity,
        adr_state: contractState.adrState,
        adr_country: contractState.adrCountry,
        adr_postal_code: contractState.adrPostalCode
      };
    }

    if (contractState.age_verified) {
      data.age_verified = contractState.age_verified;
    }

    return data;
  };

  const checkCustomerVerification = () => {
    let sig_verified = true;
    if (
      contractState.verify_signature &&
      !contractState.customerVerifiedSignature
    ) {
      sig_verified = false;
    }

    // If user is already verified, pass them directly to the confirmation page
    if (sig_verified) {
      axios
        .post(
          `${process.env.DIRECT_URL}/api/v2/my-bookings/verify/${
            contractState.customer.id
          }`,
          postData()
        )
        .then(response => {
          window.location = `/my-bookings/receipt/${
            contractState.booking.booking_code
          }?verified=true`;
        })
        .catch(error => {
          toast.error(error);
          console.log(error);
        });
    }
  };

  const fetchVerificationInfo = () => {
    axios
      .get(
        `${process.env.DIRECT_URL}/api/v2/my-bookings/verification/${
          props.match.params.booking_code
        }`
      )
      .then(response => {
        updateContractState(
          {
            availability: response.data.availability,
            booking: response.data.booking,
            charges: response.data.charges,
            customer: response.data.customer,
            customerVerifiedAddress: response.data.customer_verified_address,
            customerVerifiedAge: response.data.customer_verified_age,
            customerVerifiedId: response.data.customer_verified_id,
            customerVerifiedSignature:
              response.data.customer_verified_signature,
            featured_image: response.data.featured_image,
            listing: response.data.listing,
            location: response.data.location,
            property: response.data.property,
            rentalAgreement: response.data.rental_agreement,
            propertyManager: response.data.property_manager,
            slug: response.data.slug,
            unit: response.data.unit,
            verify_id: response.data.verify_id,
            verify_id_description: response.data.verify_id_description,
            verify_signature: response.data.verify_signature,
            verify_address: response.data.verify_address,
            verify_age: response.data.verify_age,
            required_age: response.data.required_age,
            contractTermsAndConditions:
              response.data.contract_terms_and_conditions
          },
          true
        );
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const submitVerification = () => {
    axios
      .post(
        `${process.env.DIRECT_URL}/api/v2/my-bookings/verify/${
          contractState.customer.id
        }`,
        postData()
      )
      .then(response => {
        window.location = `/my-bookings/receipt/${
          contractState.booking.booking_code
        }?verified=true`;
      })
      .catch(error => {
        toast.error(error);
        console.log(error);
      });
  };

  const handleSubmit = e => {
    submitVerification();
  };

  useEffect(
    () => {
      if (contractStateChanged) {
        dispatch(updateUiSlice({ contractStateChanged: false }));
        checkCustomerVerification();
      }
    },
    [contractState, contractStateChanged]
  );

  useEffect(() => {
    fetchVerificationInfo();
  }, []);

  return loading ? null : (
    <>
      <Container variation="extended-wls">
        <PageHeader
          label="New Contract"
          backLink={`/my-bookings/receipt/${
            contractState.booking.booking_code
          }`}
        />
        <FlexBox className="detailsLayout checkoutLayout">
          <Box flex="1">
            <TextH2>Electronic Signature</TextH2>
            <Spacer />
            <SignatureCaptureV2
              verify_signature={contractState.verify_signature}
              afterSave={signature => {
                updateContractState({ signature });
                setSubmitDisabled(false);
              }}
              afterDelete={() => updateContractState({ signature: null })}
              contract_terms_and_conditions={
                contractState.contractTermsAndConditions
              }
              required_age={contractState.required_age}
              verify_address={contractState.verify_address}
              verify_age={contractState.verify_age}
              age_verified={contractState.age_verified}
              booking={contractState.booking}
              updateState={updateContractState}
              {...props}
            />
            <Divider padding="l" />
            <CallToActionButton
              onClick={() => handleSubmit()}
              isDisabled={submitDisabled}
              size="large"
              isFullWidth
            >
              Submit Verification
            </CallToActionButton>
            <Spacer size="m" />
            <TextCaption>
              <div
                dangerouslySetInnerHTML={{
                  __html: translate(`cx.global.book_confirm.rules`, {
                    property_url: `/listings/${contractState.slug}`
                  })
                }}
              />
            </TextCaption>
            {contractState.rental_agreement ? (
              <>
                <Spacer />
                <Box>{translate(`cx.global.book_confirm.contract`)}</Box>
                <Link
                  to={contractState.rental_agreement.pdf.url}
                  target="_blank"
                >
                  {translate(`cx.global.book_confirm.contract_link`)}
                </Link>
                .
              </>
            ) : null}
          </Box>
          {isLargeTabletOrGreater && (
            <Box className="detailsLayout__right checkoutLayout">
              <Panel addShadow>
                <Box padding="s">
                  <ListingInfo stateObject={contractState} />
                </Box>
                <Divider />
                <Box padding="s">
                  <PaymentBreakdown stateObject={contractState} />
                </Box>
                <Divider />
                <Box padding="s">
                  <RemainingTotal
                    priceTotal={contractState.booking.price_total}
                    pricePaid={contractState.booking.price_paid}
                  />
                </Box>
              </Panel>
            </Box>
          )}
        </FlexBox>
      </Container>
    </>
  );
};

export default ContractV2;
