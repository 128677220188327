import React from 'react';
import {
  CallToActionButton,
  CallToActionLink,
  FlexBox,
  IconFontAwesome,
  Spacer,
  TextBody
} from '@directsoftware/ui-kit-web-admin';
import ReactI18n from 'react-i18n';
import { useSelector } from 'react-redux';
import ListingsDetailsContentBlock from './listings-details-content-block';

const ManagerInfo = () => {
  const listing = useSelector(state => state.listing);
  const translate = ReactI18n.getIntlMessage;

  return (
    <ListingsDetailsContentBlock label={translate(`cx.details.manager`)}>
      <TextBody textColor="dark-gray" weight="semibold">
        {listing.property_manager.name}
      </TextBody>
      <Spacer size="xs" />
      <FlexBox>
        <CallToActionButton
          onClick={() => document.getElementById('contact-owner').click()}
          type="link"
          customButtonColor="#F1F1F4"
          customTextColor="#505775"
        >
          Contact Owner
        </CallToActionButton>
      </FlexBox>
    </ListingsDetailsContentBlock>
  );
};

export default ManagerInfo;
