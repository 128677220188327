// Dependencies
// -----------------------------------------------
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// -----------------------------------------------
// COMPONENT->META -------------------------------
// -----------------------------------------------
class Meta extends React.Component {
  // Constructor
  // ---------------------------------------------
  constructor(props) {
    super(props);
  }

  // Render
  // ---------------------------------------------
  render() {
    return (
      <Helmet>
        <title>{`Checkout: ${this.props.brand.name}`}</title>
      </Helmet>
    );
  }
}

// Map State to Props
// -----------------------------------------------
function mapStateToProps(state) {
  return {
    brand: state.brand ? state.brand : {}
  };
}

// Export
// -----------------------------------------------
export default connect(mapStateToProps)(Meta);
