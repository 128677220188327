import React, { useEffect, useState } from 'react';
import axios from 'axios';
import get from 'lodash/get';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import CheckoutCta from '../shared/checkout-cta';
import { baseLynnbrookConfig } from '../../../shared/data';
import { updatePaymentSlice } from '../../redux/slices/payment';

const LynnbrookPayment = props => {
  const dispatch = useDispatch();
  const [lynnbrookCardAccepted, setLynnbrookCardAccepted] = useState(false);
  const [lynnbrookErrors, setLynnbrookErrors] = useState([]);
  const [lynnbrookPaymentMethod, setLynnbrookPaymentMethod] = useState(null);
  const [cardError, setCardError] = useState(false);
  const [disableCta, setDisableCta] = useState(false);

  const submitLynnbrook = () => {
    if (typeof aptxFields !== 'undefined') {
      aptxFields.submit('CARD');
    }
  };

  const renderError = key => {
    if (lynnbrookErrors.length > 0) {
      const findError = lynnbrookErrors.filter(error => error.field === key);
      return findError[0]?.type ? (
        <div className="lynnbrook-error">{findError[0].type}</div>
      ) : null;
    }
    return null;
  };

  const renderMethodError = key => {
    if (lynnbrookErrors.length > 0) {
      const findError = lynnbrookErrors.filter(error => error.type === key);
      return findError[0]?.type ? (
        <div className="lynnbrook-error">
          Something was wrong with your card. Please check your info and try
          again.
        </div>
      ) : null;
    }
    return null;
  };

  const handleCompletePayment = () => {
    setDisableCta(true);
    axios
      .post(
        `${process.env.DIRECT_URL}/api/v2/listings/${
          props.listing.id
        }/process_payment`,
        {
          charge_amount: parseFloat(props.chargeAmount),
          booking_id: props.booking.id,
          customer_email: props.customerEmail,
          customer_name: props.customerName,
          customer_telephone: props.customerTelephone,
          method_id: lynnbrookPaymentMethod.methodId
        }
      )
      .then(() => {
        dispatch(updatePaymentSlice({ lynnbrookCardPaymentFailed: false }));
        setDisableCta(false);
        window.location = window.location;
      })
      .catch(data => {
        dispatch(updatePaymentSlice({ lynnbrookCardPaymentFailed: true }));
        props.fetchLynnbrookConfig(props.lynnbrookConfig.accountTargetId);
        toast.error(data.responseJSON.error);
        window.location = window.location;
      });
  };

  const handleCompleteBooking = () => {
    setDisableCta(true);
    axios
      .post(
        `${process.env.DIRECT_URL}/api/v2/checkout_booking/${props.listing.id}`,
        {
          unit_id: props.unit.id,
          booking_range: JSON.stringify(props.bookingDaysInclusive),
          check_in: props.checkInDate.format('DD-MM-YYYY'),
          check_out: props.checkOutDate.format('DD-MM-YYYY'),
          num_guests: props.guests,
          customer_email: props.customerEmail,
          customer_name: `${props.customerFirstName} ${props.customerLastName
            }`,
          customer_telephone: props.customerTelephone,
          adr_street: props.adrStreet,
          adr_city: props.adrCity,
          adr_state: props.adrState,
          adr_country: props.adrCountry,
          adr_zip: props.adrPostalCode,
          addon_fee_ids: props.addonFeeIds,
          stripe_customer_id: props.stripeCustomerId,
          coupon_code: props.couponCode,
          room_type_booking: props.listing.is_room_type,
          quote_id: props.quoteId,
          method_id: lynnbrookPaymentMethod.methodId
        },
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then(response => {
        setDisableCta(false);
        const data = response.data;
        if (props.brand_info.google_events) {
          gtag('event', 'purchase', {
            transaction_id: data.booking_code,
            affiliation: 'Direct',
            value: props.pricing.total,
            currency: 'USD',
            tax: props.pricing.taxes,
            shipping: 0
          });
        }
        if (props.brand_info.facebook_pixel) {
          fbq('track', 'Purchase', {
            currency: 'USD',
            value: props.pricing.total
          });
        }
        if (
          props.verifyImage ||
          props.verifySignature ||
          props.verifyAge ||
          props.verifyAddress
        ) {
          window.location = `/my-bookings/verification/${data.booking_code}`;
        } else {
          window.location = `/my-bookings/receipt/${data.booking_code}`;
        }
      })
      .catch(data => {
        const err = get(data, 'response.data.error[1][0]', 'booking could not be processed, try again later').replace('Lynnbrook ', '');
        setCardError(err);
        toast.error(err);
        props.createAccountPerson();
      });
  };

  useEffect(
    () => {
      setCardError(false);
      if (lynnbrookPaymentMethod?.methodId) {
        setLynnbrookCardAccepted(true);
      } else {
        setLynnbrookCardAccepted(false);
      }
    },
    [lynnbrookPaymentMethod]
  );

  useEffect(
    () => {
      if (props.accountPersonCreated && !lynnbrookCardAccepted) {
        const test = setInterval(() => {
          if (typeof aptxFields !== 'undefined') {
            clearInterval(test);
            const config = {
              ...baseLynnbrookConfig,
              ...props.lynnbrookConfig,
              domain: window.location.origin,
              production: process.env.DIRECT_URL === 'https://app.getdirect.io',
              callback: response => {
                const res = JSON.parse(response);
                setLynnbrookErrors(res?.errors || []);
                delete res.errors;
                if (res.duplicateCardMethods) {
                  const latestMethod = res.duplicateCardMethods.slice(-1);
                  setLynnbrookPaymentMethod(latestMethod[0]);
                } else {
                  setLynnbrookPaymentMethod(res);
                }
              }
            };

            aptxFields.setup(config);
          }
        }, 250);
      }
    },
    [props.accountPersonCreated, lynnbrookCardAccepted]
  );

  return (
    <>
      <header className="addons-title">Payment Information</header>
      {props.accountPersonCreated ? (
        <>
          {lynnbrookCardAccepted ? (
            <div className="guestInfoPlaceholder">
              <div className="guestInfoPlaceholder__guest">
                <div>
                  {`Card: ${lynnbrookPaymentMethod?.brand || lynnbrookPaymentMethod?.brandType} ${lynnbrookPaymentMethod?.lastFour
                    }`}
                </div>
                <div>{lynnbrookPaymentMethod?.accountHolder}</div>
                {cardError && <div style={{ color: 'red' }}>{cardError}</div>}
              </div>
              <div>
                <button
                  onClick={() => setLynnbrookCardAccepted(false)}
                  className="guestInfoPlaceholder__editButton"
                >
                  Edit
                </button>
              </div>
            </div>
          ) : (
            <>
              <div>
                <label htmlFor="aptexx-card-name-container">
                  Cardholder Name:
                </label>
              </div>
              <span
                id="aptexx-card-name-container"
                className="lynnbrook-iframe-input"
              />
              {renderError('CARD_NAME')}
              <div>
                <label htmlFor="aptexx-card-number-container">
                  Card Number:
                </label>
              </div>
              <span
                id="aptexx-card-number-container"
                className="lynnbrook-iframe-input"
              />
              {renderError('CARD_NUMBER')}
              <div className="lynnbrook-input-row">
                <div className="lynnbrook-input-row-cell">
                  <div>
                    <label htmlFor="aptexx-card-expiration-date-container">
                      Exp date:
                    </label>
                  </div>
                  <span
                    id="aptexx-card-expiration-date-container"
                    className="lynnbrook-iframe-input"
                  />
                  {renderError('EXPIRATION_DATE')}
                </div>
                <div className="lynnbrook-input-row-cell">
                  <div>
                    <label htmlFor="aptexx-card-zip-code-container">Zip:</label>
                  </div>
                  <span
                    id="aptexx-card-zip-code-container"
                    className="lynnbrook-iframe-input"
                  />
                  {renderError('ZIP_CODE')}
                </div>
              </div>
              {renderMethodError('METHOD_ERROR')}
            </>
          )}
        </>
      ) : (
        <div className="paymentPlaceholder">
          Please fill out guest information first.
        </div>
      )}
      {!lynnbrookCardAccepted && (
        <button
          type="button"
          onClick={() => submitLynnbrook()}
          disabled={!props.accountPersonCreated}
        >
          Add Card
        </button>
      )}
      <div style={{ height: 16 }} />
      <CheckoutCta
        {...props}
        handleSubmit={
          props.chargeAmount > 0 ? handleCompletePayment : handleCompleteBooking
        }
        disableCta={disableCta || !lynnbrookCardAccepted || !props.accountPersonCreated}
      />
    </>
  );
};

export default LynnbrookPayment;
