import React from 'react';
import { Box } from '@directsoftware/ui-kit-web-admin';
import PhotoGridItem from './photo-grid-item';

const PhotoGridPreviewLayout5 = ({ images, onClick }) => {
  return (
    <>
      <Box className="photoGrid__colLarge">
        <PhotoGridItem src={images[0].src} onClick={onClick} />
      </Box>
      <Box className="photoGrid__colSmall -middle">
        <Box className="photoGrid__imageWrapper">
          <Box className="photoGrid__imageWrapper_image">
            <Box>
              <PhotoGridItem src={images[1].src} onClick={onClick} />
            </Box>
          </Box>
          <Box className="photoGrid__imageWrapper_image">
            <PhotoGridItem src={images[2].src} onClick={onClick} />
          </Box>
        </Box>
      </Box>
      <Box className="photoGrid__colSmall -right">
        <Box className="photoGrid__imageWrapper">
          <Box className="photoGrid__imageWrapper_image">
            <PhotoGridItem src={images[3].src} onClick={onClick} />
          </Box>
          <Box className="photoGrid__imageWrapper_image">
            <PhotoGridItem src={images[4].src} onClick={onClick} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PhotoGridPreviewLayout5;
