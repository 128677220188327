import React from 'react';
import { useSelector } from 'react-redux';
import { selectVerificationState } from '../../../redux/slices/verification';
import ContactAddress from '../../checkout/shared/contact-address';

const VerificationLocation = ({ updateVerificationState }) => {
  const verificationState = useSelector(selectVerificationState);

  return (
    <ContactAddress
      headerText="Confirm Your Address"
      stateObject={verificationState}
      updateCheckoutState={updateVerificationState}
      triggerFilterChange
    />
  );
};

export default VerificationLocation;
