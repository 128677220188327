import React, { useState } from 'react';
import { FormField } from '@directsoftware/ui-kit-web-admin';
import { times } from 'lodash';
import { useSelector } from 'react-redux';
import { selectListingsSearchState } from '../../../redux/slices/listingsSearch';

const ListingsSearchDistance = ({ updateSearchState }) => {
  const searchState = useSelector(selectListingsSearchState);
  const options = times(10, count => ({
    label: count === 0 ? 'Any Distance' : `${count} miles`,
    value: count
  }));

  return (
    <FormField
      labelText="Distance"
      inputType="select"
      inputProps={{
        options,
        onChange: option => {
          updateSearchState({ filter_distance: option.value }, true);
        },
        inputWidth: 's',
        value: options.filter(opt => opt.value === searchState.filter_distance)
      }}
    />
  );
};

export default ListingsSearchDistance;
