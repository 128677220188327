import React from 'react';
import { FlexBox, IconFontAwesome } from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';

const StarRating = ({ rating }) => {
  const brand = useSelector(state => state.brand);

  return (
    <FlexBox gap="xxs" className="reviewRating">
      <IconFontAwesome
        name="star"
        iconStyle={rating >= 1 ? 'solid' : 'regular'}
      />
      <IconFontAwesome
        name="star"
        iconStyle={rating >= 2 ? 'solid' : 'regular'}
      />
      <IconFontAwesome
        name="star"
        iconStyle={rating >= 3 ? 'solid' : 'regular'}
      />
      <IconFontAwesome
        name="star"
        iconStyle={rating >= 4 ? 'solid' : 'regular'}
      />
      <IconFontAwesome
        name="star"
        iconStyle={rating >= 5 ? 'solid' : 'regular'}
      />
    </FlexBox>
  );
};

export default StarRating;
