import React from 'react';
import { useSelector } from 'react-redux';
import ReactI18n from 'react-i18n';
import {
  Box,
  Spacer,
  TextBody,
  TextDense
} from '@directsoftware/ui-kit-web-admin';
import { Link } from 'react-router-dom';
import { selectReceiptState } from '../../../redux/slices/receipt';

const PropertyManagerInfo = () => {
  const receiptState = useSelector(selectReceiptState);
  const translate = ReactI18n.getIntlMessage;

  return (
    receiptState.property_manager && (
      <Box padding="s">
        <TextBody textColor="dark-gray" weight="semibold">
          {translate(`cx.global.property_manager_info`)}
        </TextBody>
        <Spacer size="xs" />
        {receiptState.property_manager.name && (
          <TextBody>{receiptState.property_manager.name}</TextBody>
        )}
        {receiptState.property_manager.email && (
          <Box className="customHtmlStyles">
            <TextDense>
              Email:{' '}
              <Link to={`mailTo:${receiptState.property_manager.email}`}>
                {`${receiptState.property_manager.email}`}
              </Link>
            </TextDense>
          </Box>
        )}
        {receiptState.property_manager.telephone && (
          <Box className="customHtmlStyles">
            <TextDense>
              Phone:{' '}
              <Link
                to={`tel:${receiptState.property_manager.telephone.replace(
                  /[^0-9]/,
                  ''
                )}`}
              >
                {receiptState.property_manager.telephone}
              </Link>
            </TextDense>
          </Box>
        )}
      </Box>
    )
  );
};

export default PropertyManagerInfo;
