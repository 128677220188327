import React from 'react';
import {
  Box,
  FlexBox,
  IconFontAwesome,
  InputSwitch
} from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import { selectListingsSearchState } from '../../../redux/slices/listingsSearch';

const ListingsSearchFilterInstantBooking = ({ updateSearchState }) => {
  const searchState = useSelector(selectListingsSearchState);

  return (
    <FlexBox alignItems="center" gap="xs">
      <Box>
        <IconFontAwesome name="bolt" />
      </Box>
      <InputSwitch
        onChange={() => {
          updateSearchState(
            {
              instantBookingOnly: !searchState.instantBookingOnly,
              filter_instantBook: !searchState.filter_instantBook
            },
            true
          );
        }}
        isChecked={searchState.instantBookingOnly}
        labelPosition="left"
        label="Instant Booking"
        removeBottomSpacer
      />
    </FlexBox>
  );
};

export default ListingsSearchFilterInstantBooking;
