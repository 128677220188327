import React from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  CallToActionLink,
  FlexBox,
  TextBody
} from '@directsoftware/ui-kit-web-admin';
import moment from 'moment/moment';
import { selectReceiptState } from '../../../redux/slices/receipt';
import { formatCurrency } from '../../../../shared/helpers';

const DepositInfo = () => {
  const receiptState = useSelector(selectReceiptState);
  const brand = useSelector(state => state.brand);

  const moreChargesNeeded = () => {
    const { securityDepositRequired, booking, charges } = receiptState;

    const noSuccessfulSecDepCharges =
      charges.findIndex(
        c => c.is_security_deposit === true && c.status !== 'failed'
      ) === -1;

    if (!booking.confirmed || booking.cancelled) {
      return false;
    } else if (
      securityDepositRequired &&
      moment() > moment(booking.check_in).subtract(4, 'days')
    ) {
      return noSuccessfulSecDepCharges;
    } else {
      return noSuccessfulSecDepCharges;
    }
  };

  return (
    <>
      <FlexBox alignItems="center">
        <Box flex="1">
          <TextBody textColor="dark-gray" weight="semibold" isFullWidth>
            Security Deposit Amount
          </TextBody>
          <TextBody textColor="dark-gray" weight="semibold" isFullWidth>
            {formatCurrency(
              receiptState.securityDeposit.calculation_amount,
              brand.currency
            )}
          </TextBody>
        </Box>
        {moreChargesNeeded() && (
          <Box>
            <CallToActionLink
              href={`/my-bookings/payment/${receiptState.booking.booking_code}`}
              customButtonColor={brand.brand_info.colors?.color_primary}
              customTextColor={brand.brand_info.colors?.color_primary_text}
            >
              Pay Now
            </CallToActionLink>
          </Box>
        )}
      </FlexBox>
    </>
  );
};

export default DepositInfo;
