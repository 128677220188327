import React, { useEffect, useState } from 'react';
import {
  Box,
  CallToActionButton,
  Divider,
  FlexBox,
  FormField,
  IconFontAwesome,
  InputSwitch,
  Modal,
  TextBody,
  Tooltip,
  IconButton,
  Spacer
} from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import { times } from 'lodash';
import { Anchorme } from 'react-anchorme';
import { formatCurrency } from '../../../../shared/helpers';
import { selectCheckoutState } from '../../../redux/slices/checkout';
import { useDetectMobile } from '../../../../shared/hooks/useDetectMobile';

const FeeAddon = ({ fee, updateCheckoutState }) => {
  const { isLargeTabletOrGreater } = useDetectMobile();
  const brand = useSelector(state => state.brand);
  const checkoutState = useSelector(selectCheckoutState);
  const [openModal, setOpenModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [localQuantity, setLocalQuantity] = useState(0);
  const [feeIsAdded, setFeeIsAdded] = useState(false);
  const [showInlineDescription, setShowInlineDescription] = useState(false);

  const submitFees = fee => {
    addFeeIds(fee.id, localQuantity);
  };

  const updateFees = feeId => {
    let newArray = [];

    if (checkoutState.addonFeeIds.includes(feeId)) {
      newArray = checkoutState.addonFeeIds.filter(id => id !== feeId);
    } else {
      newArray = checkoutState.addonFeeIds.concat(feeId);
    }
    updateCheckoutState(
      {
        addonFeeIds: newArray,
        checkoutCheckPricing: true
      },
      true
    );
  };

  const addFeeIds = (feeId, quantity) => {
    const addonIds = checkoutState.addonFeeIds.filter(id => id !== feeId);
    times(quantity, () => addonIds.push(feeId));
    updateCheckoutState(
      {
        addonFeeIds: addonIds,
        feeQuantities: [
          ...checkoutState.feeQuantities.filter(fee => fee.id !== feeId),
          {
            id: feeId,
            quantity
          }
        ],
        quantity: 0,
        checkoutCheckPricing: true
      },
      true
    );
    setOpenModal(false);
  };

  const updateQuantity = value => {
    setLocalQuantity(value);
    updateCheckoutState({ quantity: value });
  };

  const renderImage = (fee, makeLarge = false) => {
    return fee.fee_account?.fee_image ? (
      <Box setPositionRelative>
        <img
          src={fee.fee_account.fee_image}
          alt=""
          className={`addonFee__image${makeLarge ? ' -makeLarge' : ''}`}
        />
      </Box>
    ) : null;
  };

  const renderAction = () => {
    return (
      <FlexBox gap="xs" alignItems="center">
        {addModal ? (
          <CallToActionButton
            onClick={() => setOpenModal(true)}
            appearance="ghost"
            customButtonColor={brand.brand_info.colors?.color_primary}
            customTextColor={brand.brand_info.colors?.color_primary_text}
          >
            Manage
          </CallToActionButton>
        ) : (
          <InputSwitch
            onChange={() => updateFees(fee.id)}
            isChecked={checkoutState.addonFeeIds.includes(fee.id)}
            removeBottomSpacer
          />
        )}
        {fee.description && (
          <Tooltip tipContent="Read Description">
            <IconButton
              onClick={() => setShowInlineDescription(!showInlineDescription)}
              appearance="ghost"
              customButtonColor={brand.brand_info.colors?.color_primary}
              customTextColor={brand.brand_info.colors?.color_primary_text}
            >
              <IconFontAwesome
                name={showInlineDescription ? 'chevronUp' : 'chevronDown'}
                iconStyle="regular"
                color={brand.brand_info.colors?.color_primary}
              />
            </IconButton>
          </Tooltip>
        )}
      </FlexBox>
    );
  };

  const getQuantityFromState = () => {
    return checkoutState.feeQuantities.find(feeQ => feeQ.id === fee.id)
      ? checkoutState.feeQuantities.find(feeQ => feeQ.id === fee.id).quantity
      : 0;
  };

  const renderLabel = () => {
    return addModal ? `${fee.name} (${localQuantity})` : fee.name;
  };

  const renderFeeFlatValue = fee => {
    return fee.value === 0 ? fee.calculation_amount : fee.value;
  };

  useEffect(
    () => {
      setAddModal(fee.fee_account?.quantity_fee);
      setLocalQuantity(getQuantityFromState());
      setFeeIsAdded(checkoutState.addonFeeIds.includes(fee.id));
    },
    [fee]
  );

  return (
    <>
      <FlexBox alignItems="center" className="addonFee__wrapper">
        <FlexBox flex="1" gap="s" alignItems="center">
          {feeIsAdded && (
            <Box className="addonFee__added">
              <IconFontAwesome name="checkCircle" />
            </Box>
          )}
          {isLargeTabletOrGreater && renderImage(fee)}
          <Box>
            <TextBody isFullWidth weight="semibold" textColor="dark-gray">
              {renderLabel()}
            </TextBody>
            <FlexBox gap="xs">
              <Box>
                <TextBody weight="semibold" textColor="dark-gray">
                  {fee.calculation_type === 'percent' ? (
                    `${fee.calculation_amount}%`
                  ) : (
                    <>
                      {addModal ? (
                        <>
                          {localQuantity > 1
                            ? formatCurrency(
                                fee.additional_data?.los_ranges_at_creation
                                  ?.length > 0
                                  ? fee.value * localQuantity
                                  : fee.value,
                                checkoutState.brandCurrency
                              )
                            : formatCurrency(
                                renderFeeFlatValue(fee),
                                checkoutState.brandCurrency
                              )}
                        </>
                      ) : (
                        formatCurrency(
                          renderFeeFlatValue(fee),
                          checkoutState.brandCurrency
                        )
                      )}
                    </>
                  )}
                </TextBody>
              </Box>
            </FlexBox>
          </Box>
        </FlexBox>
        {renderAction()}
      </FlexBox>
      {showInlineDescription && (
        <Box>
          <Anchorme target="_blank" rel="noreferrer noopener" truncate={50}
          >
            {fee.description}
          </Anchorme>
          <Spacer size="m" />
        </Box>
      )}
      <Divider />
      {addModal && (
        <Modal
          reveal={openModal}
          closeOnClick={() => setOpenModal(false)}
          title="Add Fee"
          size="form"
        >
          <Modal.Content contentIsScrollable>
            <Box padding="s">
              <FlexBox alignItems="center" gap="s">
                {renderImage(fee, true)}
                <Box>
                  <TextBody isFullWidth weight="semibold" textColor="dark-gray">
                    {renderLabel()}
                  </TextBody>
                  <TextBody weight="semibold" textColor="dark-gray">
                    {fee.calculation_type === 'percent'
                      ? `${fee.calculation_amount}%`
                      : formatCurrency(
                          fee.additional_data?.los_ranges_at_creation?.length >
                            0
                            ? fee.value
                            : fee.calculation_amount,
                          checkoutState.brandCurrency
                        )}
                  </TextBody>
                </Box>
              </FlexBox>
              <Divider padding="s" />
              <FormField
                labelText="How Many to Add?"
                inputType="incrementer"
                inputProps={{
                  onChange: value => updateQuantity(value),
                  value: localQuantity,
                  min: 0,
                  max: fee.fee_account?.fee_quantity_max?.toString(),
                  inputWidth: 'm'
                }}
              />
            </Box>
          </Modal.Content>
          <Modal.Footer
            primaryLabel="Add Fee"
            primaryOnClick={() => submitFees(fee)}
            secondaryOnClick={() => setOpenModal(false)}
            secondaryLabel="Cancel"
            primaryCustomButtonColor={brand.brand_info.colors?.color_primary}
            primaryCustomTextColor={brand.brand_info.colors?.color_primary_text}
          />
        </Modal>
      )}
    </>
  );
};

export default FeeAddon;
