import React from 'react';
import { FlexBox, TextH2 } from '@directsoftware/ui-kit-web-admin';
import ReactI18n from 'react-i18n';
import { useSelector } from 'react-redux';
import { formatCurrency } from '../../../shared/helpers';

const RemainingTotal = ({ priceTotal, pricePaid }) => {
  const brand = useSelector(state => state.brand);
  const translate = ReactI18n.getIntlMessage;

  const total = (Math.floor(parseFloat(priceTotal) * 100) / 100).toFixed(2);
  const paid = (Math.floor(parseFloat(pricePaid) * 100) / 100).toFixed(2);
  let remaining = (total - paid).toFixed(2);
  if (remaining < 0) {
    remaining = (0).toFixed(2);
  }

  return (
    <FlexBox justifyContent="space-between">
      <TextH2>{translate(`cx.global.remaining`)}</TextH2>
      <TextH2>{formatCurrency(remaining, brand.currency)}</TextH2>
    </FlexBox>
  );
};

export default RemainingTotal;
