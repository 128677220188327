import React, { useEffect, useState } from 'react';
import { TabBar, Box } from '@directsoftware/ui-kit-web-admin';
import Scrollchor from 'react-scrollchor';
import { useSelector } from 'react-redux';
import { useDetectMobile } from '../../../../shared/hooks/useDetectMobile';

const DetailsTabNav = ({ isMultiUnit }) => {
  const { isMobile } = useDetectMobile();
  const [addShadow, setAddShadow] = useState(false);
  const [activeTab, setActiveTab] = useState('overview');
  const listing = useSelector(state => state.listing);

  const handleScroll = () => {
    const div = document
      .getElementById('detailsTabNav')
      .getBoundingClientRect();
    setAddShadow(div.top <= 1);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box id="detailsTabNav" className="detailsTabNav">
      <Box style={{ backgroundColor: 'white' }}>
        <TabBar minimalist containerVariation="full-width">
          <TabBar.Tab
            minimalist
            onClick={() => setActiveTab('overview')}
            isActive={activeTab === 'overview'}
            type="button"
            isFullWidth={isMobile}
          >
            <Scrollchor to="#details-overview">Overview</Scrollchor>
          </TabBar.Tab>
          {isMultiUnit && (
            <TabBar.Tab
              minimalist
              type="button"
              onClick={() => setActiveTab('units')}
              isActive={activeTab === 'units'}
              isFullWidth={isMobile}
            >
              <Scrollchor to="#details-units">Units</Scrollchor>
            </TabBar.Tab>
          )}
          <TabBar.Tab
            minimalist
            type="button"
            onClick={() => setActiveTab('location')}
            isActive={activeTab === 'location'}
            isFullWidth={isMobile}
          >
            <Scrollchor to="#details-location">Location</Scrollchor>
          </TabBar.Tab>
          {!isMultiUnit && listing.reviews.length > 0 && (
            <TabBar.Tab
              minimalist
              type="button"
              onClick={() => setActiveTab('reviews')}
              isActive={activeTab === 'reviews'}
              isFullWidth={isMobile}
            >
              <Scrollchor to="#details-reviews">Reviews</Scrollchor>
            </TabBar.Tab>
          )}
        </TabBar>
      </Box>
      {addShadow && <Box className="detailsTabNav__shadow" />}
    </Box>
  );
};

export default DetailsTabNav;
