import React from 'react';
import {
  FlexBoxGrid,
  Spacer,
  TextBody
} from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import ReactI18n from 'react-i18n';
import ListingsDetailsContentBlock from './listings-details-content-block';
import IconCardItem from './icon-card-item';

const BathroomsList = () => {
  const listing = useSelector(state => state.listing);
  const translate = ReactI18n.getIntlMessage;

  const getAmountAmenities = bathroom => {
    let amountTrue = 0;

    bathroom.amenities &&
      Object.keys(bathroom.amenities).map(amenityKey => {
        if (bathroom.amenities[amenityKey].value) {
          amountTrue++;
        }
      });

    return amountTrue > 0;
  };

  const renderAmenity = (bathroom, amenityKey, getIcons = false) => {
    const val = bathroom.amenities[amenityKey].value;

    if (val) {
      if (getIcons) {
        if (amenityKey.includes('SHOWER')) return 'shower';
        if (amenityKey.includes('TUB')) return 'bath';
        if (amenityKey.includes('TOILET')) return 'toilet';
        return false;
      } else {
        return translate(`global.bathroom_amenities.${amenityKey}`);
      }
    }

    return false;
  };

  return (
    <ListingsDetailsContentBlock
      label={translate(`cx.global.amenities.bathroom_info`)}
    >
      <TextBody>
        {translate(`cx.details.summary.num_bathrooms`, {
          num: listing.unit.num_bathrooms,
          s: listing.unit.num_bathrooms === 1 ? '' : 's'
        })}
      </TextBody>
      <Spacer size="m" />
      <FlexBoxGrid columns={2} gap="m">
        {listing.bathrooms
          .filter(bath => getAmountAmenities(bath))
          .map((bathroom, idx) => {
            const bathroomList = [];
            let iconSet = [];
            Object.keys(bathroom.amenities).forEach(amenityKey => {
              const amenity = renderAmenity(bathroom, amenityKey);
              const icon = renderAmenity(bathroom, amenityKey, true);
              if (amenity) bathroomList.push(amenity);
              if (icon) iconSet.push(icon);
            });
            if (iconSet.length > 0) iconSet = [...new Set(iconSet)];
            return (
              <IconCardItem
                key={`bath-${idx}`}
                iconNames={iconSet}
                headline={
                  bathroom.bathroom_type
                    ? translate(
                        `global.bathroom_type.${bathroom.bathroom_type}`
                      )
                    : 'Full Bath'
                }
                listItems={bathroomList}
              />
            );
          })}
      </FlexBoxGrid>
    </ListingsDetailsContentBlock>
  );
};

export default BathroomsList;
