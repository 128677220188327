import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  DisplayLineItem,
  FlexBox,
  IconButton,
  IconFontAwesome,
  TextDense,
  Tooltip
} from '@directsoftware/ui-kit-web-admin';
import { reject } from 'lodash';
import ReactI18n from 'react-i18n';
import { formatCurrency } from '../../../../shared/helpers';
import { selectCheckoutState } from '../../../redux/slices/checkout';

const CheckoutPricingFees = () => {
  const checkoutState = useSelector(selectCheckoutState);
  const brand = useSelector(state => state.brand);
  const translate = ReactI18n.getIntlMessage;
  const [openFees, setOpenFees] = useState(false);
  const [feeTotal, setFeeTotal] = useState(0);
  const mandatoryFees = reject(checkoutState.fees, ['is_addon', 'true']);

  const renderFeeTotal = () => {
    let total = 0;
    mandatoryFees.forEach(fee => {
      total += fee.value;
    });
    if (checkoutState.pricing?.processing_fee > 0) {
      total += checkoutState.pricing.processing_fee;
    }
    return setFeeTotal(total);
  };

  useEffect(
    () => {
      renderFeeTotal();
    },
    [checkoutState]
  );

  return (
    <>
      <DisplayLineItem
        label={translate(`cx.global.taxes`)}
        value={formatCurrency(checkoutState.pricing.taxes, brand.currency)}
        collapseOnMobile={false}
        padding="xxs"
        labelIsRegularWeight
        removeBottomBorder
      />
      {mandatoryFees.length > 0 && (
        <>
          <DisplayLineItem
            label={
              <FlexBox alignItems="center" gap="xs">
                <Box>Fees</Box>
                <IconButton
                  onClick={() => setOpenFees(!openFees)}
                  size="dense"
                  appearance="ghost"
                  customButtonColor={brand.brand_info.colors?.color_primary}
                  customTextColor={brand.brand_info.colors?.color_primary_text}
                >
                  <IconFontAwesome
                    name={openFees ? 'chevronUp' : 'chevronDown'}
                  />
                </IconButton>
              </FlexBox>
            }
            value={formatCurrency(feeTotal, brand.currency)}
            collapseOnMobile={false}
            padding="xxs"
            labelIsRegularWeight
            removeBottomBorder
          />
          {openFees && (
            <Box paddingLeft="s" style={{ borderLeft: '2px solid gray' }}>
              {mandatoryFees
                .filter(fee => fee.value > 0)
                .map((fee, idx) => (
                  <DisplayLineItem
                    key={`mandatory-fee-${idx}`}
                    label={
                      <FlexBox gap="xs" alignItems="center">
                        {fee.description && (
                          <Box>
                            <Tooltip
                              wrapperClassName="addonFee__tooltip"
                              tipContent={
                                <TextDense>{fee.description}</TextDense>
                              }
                            >
                              <IconFontAwesome
                                name="questionCircle"
                                iconStyle="regular"
                              />
                            </Tooltip>
                          </Box>
                        )}
                        {`${fee.name} ${
                          fee.calculation_type === 'percent'
                            ? ` (${parseFloat(fee.calculation_amount).toFixed(
                                0
                              )}%)`
                            : ''
                        }`}
                      </FlexBox>
                    }
                    value={formatCurrency(fee.value, brand.currency)}
                    collapseOnMobile={false}
                    padding="xxs"
                    labelIsRegularWeight
                    removeBottomBorder
                  />
                ))}
              {checkoutState.pricing?.processing_fee > 0 && (
                <DisplayLineItem
                  label="Processing Fee"
                  value={formatCurrency(
                    checkoutState.pricing.processing_fee,
                    brand.currency
                  )}
                  collapseOnMobile={false}
                  padding="xxs"
                  labelIsRegularWeight
                  removeBottomBorder
                />
              )}
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default CheckoutPricingFees;
