import React from 'react';
import { useSelector } from 'react-redux';
import { selectUiState } from '../../redux/slices/ui';
import Footer from './footer';
import FooterV2 from './footer-v2';

const FooterSwitch = props => {
  const ui = useSelector(selectUiState);

  const renderFooter = () => {
    if (ui.cmsVersion === 'v2') {
      if (!ui.isBookingEngine) return <FooterV2 {...props} />;
      return null;
    }
    return <Footer {...props} />;
  };

  return renderFooter();
};

FooterSwitch.propTypes = {};

export default FooterSwitch;
