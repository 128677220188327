import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateUiSlice } from '../../redux/slices/ui';
import { bookingEngineRoutes } from '../../../shared/data';

const LayoutManager = props => {
  const dispatch = useDispatch();
  const location = useLocation();
  const brand = useSelector(state => state.brand);

  const determineLayout = () => {
    const pathName = location.pathname;
    let isBookingEngine = false;
    bookingEngineRoutes.forEach(route => {
      if (brand.cms_version === 1 && pathName.startsWith(route))
        isBookingEngine = true;
    });
    if (!isBookingEngine) document.body.classList.add('v2-modern');
    else document.body.classList.remove('v2-modern');
    dispatch(updateUiSlice({ isBookingEngine }));
  };

  useEffect(
    () => {
      determineLayout();
    },
    [location]
  );

  useEffect(() => {
    if (
      brand.brand_info.colors?.color_primary &&
      brand.brand_info.colors?.color_primary_text
    ) {
      document.documentElement.style.setProperty(
        '--primary-cta-color',
        brand.brand_info.colors?.color_primary
      );
      document.documentElement.style.setProperty(
        '--primary-cta-text-color',
        brand.brand_info.colors?.color_primary_text
      );
    }

    if (
      brand.brand_info.colors?.color_secondary &&
      brand.brand_info.colors?.color_secondary_text
    ) {
      document.documentElement.style.setProperty(
        '--secondary-cta-color',
        brand.brand_info.colors?.color_secondary
      );
      document.documentElement.style.setProperty(
        '--secondary-cta-text-color',
        brand.brand_info.colors?.color_secondary_text
      );
    }

    if (brand.brand_info.colors?.color_decorative_icon) {
      document.documentElement.style.setProperty(
        '--decorative-icon-color',
        brand.brand_info.colors?.color_decorative_icon
      );
    }
  }, []);

  return <>{props.children}</>;
};

export default LayoutManager;
