import React from 'react';
import {
  Box,
  FlexBox,
  IconFontAwesome,
  InputButtonSelect,
  TextDense
} from '@directsoftware/ui-kit-web-admin';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { selectListingsSearchState } from '../../../redux/slices/listingsSearch';
import { useDetectMobile } from '../../../../shared/hooks/useDetectMobile';
import ListingsSearchSort from '../atoms/listings-search-sort';
import { selectUiState, updateUiSlice } from '../../../redux/slices/ui';
import ListingsSearchFilterInstantBooking from '../atoms/listings-search-filter-instant-booking';

const ListingsSearchOptionsBar = ({ translate, updateSearchState }) => {
  const dispatch = useDispatch();
  const searchState = useSelector(selectListingsSearchState);
  const { mobileToggleSearchMapView } = useSelector(selectUiState);
  const { isMobile, isTouch } = useDetectMobile();

  return (
    <FlexBox
      className="option-bar"
      justifyContent={isMobile ? 'flex-start' : 'space-between'}
      alignItems="center"
      gap={isMobile ? 'xs' : 's'}
    >
      {isMobile && (
        <FlexBox alignItems="center" gap="xs" flex={isMobile ? '1' : null}>
          <ListingsSearchSort
            translate={translate}
            updateSearchState={updateSearchState}
          />
        </FlexBox>
      )}
      {!isMobile && (
        <FlexBox alignItems="center">
          {searchState.resultsLength ? (
            <TextDense weight="semibold" textColor="dark-gray">
              {`${searchState.totalProperties} Listings `}
            </TextDense>
          ) : (
            <Skeleton height={14} width={150} />
          )}
        </FlexBox>
      )}
      <FlexBox gap="xs" alignItems="center">
        {!isMobile && (
          <ListingsSearchFilterInstantBooking
            updateSearchState={updateSearchState}
          />
        )}
        {isTouch && (
          <Box>
            <InputButtonSelect removeBottomSpacer>
              <InputButtonSelect.Button
                isActive={!mobileToggleSearchMapView}
                onClick={() => {
                  dispatch(updateUiSlice({ mobileToggleSearchMapView: false }));
                }}
              >
                {isMobile ? <IconFontAwesome name="list" /> : 'List View'}
              </InputButtonSelect.Button>
              <InputButtonSelect.Button
                isActive={mobileToggleSearchMapView}
                onClick={() => {
                  dispatch(updateUiSlice({ mobileToggleSearchMapView: true }));
                }}
              >
                {isMobile ? <IconFontAwesome name="map" /> : 'Map View'}
              </InputButtonSelect.Button>
            </InputButtonSelect>
          </Box>
        )}
      </FlexBox>
    </FlexBox>
  );
};

export default ListingsSearchOptionsBar;
