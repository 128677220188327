import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  isStripeSuccessful: false,
  listing: {},
  transactionError: null,
  availability: {},
  booking: {},
  charges: [],
  customer: {},
  featured_image: {},
  location: {},
  property: {},
  property_manager: {},
  slug: '',
  unit: {},
  securityDeposit: {},
  securityDepositRequired: null,
  verified: null,
  loading: true,
  stripePublishableKey: ''
};

const receiptSlice = createSlice({
  name: 'receipt',
  initialState,
  reducers: {
    updateReceiptSlice: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetReceiptSlice: state => {
      return { ...state, ...initialState };
    }
  }
});

export const { updateReceiptSlice, resetReceiptSlice } = receiptSlice.actions;

export default receiptSlice.reducer;

export const selectReceiptState = createSelector(
  state => state.receipt,
  receipt => receipt
);
