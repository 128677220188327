import React, { useState } from 'react';
import { Box, InputSelect, TextDense } from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import { useDetectMobile } from '../../../../shared/hooks/useDetectMobile';

const ListingsSearchSort = ({ translate, updateSearchState }) => {
  const brand = useSelector(state => state.brand);
  const sortOptions = [
    {
      label: translate(`cx.search.sort.default`),
      value: 'default'
    },
    {
      label: translate(`cx.search.sort.name`),
      value: 'name'
    },
    {
      label: translate(`cx.search.sort.price_asc`),
      value: 'price_asc'
    },
    {
      label: translate(`cx.search.sort.price_desc`),
      value: 'price_desc'
    }
  ];
  if (brand.organization_id === 52) {
    sortOptions.splice(0, 1);
  }
  const { isMobile, isLargeTabletOrGreater } = useDetectMobile();
  const [activeSort, setActiveSort] = useState(sortOptions[0]);

  return (
    <>
      {!isMobile && (
        <Box paddingLeft="xs" className="searchFilterDivider">
          <TextDense weight="semibold">
            {isLargeTabletOrGreater ? 'Sort by:' : 'Sort:'}
          </TextDense>
        </Box>
      )}
      <Box style={isMobile ? { width: '100%' } : { minWidth: 160 }}>
        <InputSelect
          options={sortOptions}
          removeBottomSpacer
          isDense
          onChange={option => {
            setActiveSort(option);
            updateSearchState({ sort: option.value }, true);
          }}
          value={activeSort}
          inputWidth="auto"
        />
      </Box>
    </>
  );
};

export default ListingsSearchSort;
