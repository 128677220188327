import React, { useEffect, useRef, useState } from 'react';
import Script from 'react-load-script';
import {
  Box,
  FlexBox,
  IconFontAwesome,
  Panel,
  TextDense
} from '@directsoftware/ui-kit-web-admin';
import { useDispatch, useSelector } from 'react-redux';
import {
  getListingImageUrl,
  getStringifiedQueryString
} from '../resources/shared-functions';
import {
  selectListingsSearchState,
  updateListingsSearchSlice
} from '../../../redux/slices/listingsSearch';
import SearchThisArea from '../atoms/search-this-area';
import useClickOutsideListener from '../../../../shared/hooks/useOutsideClickListener';
import SearchMapV2 from '../searchComponents/search-map-v2';

const ListingsSearchMap = ({ translate, updateSearchState }) => {
  const { searchInProgress } = useSelector(state => state.ui);
  const searchState = useSelector(selectListingsSearchState);
  const dispatch = useDispatch();
  const brand = useSelector(state => state.brand);
  const mapMarkerRef = useRef(null);
  const { isOutsideClick: controlOutsideClick } = useClickOutsideListener(
    mapMarkerRef
  );
  const [searchThisArea, setSearchThisArea] = useState(false);
  const [mapValues, setMapValues] = useState({});
  const [mapInitialChange, setMapInitialChange] = useState(true);

  const updateBounds = (geoNELat, geoNELon, geoSWLat, geoSWLon) => {
    updateSearchState({
      geoNELat,
      geoNELon,
      geoSWLat,
      geoSWLon
    });
  };

  const updateZoom = zoom => {
    updateSearchState({ zoom }, true);
  };

  useEffect(
    () => {
      if (searchState.showMobileMarker && controlOutsideClick) {
        dispatch(
          updateListingsSearchSlice({
            showMobileMarker: false,
            mobileMarkerData: null
          })
        );
      }
    },
    [searchState.showMobileMarker, controlOutsideClick]
  );

  useEffect(
    () => {
      if (!searchInProgress && searchThisArea) setSearchThisArea(false);
    },
    [searchInProgress]
  );

  return (
    <>
      <Box style={{ height: '100%' }}>
        <Box style={{ height: '100%' }} setPositionRelative>
          {searchThisArea ? (
            <SearchThisArea
              useSpinner={searchInProgress}
              toggleSearchThisArea={() => {
                if (mapValues.bounds) {
                  updateBounds(
                    mapValues.bounds.ne.lat,
                    mapValues.bounds.ne.lng,
                    mapValues.bounds.sw.lat,
                    mapValues.bounds.sw.lng
                  );
                }
                if (mapValues.zoom) {
                  updateZoom(mapValues.zoom);
                }
              }}
            />
          ) : null}
          <SearchMapV2
            filteredResults={searchState.results}
            geoNELat={searchState.geoNELat}
            geoNELon={searchState.geoNELon}
            geoSWLat={searchState.geoSWLat}
            geoSWLon={searchState.geoSWLon}
            geoCenterLat={searchState.geoCenterLat}
            geoCenterLon={searchState.geoCenterLon}
            zoom={searchState.zoom}
            updateBounds={updateBounds}
            updateZoom={updateZoom}
            isLoading={searchState.isLoading}
            isLoaded={searchState.isLoaded}
            isDirty={searchState.isDirty}
            getStringifiedQueryString={() =>
              getStringifiedQueryString(searchState)
            }
            searchThisArea={searchThisArea}
            toggleSearchThisArea={(showButton, vals) => {
              if (mapInitialChange) {
                setMapInitialChange(false);
              } else {
                setSearchThisArea(showButton);
                setMapValues(vals);
              }
            }}
            searchWithMap={searchState.searchWithMap}
            translate={translate}
          />
          {searchState.showMobileMarker && searchState.mobileMarkerData && (
            <Panel addShadow className="mobileMapMarker">
              <FlexBox
                alignItems="center"
                setPositionRelative
                domRef={mapMarkerRef}
              >
                <a
                  href={`/listings/${searchState.mobileMarkerData.slug +
                    getStringifiedQueryString(searchState)}`}
                  target="_blank"
                  className="searchGridItem__link"
                  rel="noreferrer"
                />
                <Box
                  className="searchGridItem__imageWrapper -mobileMarker"
                  setPositionRelative
                >
                  {!getListingImageUrl(searchState.mobileMarkerData) ? (
                    <Box className="searchGridItem__noImage">
                      <IconFontAwesome name="image" />
                    </Box>
                  ) : (
                    <img
                      className="searchGridItem__image"
                      alt=""
                      src={getListingImageUrl(searchState.mobileMarkerData)}
                    />
                  )}
                </Box>
                <Box padding="s">
                  <TextDense
                    textColor="dark-gray"
                    weight="semibold"
                    isFullWidth
                  >
                    {searchState.mobileMarkerData.property.name}
                  </TextDense>
                  <TextDense>
                    {`From: ${translate(
                      `global.parsers.currency.${brand.currency}`,
                      {
                        value: Math.round(
                          searchState.mobileMarkerData.bookable_nightly_price
                        )
                      }
                    )} / night`}
                  </TextDense>
                </Box>
              </FlexBox>
            </Panel>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ListingsSearchMap;
