import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactI18n from 'react-i18n';
import {
  Box,
  DisplayLineItem,
  FlexBox,
  IconButton,
  IconFontAwesome,
  Spacer,
  TextBody
} from '@directsoftware/ui-kit-web-admin';
import moment from 'moment/moment';
import { formatCurrency } from '../../../shared/helpers';

const RenderDiscount = (discount, translate, currency) => {
  return (
    <DisplayLineItem
      label={translate(`global.parsers.discount.${discount[0]}`, {
        value: discount[1]
      })}
      value={`- ${formatCurrency(discount[2], currency)}`}
      labelIsRegularWeight
      collapseOnMobile={false}
      removeBottomBorder
      padding="xxs"
    />
  );
};

const RenderFeeLineItem = (fee, translate, currency) => {
  if (fee.additional_data.included_in_base_rent) {
    return null;
  } else {
    return (
      <DisplayLineItem
        label={fee.name}
        value={formatCurrency(fee.total_cents / 100, currency)}
        labelIsRegularWeight
        collapseOnMobile={false}
        removeBottomBorder
        padding="xxs"
      />
    );
  }
};

const RenderLineItems = (pricing, nights, currency, translate) => {
  return (
    <>
      <Box paddingLeft="s" className="feesTable">
        {pricing.fees.map(
          fee =>
            fee.total_cents > 0 && RenderFeeLineItem(fee, translate, currency)
        )}
      </Box>
      <Spacer size="xs" />
    </>
  );
};

const RenderCharge = (charge, refunds, translate, currency, displayFormat) => {
  const amount_charged = parseFloat(charge.amount_charged).toFixed(2);
  if (
    charge.status === 'charged' ||
    (charge.status === 'partially_refunded' && refunds?.length > 0)
  ) {
    return (
      <DisplayLineItem
        label={moment(charge.created_at).format(
          displayFormat === 'DD/MM/YYYY' ? 'D MMMM, YYYY' : 'MMMM D, YYYY'
        )}
        description={
          charge.charge_type.includes('card')
            ? translate(`cx.receipt.cc_info`, {
                brand: charge.cc_brand,
                last_4: charge.cc_last_4
              })
            : translate(`cx.receipt.payment_info`, {
                payment_method: charge.charge_type
              })
        }
        value={`- ${formatCurrency(amount_charged, currency)}`}
        valueClassName="success-green-text"
        labelIsRegularWeight
        collapseOnMobile={false}
        removeBottomBorder
        padding="xxs"
      />
    );
  } else {
    return null;
  }
};

const RenderRefund = (refunds, currency) => {
  if (refunds?.length > 0) {
    return refunds.map(refund => (
      <DisplayLineItem
        label="Refund"
        value={`- ${formatCurrency(refund, currency)}`}
        valueClassName="success-green-text"
        labelIsRegularWeight
        collapseOnMobile={false}
        removeBottomBorder
        padding="xxs"
      />
    ));
  } else {
    return null;
  }
};

const PaymentBreakdown = ({ stateObject }) => {
  const brand = useSelector(state => state.brand);
  const translate = ReactI18n.getIntlMessage;
  const [openFees, setOpenFees] = useState(false);
  const [totalFees, setTotalFees] = useState(0);

  const total = (
    Math.floor(parseFloat(stateObject.booking.price_total) * 100) / 100
  ).toFixed(2);
  const paid = (
    Math.floor(parseFloat(stateObject.booking.price_paid) * 100) / 100
  ).toFixed(2);
  let remaining = (total - paid).toFixed(2);
  if (remaining < 0) {
    remaining = (0).toFixed(2);
  }

  useEffect(() => {
    let feeTotal = 0;
    if (stateObject.pricing && stateObject.pricing.fees) {
      stateObject.pricing.fees.forEach(fee => {
        if (!fee.additional_data.included_in_base_rent)
          feeTotal += fee.total_cents;
      });
    }
    if (feeTotal > 0) {
      setTotalFees(feeTotal / 100);
    }
  }, []);

  return (
    <>
      <TextBody textColor="dark-gray" weight="semibold">
        {translate(`cx.global.payment_info`)}
      </TextBody>
      <Spacer size="xs" />
      {stateObject.pricing && (
        <>
          <DisplayLineItem
            label={`${formatCurrency(
              stateObject.pricing.average_nightly_price,
              brand.currency
            )} * ${translate(
              `global.parsers.num_nights.${
                stateObject.nights > 1 ? 'plural' : 'single'
              }`,
              { nights: stateObject.nights }
            )}`}
            value={formatCurrency(
              stateObject.pricing.room_rate,
              brand.currency
            )}
            labelIsRegularWeight
            collapseOnMobile={false}
            removeBottomBorder
            padding="xxs"
          />
          {stateObject.pricing.discount[0] &&
            stateObject.pricing.discount[1] > 0 &&
            RenderDiscount(
              stateObject.pricing.discount,
              translate,
              brand.currency
            )}
          {stateObject.pricing.taxes > 0 && (
            <DisplayLineItem
              label={translate(`cx.global.taxes`)}
              value={formatCurrency(stateObject.pricing.taxes, brand.currency)}
              labelIsRegularWeight
              collapseOnMobile={false}
              removeBottomBorder
              padding="xxs"
            />
          )}
          {totalFees > 0 && (
            <DisplayLineItem
              label={
                <FlexBox alignItems="center" gap="xs">
                  <Box>Fees</Box>
                  <IconButton
                    onClick={() => setOpenFees(!openFees)}
                    size="dense"
                    appearance="ghost"
                    className="feesTable__button"
                  >
                    <IconFontAwesome
                      name={openFees ? 'chevronUp' : 'chevronDown'}
                    />
                  </IconButton>
                </FlexBox>
              }
              value={formatCurrency(totalFees, brand.currency)}
              collapseOnMobile={false}
              padding="xxs"
              labelIsRegularWeight
              removeBottomBorder
            />
          )}
          {openFees &&
            RenderLineItems(
              stateObject.pricing,
              stateObject.nights,
              brand.currency,
              translate
            )}
          <Spacer size="xxs" />
        </>
      )}
      <FlexBox justifyContent="space-between">
        <TextBody textColor="dark-gray" weight="semibold">
          {translate(`cx.global.total`)}
        </TextBody>
        <TextBody textColor="dark-gray" weight="semibold">
          {formatCurrency(total, brand.currency)}
        </TextBody>
      </FlexBox>
      {stateObject.charges && stateObject.charges.length > 0 && (
        <>
          <Spacer size="l" />
          <TextBody textColor="dark-gray" weight="semibold">
            Charges
          </TextBody>
          <Spacer size="xs" />
          {stateObject.charges.map(charge =>
            RenderCharge(
              charge,
              stateObject.refunds,
              translate,
              brand.currency,
              brand.date_format
            )
          )}
        </>
      )}
      {stateObject.refunds && stateObject.refunds.length.length > 0 && (
        <>
          <Spacer size="l" />
          <TextBody textColor="dark-gray" weight="semibold">
            Refunds
          </TextBody>
          <Spacer size="xs" />
          {RenderRefund(stateObject.refunds, brand.currency, translate)}
        </>
      )}
    </>
  );
};

export default PaymentBreakdown;
