import React from 'react';
import {
  Box,
  CallToActionButton,
  IconFontAwesome,
  Panel
} from '@directsoftware/ui-kit-web-admin';

const SearchThisArea = ({ toggleSearchThisArea, useSpinner }) => {
  return (
    <Panel addShadow className="searchThisArea">
      {useSpinner ? (
        <Box paddingHorizontal="s" paddingVertical="xxs">
          <IconFontAwesome name="spinner" spin />
        </Box>
      ) : (
        <CallToActionButton
          onClick={toggleSearchThisArea}
          variation="secondary"
          appearance="ghost"
          size="dense"
        >
          Search This Area
        </CallToActionButton>
      )}
    </Panel>
  );
};

export default SearchThisArea;
