import React, { useEffect, useState } from 'react';
import { Box, Divider, FlexBox } from '@directsoftware/ui-kit-web-admin';
import ReactI18n from 'react-i18n';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { isNull } from 'lodash';
import { Intercom } from '../miscellaneous';
import Meta from './meta';
import {
  resetListingsSearchSlice,
  selectListingsSearchState,
  updateListingsSearchSlice
} from '../../redux/slices/listingsSearch';
import {
  parseQuery,
  getAmenitiesFromParams,
  vehicleAmenitiesFromParams,
  createQueryParams,
  updateQueryString,
  calculateMaxes
} from './resources/shared-functions';
import ListingsSearchFilterBar from './molecules/listings-search-filter-bar';
import ListingsSearchOptionsBar from './molecules/listings-search-options-bar';
import ListingsSearchMap from './molecules/listings-search-map';
import { useDetectMobile } from '../../../shared/hooks/useDetectMobile';
import ListingsSearchGrid from './molecules/listings-search-grid';
import { selectUiState, updateUiSlice } from '../../redux/slices/ui';

const SearchV2 = props => {
  const dispatch = useDispatch();
  const { isTouch, height } = useDetectMobile();
  const brand = useSelector(state => state.brand);
  const searchState = useSelector(selectListingsSearchState);
  const { searchFiltersChanged, mobileToggleSearchMapView } = useSelector(
    selectUiState
  );
  const [initialMount, setInitialMount] = useState(true);
  const [cleanUpFetchPropertyData, setCleanUpFetchPropertyData] = useState(
    false
  );
  const translate = ReactI18n.getIntlMessage;

  const listingType = () => (brand.isRvFleet ? 'vehicles' : 'properties');

  const updateSearchState = (data, isFilterChange = false) => {
    dispatch(updateListingsSearchSlice(data));
    if (isFilterChange)
      dispatch(
        updateUiSlice({ searchFiltersChanged: true, searchInProgress: true })
      );
  };

  const fetchPropertyData = () => {
    const queryParams = createQueryParams(searchState, brand);
    axios
      .get(`${process.env.DIRECT_URL}/api/v2/${listingType()}${queryParams}`, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then(response => {
        const data = response.data;
        updateSearchState({
          results: data.results,
          resultsLength: data.results.length,
          isLoading: false,
          isDirty: false,
          isLoaded: true,
          geoNELat: data.bounds ? data.bounds.ne.lat : null,
          geoNELon: data.bounds ? data.bounds.ne.lng : null,
          geoSWLat: data.bounds ? data.bounds.sw.lat : null,
          geoSWLon: data.bounds ? data.bounds.sw.lng : null,
          geoCenterLat: data.center ? data.center[0] : null,
          geoCenterLon: data.center ? data.center[1] : null,
          max_price: data.max_price || null,
          min_price: data.min_price || 0,
          maxBaths: data.max_baths,
          maxBedrooms: data.max_bedrooms,
          maxGuests: data.max_guests,
          totalPages: data.total_pages,
          totalProperties: brand.isRvFleet
            ? data.total_vehicles
            : data.total_properties,
          datesSet: !(
            data.results[0] && data.results[0].search_type === 'dateless'
          )
        });
        dispatch(
          updateUiSlice({ searchInProgress: false, allowSearchSpinner: true })
        );
        setCleanUpFetchPropertyData(true);
      });
  };

  const handleBrowserState = () => {
    const queryInfo = parseQuery(location.search, brand);
    updateSearchState({
      bookingRange: queryInfo.bookingRange || null,
      checkIn: queryInfo.checkIn || null,
      checkOut: queryInfo.checkOut || null,
      geoNELat: queryInfo.geoNELat || null,
      geoNELon: queryInfo.geoNELon || null,
      geoSWLat: queryInfo.geoSWLat || null,
      geoSWLon: queryInfo.geoSWLon || null,
      guests: queryInfo.guests || 1,
      loc: queryInfo.loc || null,
      sort: queryInfo.sort || null,
      zoom: queryInfo.zoom || 13,
      distance: queryInfo.distance || null,
      selectedAmenities: queryInfo.selectedAmenities || [],
      amenities: brand.isRvFleet
        ? vehicleAmenitiesFromParams(queryInfo.selectedAmenities)
        : getAmenitiesFromParams(queryInfo.selectedAmenities),
      eventSearch: queryInfo.eventSearch,
      isDirty: true,
      initialBrowserQuery: false
    });
  };

  useEffect(
    () => {
      if (initialMount) {
        if (!searchState.initialBrowserQuery) {
          if (initialMount) setInitialMount(false);
          fetchPropertyData();
        }
      } else if (searchFiltersChanged) {
        dispatch(updateUiSlice({ searchFiltersChanged: false }));
        updateQueryString(searchState);
        fetchPropertyData();
      }
    },
    [searchState, searchFiltersChanged, initialMount]
  );

  useEffect(
    () => {
      if (cleanUpFetchPropertyData) {
        // this._div.scrollTop = 0;
        if (
          isNull(searchState.maxBaths) ||
          isNull(searchState.maxBedrooms) ||
          isNull(searchState.maxGuests)
        ) {
          calculateMaxes(searchState, updateSearchState);
        }
        setCleanUpFetchPropertyData(false);
      }
    },
    [cleanUpFetchPropertyData]
  );

  useEffect(() => {
    handleBrowserState();
    window.onpopstate = handleBrowserState;
    Intercom(props.intercom_id);

    return () => {
      dispatch(resetListingsSearchSlice());
      dispatch(updateUiSlice({ searchFiltersChanged: false }));
    };
  }, []);

  return (
    <>
      <Meta />
      <ListingsSearchFilterBar
        updateSearchState={updateSearchState}
        translate={translate}
      />
      <Divider />
      <FlexBox className="searchMapGridLayout">
        <Box
          className="searchMapGridLayout__left"
          style={{ height: height - 114 }}
        >
          <Box
            className="searchMapGridLayout__grid"
            setPositionRelative
            style={{ height: height - 114 }}
          >
            <ListingsSearchOptionsBar
              translate={translate}
              updateSearchState={updateSearchState}
            />
            {isTouch ? (
              <>
                {mobileToggleSearchMapView ? (
                  <Box
                    className="searchMapGridLayout__mobileMap"
                    style={{ height: height - 170 }}
                  >
                    <ListingsSearchMap
                      updateSearchState={updateSearchState}
                      translate={translate}
                    />
                  </Box>
                ) : (
                  <ListingsSearchGrid
                    updateSearchState={updateSearchState}
                    translate={translate}
                  />
                )}
              </>
            ) : (
              <ListingsSearchGrid
                updateSearchState={updateSearchState}
                translate={translate}
              />
            )}
          </Box>
        </Box>
        {!isTouch && (
          <Box className="searchMapGridLayout__right">
            <ListingsSearchMap
              updateSearchState={updateSearchState}
              translate={translate}
            />
          </Box>
        )}
      </FlexBox>
    </>
  );
};

export default SearchV2;
