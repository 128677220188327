import React from 'react';
import {
  Box,
  DisplayLineItem,
  Divider,
  FlexBox,
  Spacer,
  TextBody,
  TextH2
} from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import ReactI18n from 'react-i18n';
import moment from 'moment/moment';
import get from 'lodash/get';
import { selectVerificationState } from '../../../redux/slices/verification';
import { formatCurrency } from '../../../../shared/helpers';

const VerificationPricingInfo = () => {
  const verificationState = useSelector(selectVerificationState);
  const brand = useSelector(state => state.brand);
  const translate = ReactI18n.getIntlMessage;

  const renderCharge = charge => {
    const amount_charged = parseFloat(charge.amount_charged).toFixed(2);

    if (charge.status === 'charged') {
      return (
        <DisplayLineItem
          key={`charge-${charge.id}`}
          label={`Payment: ${moment(charge.created_at).format(
            get(this, 'props.displayFormat') === 'DD/MM/YYYY'
              ? 'DD MMM, YYYY'
              : 'MMM DD, YYYY'
          )}`}
          description={
            charge.charge_type.includes('card')
              ? translate(`cx.receipt.cc_info`, {
                  brand: charge.cc_brand,
                  last_4: charge.cc_last_4
                })
              : translate(`cx.receipt.payment_info`, {
                  payment_method: charge.charge_type
                })
          }
          value={`- ${formatCurrency(amount_charged, charge.currency)}`}
          removeBottomBorder
        />
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Box paddingHorizontal="s" paddingTop="s">
        <FlexBox>
          <Box>
            <TextBody weight="semibold" textColor="dark-gray">
              {translate(`cx.global.total`)}
            </TextBody>
          </Box>
          <FlexBox flex="1" justifyContent="flex-end">
            <TextBody weight="semibold" textColor="dark-gray">
              {formatCurrency(
                verificationState.booking.price_total,
                brand.currency
              )}
            </TextBody>
          </FlexBox>
        </FlexBox>
        {verificationState.charges.length > 0 ? (
          <>{verificationState.charges.map(charge => renderCharge(charge))}</>
        ) : (
          <Spacer />
        )}
      </Box>
      <Divider />
      <Box padding="s">
        <FlexBox>
          <Box>
            <TextH2>{translate(`cx.global.remaining`)}</TextH2>
          </Box>
          <FlexBox flex="1" justifyContent="flex-end">
            <TextH2>
              {formatCurrency(
                verificationState.booking.price_total -
                  verificationState.booking.price_paid,
                brand.currency
              )}
            </TextH2>
          </FlexBox>
        </FlexBox>
      </Box>
    </>
  );
};

export default VerificationPricingInfo;
