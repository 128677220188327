import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  CallToActionButton,
  Spacer,
  StandByOverlay,
  TextCaption
} from '@directsoftware/ui-kit-web-admin';
import axios from 'axios';
import { toast } from 'react-toastify';
import { selectVerificationState } from '../../../redux/slices/verification';

const VerificationSubmit = () => {
  const verificationState = useSelector(selectVerificationState);
  const [showOverlay, setShowOverlay] = useState(false);

  const hasValidSignature = () => {
    if (verificationState.verify_signature) {
      return verificationState.signature !== null;
    }
    return true;
  };

  const hasValidIdPhoto = () => {
    if (verificationState.verify_id) {
      return verificationState.idPhoto !== null;
    }
    return true;
  };

  const hasValidTerms = () => {
    const terms = verificationState.contractTermsAndConditions;

    if (!terms) {
      return true;
    }

    if (
      terms.replace(/<(?:.|\n)*?>/gm, '').replace(/(\r\n|\n|\r)/gm, '') !== ''
    ) {
      return verificationState.terms_accepted;
    }

    return true;
  };

  const hasValidAge = () => {
    if (verificationState.verify_age) {
      return verificationState.age_verified;
    }

    return true;
  };

  const hasValidAddress = () => {
    if (verificationState.verify_address) {
      return verificationState.address_verified;
    }

    return true;
  };

  const hasInvalidFields = () => {
    return !(
      hasValidSignature() &&
      hasValidIdPhoto() &&
      hasValidAge() &&
      hasValidAddress() &&
      hasValidTerms()
    );
  };

  const postData = () => {
    const data = { booking_id: verificationState.booking.id };
    if (verificationState.signature) {
      data.signature_id = verificationState.signature.id;
    }

    if (verificationState.idPhoto) {
      data.id_photo_id = verificationState.idPhoto.id;
    }

    if (verificationState.address_verified) {
      data.location = {
        adr_street: verificationState.adrStreet,
        adr_unit: verificationState.adrUnit,
        adr_city: verificationState.adrCity,
        adr_state: verificationState.adrState,
        adr_country: verificationState.adrCountry,
        adr_postal_code: verificationState.adrPostalCode
      };
    }

    if (verificationState.age_verified) {
      data.age_verified = verificationState.age_verified;
    }

    return data;
  };

  const submitVerification = () => {
    setShowOverlay(true);
    axios
      .post(
        `${process.env.DIRECT_URL}/api/v2/my-bookings/verify/${
          verificationState.customer.id
        }`,
        postData()
      )
      .then(response => {
        setShowOverlay(false);
        window.location = `/my-bookings/receipt/${
          verificationState.booking.booking_code
        }?verified=true`;
      })
      .catch(error => {
        toast.error(error);
        console.log(error);
        setShowOverlay(false);
      });
  };

  return (
    <>
      <StandByOverlay
        reveal={showOverlay}
        position="fixed"
        headline="Verifying your info..."
      />
      <CallToActionButton
        onClick={() => submitVerification()}
        isFullWidth
        size="large"
        isDisabled={hasInvalidFields() || showOverlay}
      >
        Submit Verification
      </CallToActionButton>
      <Spacer size="m" />
      <TextCaption>
        By submitting the form above, you agree to this listing’s
        {` `}
        <a href="/">terms and conditions</a>
      </TextCaption>
      <Spacer />
      <TextCaption>
        By submitting the form above, you agree to abide by the terms and
        conditions put forth in the
        {` `}
        <a href="/">rental agreement</a>
      </TextCaption>
    </>
  );
};

export default VerificationSubmit;
