import React, { Component } from 'react';
import ReactI18n from 'react-i18n';
import styled from 'styled-components';
import Indicator from '../toggle/indicator';
import ErrorsPaymentCustomer from '../errors/payment-customer';

// Styles
// -----------------------------------------------
const ErrorLabel = styled.span`
  color: red;
  font-size: 12px;
  line-height: 24px;
`;

const FormGroup = styled.fieldset`
  display: block;
  width: 100%;

  &.width-25 {
    width: 23%;
  }

  &.width-50 {
    width: 48%;
  }

  &.width-75 {
    width: 73%;
  }
`;
class BillingInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggleFill: false
    };
  }

  render() {
    const translate = ReactI18n.getIntlMessage;

    return (
      <section className="fields-customer">
        <header>Billing Information</header>
        {this.props.chargeAmount === undefined && (
          <Indicator
            toggleStatus={this.state.toggleFill}
            toggleFalseLabel="Fill from Contact Info"
            toggleTrueLabel="Fill from Contact Info"
            toggleAction={
              this.state.toggleFill
                ? () => {
                    this.setState({ toggleFill: false });
                    this.props.updateState({
                      customerEmail: '',
                      customerName: '',
                      customerPostalCode: '',
                      customerTelephone: '',
                      customerEmailValid: false,
                      customerNameValid: false,
                      customerPostalCodeValid: false,
                      customerTelephoneValid: false,
                      customerEmailError: 'empty',
                      customerNameError: 'empty',
                      customerPostalCodeError: 'empty',
                      customerTelephoneError: 'empty'
                    });
                  }
                : () => {
                    this.setState({ toggleFill: true });
                    this.props.fillFromContact();
                  }
            }
          />
        )}
        <ErrorsPaymentCustomer
          errors={[
            { param: 'customerName', code: this.props.customerNameError },
            { param: 'customerEmail', code: this.props.customerEmailError },
            {
              param: 'customerTelephone',
              code: this.props.customerTelephoneError
            },
            {
              param: 'customerPostalCode',
              code: this.props.customerPostalCodeError
            },
            { param: 'guests', code: this.props.guestsError }
          ]}
          translate={translate}
        />
        <figure className="field-customer-name">
          <label htmlFor="customerName">
            <span>Full name</span>
          </label>
          <input
            autoComplete="name"
            type="text"
            name="customerName"
            onChange={this.props.onChange}
            onBlur={this.props.onBlur}
            placeholder="Jane Smith"
            value={this.props.customerName}
            className={this.props.buildFieldStatus('customerName')}
            required
          />
          <ErrorLabel>{this.props.errors.name}</ErrorLabel>
        </figure>

        <FormGroup className="width-50">
          <label htmlFor="customerEmail">
            <span>Email</span>
          </label>
          <input
            autoComplete="email"
            type="email"
            name="customerEmail"
            onChange={this.props.onChange}
            onBlur={this.props.onBlur}
            placeholder="name@email.com"
            value={this.props.customerEmail}
            className={this.props.buildFieldStatus('customerEmail')}
            required
          />
          <ErrorLabel>{this.props.errors.email}</ErrorLabel>
        </FormGroup>
        <FormGroup className="width-50">
          <label htmlFor="customerTelephone">
            <span>Phone</span>
          </label>
          <input
            autoComplete="tel"
            type="tel"
            name="customerTelephone"
            onChange={this.props.onChange}
            onBlur={this.props.onBlur}
            placeholder="+1 (123) 123-1234"
            maxLength={20}
            value={this.props.customerTelephone}
            className={this.props.buildFieldStatus('customerTelephone')}
            required
          />
          <ErrorLabel>{this.props.errors.phone}</ErrorLabel>
        </FormGroup>
        <FormGroup>
          <label htmlFor="customerPostalCode">
            <span>Postal code</span>
          </label>
          <input
            autoComplete="postal-code"
            type="text"
            name="customerPostalCode"
            onChange={this.props.onChange}
            onBlur={this.props.onBlur}
            placeholder="12345"
            maxLength={15}
            value={this.props.customerPostalCode}
            className={this.props.buildFieldStatus('customerPostalCode')}
            required
          />
          <ErrorLabel>{this.props.errors.postal}</ErrorLabel>
        </FormGroup>
        {this.props.chargeAmount === undefined ||
          (this.props.chargeAmount === '' && (
            <figure className="field-customer-guests select-wrapper">
              <label htmlFor="guests">
                <span>Number of Guests</span>
              </label>
              <select
                className={this.props.buildFieldStatus('guests')}
                name="guests"
                onBlur={this.props.onBlur}
                onChange={this.props.onChange}
                value={this.props.guests}
                required
              >
                {this.props.guestsArray.map(guest => (
                  <option value={guest} key={guest}>
                    {translate(
                      `global.parsers.num_guests.${
                        guest > 1 ? 'plural' : 'single'
                      }`,
                      { num: guest }
                    )}
                  </option>
                ))}
              </select>
            </figure>
          ))}
      </section>
    );
  }
}

BillingInformation.propTypes = {};

export default BillingInformation;
