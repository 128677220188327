import React from 'react';
import {
  FlexBoxGrid,
  Spacer,
  TextBody
} from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import ReactI18n from 'react-i18n';
import ListingsDetailsContentBlock from './listings-details-content-block';
import IconCardItem from './icon-card-item';
import Crib from '../../../../shared/reactSvg/crib';

const BedroomsList = () => {
  const listing = useSelector(state => state.listing);
  const translate = ReactI18n.getIntlMessage;

  const renderAmenity = (bedroom, amenityKey, getIcons = false) => {
    const val = bedroom.amenities[amenityKey].value;

    if (val) {
      if (getIcons) {
        if (amenityKey.includes('CRIB'))
          return { name: <Crib />, useOldIcon: true };
        if (amenityKey.includes('BUNK')) return { name: 'bedBunk' };
        return { name: 'bed' };
      } else {
        const amenityName = translate(
          `global.bedroom_amenities.${amenityKey}`,
          {
            s: val === 1 ? '' : 's'
          }
        );
        return `${val} ${amenityName}`;
      }
    }

    return null;
  };

  return (
    <ListingsDetailsContentBlock
      label={translate(`cx.global.amenities.bedroom_info`)}
    >
      <TextBody>
        {listing.unit.num_bedrooms ? (
          <>
            {translate(`cx.details.summary.num_bedrooms`, {
              num: listing.unit.num_bedrooms,
              s: listing.unit.num_bedrooms === 1 ? '' : 's'
            })}{' '}
          </>
        ) : null}
        {listing.unit.num_sleep_in_beds ? (
          <>
            {' '}
            |{' '}
            {translate(`cx.details.summary.num_sleep_in_beds`, {
              num: listing.unit.num_sleep_in_beds,
              s: listing.unit.num_sleep_in_beds === 1 ? '' : 's'
            })}
          </>
        ) : null}
      </TextBody>
      <Spacer size="m" />
      <FlexBoxGrid columns={2} gap="m">
        {listing.bedrooms.map((bedroom, idx) => {
          const bedroomList = [];
          const iconSet = [];
          Object.keys(bedroom.amenities).forEach(amenityKey => {
            const bedroomLabel = renderAmenity(bedroom, amenityKey);
            const icon = renderAmenity(bedroom, amenityKey, true);
            if (bedroomLabel) bedroomList.push(bedroomLabel);
            if (icon) iconSet.push(icon);
          });

          return (
            <IconCardItem
              key={`beds-${idx}`}
              iconNames={iconSet.length > 0 ? iconSet : ['bed']}
              headline={
                bedroom.name
                  ? bedroom.name
                  : translate(`global.bedroom_type.${bedroom.bedroom_type}`)
              }
              listItems={bedroomList}
              description={bedroom.description}
            />
          );
        })}
      </FlexBoxGrid>
    </ListingsDetailsContentBlock>
  );
};

export default BedroomsList;
