import React, { useEffect, useState } from 'react';
import {
  Box,
  FlexBox,
  FlexBoxGrid,
  InputDateRange,
  InputIncrementer,
  Modal,
  PageStateMessage,
  Spacer,
  TextDense
} from '@directsoftware/ui-kit-web-admin';
import { useDispatch, useSelector } from 'react-redux';
import { isInclusivelyBeforeDay } from 'react-dates';
import { isEmpty, debounce } from 'lodash';
import ListingsDetailsContentBlock from '../shared-v2/listings-details-content-block';
import { useDetectMobile } from '../../../../shared/hooks/useDetectMobile';
import {
  selectListingsDetailsMultiState,
  updateListingsDetailsMultiSlice
} from '../../../redux/slices/listingsDetailsMulti';
import UnitTypeItem from './unit-type-item';
import ChildUnitModalContent from './child-unit-modal-content';
import { updateUiSlice } from '../../../redux/slices/ui';

const UnitTypes = ({ updateDetailsMultiState }) => {
  const { isMobile } = useDetectMobile();
  const dispatch = useDispatch();
  const listing = useSelector(state => state.listing);
  const listingsDetailsState = useSelector(selectListingsDetailsMultiState);
  const { openChildUnitModal } = useSelector(state => state.ui);
  const [focusedInput, setFocusedInput] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [initialQueryCheck, setInitialQueryCheck] = useState(true);
  const [units, setUnits] = useState([]);

  const updateDates = (checkIn, checkOut) => {
    if (isInclusivelyBeforeDay(checkIn, checkOut)) {
      const bookingRange = [];
      const d = checkIn.clone();

      while (isInclusivelyBeforeDay(d, checkOut)) {
        bookingRange.push({
          key: d.format('DD-MM-YYYY'),
          day: d.day()
        });
        d.add(1, 'days');
      }

      updateDetailsMultiState(
        {
          bookingRange,
          bookingLength: bookingRange.length - 1,
          datesParsed: true,
          checkIn,
          checkOut
        },
        true
      );
    }
  };

  const updateGuests = guests => {
    debounce(
      updateDetailsMultiState(
        {
          guests
        },
        true
      ),
      500
    );
  };

  const getMaxGuests = () => {
    const tempArray = [...listing?.units].sort((a, b) =>
      a.unit.num_sleep > b.unit.num_sleep
        ? -1
        : b.unit.num_sleep > a.unit.num_sleep
        ? 1
        : 0
    );
    return tempArray.length > 0 ? tempArray[0].unit.num_sleep : 1;
  };

  useEffect(
    () => {
      if (
        !isEmpty(listingsDetailsState.availabilities) &&
        !isEmpty(listingsDetailsState.pricings)
      ) {
        setUnits(
          listing.units.filter(unit => {
            return unit.unit.num_sleep >= parseInt(listingsDetailsState.guests);
          })
        );
      } else {
        setUnits([]);
      }
    },
    [listingsDetailsState.availabilities, listingsDetailsState.pricings]
  );

  useEffect(
    () => {
      if (
        listingsDetailsState.checkIn &&
        listingsDetailsState.checkOut &&
        initialQueryCheck
      ) {
        if (listingsDetailsState.checkIn)
          setStartDate(listingsDetailsState.checkIn);
        if (listingsDetailsState.checkOut)
          setEndDate(listingsDetailsState.checkOut);
        setInitialQueryCheck(false);
      }
    },
    [listingsDetailsState.checkIn, listingsDetailsState.checkOut]
  );

  return (
    <ListingsDetailsContentBlock label="Unit Types">
      {listingsDetailsState.datesParsed && (
        <>
          <FlexBox alignItems="center" gap="s">
            {!isMobile && (
              <TextDense textColor="dark-gray" weight="semibold">
                Check Availability
              </TextDense>
            )}
            <Box setPositionRelative>
              <InputDateRange
                onDatesChange={({ startDate, endDate }) => {
                  setStartDate(startDate);
                  setEndDate(endDate);
                  updateDates(startDate, endDate);
                }}
                onFocusChange={focus => setFocusedInput(focus)}
                startDate={startDate}
                endDate={endDate}
                focusedInput={focusedInput}
                removeBottomSpacer
                inputWidth="l"
              />
            </Box>
            {!isMobile && (
              <TextDense textColor="dark-gray" weight="semibold">
                Number of Guests
              </TextDense>
            )}
            <InputIncrementer
              onChange={value => {
                updateGuests(value);
              }}
              value={listingsDetailsState.guests}
              inputWidth="s"
              removeBottomSpacer
              min={1}
              max={getMaxGuests()}
            />
          </FlexBox>
          <Spacer />
        </>
      )}
      {isEmpty(listingsDetailsState.availabilities) &&
      isEmpty(listingsDetailsState.pricings) ? (
        <PageStateMessage
          iconProps={{ name: 'calendarAlt' }}
          headline="Add Check-in & Check-out Dates"
          description="Once we know your travel dates, we can show you which units are available!"
        />
      ) : (
        <FlexBoxGrid columns={3} gap="m">
          {units
            .sort((a, b) => {
              if (a.unit.name < b.unit.name) return -1;
              if (a.unit.name > b.unit.name) return 1;
              return 0;
            })
            .map((unit, idx) => (
              <UnitTypeItem
                key={`unit-type-${idx}`}
                unitData={unit}
                pricing={listingsDetailsState.pricings[unit.unit.id]}
                availability={listingsDetailsState.availabilities[unit.unit.id]}
              />
            ))}
        </FlexBoxGrid>
      )}
      <Modal
        reveal={openChildUnitModal}
        title="Unit Details"
        closeOnClick={() => {
          dispatch(updateUiSlice({ openChildUnitModal: false }));
          dispatch(updateListingsDetailsMultiSlice({ unitDataForModal: null }));
        }}
        size="l"
      >
        <Modal.Content contentIsScrollable>
          <ChildUnitModalContent />
        </Modal.Content>
      </Modal>
    </ListingsDetailsContentBlock>
  );
};

export default UnitTypes;
