import React from 'react';
import {
  Box,
  InputCheckbox,
  Spacer,
  TextBody,
  TextH2
} from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import { selectVerificationState } from '../../../redux/slices/verification';

const VerificationHeader = ({ updateVerificationState }) => {
  const verificationState = useSelector(selectVerificationState);
  const terms = verificationState.contractTermsAndConditions || '';

  const updateTermsAccepted = () => {
    updateVerificationState({
      terms_accepted: !verificationState.terms_accepted
    });
  };

  return (
    <>
      <TextH2>
        {`Your reservation is ${
          verificationState.booking.confirmed
            ? 'confirmed'
            : 'being reviewed by the owner'
        }!`}
      </TextH2>
      <Spacer />
      {terms.replace(/<(?:.|\n)*?>/gm, '').replace(/(\r\n|\n|\r)/gm, '') !==
      '' ? (
        <>
          <TextBody>
            Before you can access the property, we do need to review and accept
            the on-site terms & conditions, as well as verify your
            identification for security purposes. This required information will
            also be processed with the reservations teams on-site to ensure a
            seamless check-in experience.
          </TextBody>
          <Spacer size="l" />
          <TextH2>Additional On-Site Information</TextH2>
          <Spacer />
          <Box className="scrollContainer">
            <div
              className="customHtmlStyles -denseBody"
              dangerouslySetInnerHTML={{
                __html: verificationState.contractTermsAndConditions
              }}
            />
          </Box>
          <Spacer size="m" />
          <InputCheckbox onChange={() => updateTermsAccepted()}>
            By checking this box, I acknowledge that I have read and agree to
            the additional Terms and Conditions.
          </InputCheckbox>
        </>
      ) : (
        <TextBody>
          Before you can access the property, we do need to verify your
          identification below for security purposes. This required information
          will also be processed with the reservations teams on-site to ensure a
          seamless check-in experience.
        </TextBody>
      )}
      <Spacer size="l" />
    </>
  );
};

export default VerificationHeader;
