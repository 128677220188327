import React from 'react';
import ReactI18n from 'react-i18n';
import { useSelector } from 'react-redux';
import ListingsDetailsContentBlock from './listings-details-content-block';

const CheckInCheckOutPolicy = () => {
  const listing = useSelector(state => state.listing);
  const translate = ReactI18n.getIntlMessage;

  return (
    <ListingsDetailsContentBlock
      label={translate(`cx.details.headers.check_in_out_policy`)}
    >
      {listing.availability.check_in_check_out_policy}
    </ListingsDetailsContentBlock>
  );
};

export default CheckInCheckOutPolicy;
