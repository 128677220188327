import React from 'react';
import { Box, TextDense, TextH2 } from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import { selectListingsDetailsSingleState } from '../../../redux/slices/listingsDetailsSingle';
import ReactI18n from 'react-i18n';

const PriceAverage = () => {
  const listingsDetailsState = useSelector(selectListingsDetailsSingleState)
  const listing = useSelector(state => state.listing)
  const translate = ReactI18n.getIntlMessage;

  if(listingsDetailsState.pricing && listingsDetailsState.pricing.average_nightly_price) {
    return (
      <Box>
        <TextH2 textColor="dark-gray" isFullWidth className={'reset-line-height'}>
          {translate(
            `global.parsers.currency.${listing.currency}`,
            { value: Math.round(listingsDetailsState.pricing.average_nightly_price) }
          )}
        </TextH2>
        <TextDense>{translate(`cx.global.pricing.per_night`)}</TextDense>
      </Box>
    )
  } else if (listing.average_default_nightly_price) {
    return (
      <Box>
        <TextH2 textColor="dark-gray" isFullWidth className={'reset-line-height'}>
          {translate(`global.parsers.currency.${listing.currency}`, {
            value: Math.round(listing.average_default_nightly_price)
          })}
        </TextH2>
        <TextDense>{translate(`cx.global.pricing.avg_per_night`)}</TextDense>
      </Box>
    )
  } else {
    return null
  }
};

export default PriceAverage;
