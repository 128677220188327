import React from 'react';
import { useSelector } from 'react-redux';
import { selectUiState } from '../../redux/slices/ui';
import Search from './search';
import SearchV2 from './search-v2';

const SearchSwitch = props => {
  const ui = useSelector(selectUiState);

  return ui.isBookingEngine ? <SearchV2 {...props} /> : <Search {...props} />;
};

export default SearchSwitch;
