import React from 'react';
import { useDetectMobile } from '../../../shared/hooks/useDetectMobile';
import { renderMobileCheckoutSteps } from '../../../shared/helpers';
import { checkoutSteps } from '../../../shared/data';

const LegacyCheckoutHeader = ({ checkoutActiveStep, steps }) => {
  const { isDesktop } = useDetectMobile();

  return (
    <div className="checkoutHeader">
      <div>
        <h2>{checkoutSteps[checkoutActiveStep].label}</h2>
      </div>
      <ul className="checkoutHeader__steps">
        {isDesktop ? (
          <>
            {steps.map((step, idx) => (
              <li
                key={`step-${idx}`}
                className={checkoutActiveStep === step ? 'activeStep' : ''}
              >
                <div className="checkoutHeader__steps_number">{idx + 1}</div>
                <div>{checkoutSteps[step].label}</div>
              </li>
            ))}
          </>
        ) : (
          <li className="activeStep">
            <div>
              {renderMobileCheckoutSteps(checkoutActiveStep, steps.length)}
            </div>
          </li>
        )}
      </ul>
    </div>
  );
};

export default LegacyCheckoutHeader;
