import React, { useEffect, useState } from 'react';
import axios from 'axios';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as listingAction from '../../redux/action/listing';
import Ripple from '../miscellaneous/ripple';
import SingleV2 from './single-v2';
import VehicleV2 from './vehicle-v2';
import MultiV2 from './multi-v2';
import RoomV2 from './room-v2';

const LoadingWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100%;
`;

const ListingV2 = props => {
  const dispatch = useDispatch();
  const listingState = useSelector(state => state.listing);
  const [loadListing, setLoadListing] = useState(true);

  const fetchListingData = () => {
    axios
      .get(
        `/api/listings/${get(props, 'match.params.listing_slug')}${get(
          props,
          'location.search'
        )}`,
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      .then(response => {
        dispatch(listingAction.setListing(response.data));
        setLoadListing(false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchListingData();
  }, []);

  if (!loadListing) {
    if (listingState.room_type) {
      return <RoomV2 />;
    } else if (listingState.multi_unit) {
      return <MultiV2 />;
    } else if (
      listingState.room_type === false &&
      listingState.multi_unit === false
    ) {
      return <SingleV2 />;
    } else if (listingState.vehicle) {
      return <VehicleV2 />;
    } else {
      return <div />;
    }
  }

  return (
    <LoadingWrapper>
      <Ripple color="#50E3C2" />
    </LoadingWrapper>
  );
};

export default ListingV2;
