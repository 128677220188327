import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  FlexBox,
  IconButton,
  IconFontAwesome,
  Spacer
} from '@directsoftware/ui-kit-web-admin';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

const NavSidebarLink = ({
  href,
  label,
  dropdownOnClick,
  type,
  isActive,
  icon,
  removeBottomSpacer,
  isSubLink,
  dropdownIconToggle
}) => {
  const renderLinkContent = () => {
    return (
      <>
        <FlexBox
          renderAs="span"
          paddingHorizontal="s"
          alignItems="center"
          gap="xs"
        >
          {icon && <Box className="navigationSidebarLink__icon">{icon}</Box>}
          {label}
        </FlexBox>
      </>
    );
  };

  return (
    <>
      <FlexBox
        alignItems="center"
        className={classnames('navigationSidebarLink', {
          isActive,
          isSubLink
        })}
      >
        {isActive ? (
          <FlexBox className="navigationSidebarLink__link">
            {renderLinkContent()}
          </FlexBox>
        ) : (
          <>
            {type === 'router-link' ? (
              <Link to={href} className="navigationSidebarLink__link">
                {renderLinkContent()}
              </Link>
            ) : (
              <a
                href={href}
                target="_blank"
                className="navigationSidebarLink__link"
                rel="noreferrer nofollow"
              >
                {renderLinkContent()}
              </a>
            )}
          </>
        )}
        {dropdownOnClick && (
          <Box>
            <IconButton
              onClick={dropdownOnClick}
              appearance="ghost"
              className="navigationSidebarLink__dropdown"
            >
              <IconFontAwesome
                name={dropdownIconToggle ? 'chevronUp' : 'chevronDown'}
              />
            </IconButton>
          </Box>
        )}
      </FlexBox>
      {!removeBottomSpacer && <Spacer size="xxs" />}
    </>
  );
};

NavSidebarLink.propTypes = {
  href: PropTypes.string,
  label: PropTypes.string,
  dropdownOnClick: PropTypes.func,
  type: PropTypes.oneOf(['link', 'router-link']),
  isActive: PropTypes.bool,
  icon: PropTypes.node,
  removeBottomSpacer: PropTypes.bool,
  isSubLink: PropTypes.bool,
  dropdownIconToggle: PropTypes.bool
};

NavSidebarLink.defaultProps = {
  type: 'router-link'
};

export default NavSidebarLink;
