import React from 'react';
import { useSelector } from 'react-redux';
import { selectUiState } from '../../redux/slices/ui';
import PaymentV2 from './payment-v2';
import Payment from './index';

const PaymentSwitch = props => {
  const ui = useSelector(selectUiState);

  return ui.isBookingEngine ? <PaymentV2 {...props} /> : <Payment {...props} />;
};

export default PaymentSwitch;
