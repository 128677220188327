import React, { useEffect } from 'react';
import {
  Box,
  Container,
  Divider,
  FlexBox,
  Panel,
  Spacer
} from '@directsoftware/ui-kit-web-admin';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import ReactI18n from 'react-i18n';
import CheckoutSteps from '../checkout/shared/checkout-steps';
import { selectUiState, updateUiSlice } from '../../redux/slices/ui';
import {
  selectVerificationState,
  updateVerificationSlice
} from '../../redux/slices/verification';
import VerificationHeader from './shared/verification-header';
import VerificationLocation from './shared/verification-location';
import VerificationAge from './shared/verification-age';
import VerificationSignature from './shared/verification-signature';
import VerificationId from './shared/verification-id';
import VerificationSubmit from './shared/verification-submit';
import VerificationListingInfo from './shared/verification-listing-info';
import VerificationPricingInfo from './shared/verification-pricing-info';
import FooterFinal from '../layout/footer-final';
import { useDetectMobile } from '../../../shared/hooks/useDetectMobile';

const VerificationV2 = props => {
  const { isLargeTabletOrGreater } = useDetectMobile();
  const dispatch = useDispatch();
  const verificationState = useSelector(selectVerificationState);
  const { verificationStateChanged } = useSelector(selectUiState);
  const brand = useSelector(state => state.brand);
  const translate = ReactI18n.getIntlMessage;

  const updateVerificationState = (data, isFilterChange = false) => {
    dispatch(updateVerificationSlice(data));
    if (isFilterChange)
      dispatch(updateUiSlice({ verificationStateChanged: true }));
  };

  const postData = () => {
    const data = { booking_id: verificationState.booking.id };
    if (verificationState.signature) {
      data.signature_id = verificationState.signature.id;
    }

    if (verificationState.idPhoto) {
      data.id_photo_id = verificationState.idPhoto.id;
    }

    if (verificationState.address_verified) {
      data.location = {
        adr_street: verificationState.adrStreet,
        adr_unit: verificationState.adrUnit,
        adr_city: verificationState.adrCity,
        adr_state: verificationState.adrState,
        adr_country: verificationState.adrCountry,
        adr_postal_code: verificationState.adrPostalCode
      };
    }

    if (verificationState.age_verified) {
      data.age_verified = verificationState.age_verified;
    }

    return data;
  };

  const checkCustomerVerification = () => {
    let sig_verified = true;
    if (
      verificationState.verify_signature &&
      !verificationState.customerVerifiedSignature
    ) {
      sig_verified = false;
    }

    let id_verified = true;
    if (verificationState.verify_id && !verificationState.customerVerifiedId) {
      id_verified = false;
    }

    let age_verified = true;
    if (
      verificationState.verify_age &&
      !verificationState.customerVerifiedAge
    ) {
      age_verified = false;
    }

    let address_verified = true;
    if (
      verificationState.verify_address &&
      !verificationState.customerVerifiedAddress
    ) {
      address_verified = false;
    }

    // If user is already verified, pass them directly to the confirmation page
    if (sig_verified && id_verified && age_verified && address_verified) {
      axios
        .post(
          `${process.env.DIRECT_URL}/api/v2/my-bookings/verify/${
            verificationState.customer.id
          }`,
          {
            params: postData()
          }
        )
        .then(response => {
          window.location = `${
            process.env.DIRECT_URL
          }/api/v2/my-bookings/receipt/${props.match.params.slug}/${
            verificationState.booking.booking_code
          }&verified=true`;
        })
        .catch(error => {
          toast.error(error);
          console.log(error);
        });
    }
  };

  const fetchVerificationInfo = () => {
    axios
      .get(
        `${process.env.DIRECT_URL}/api/v2/my-bookings/verification/${get(
          props,
          'match.params.booking_code'
        )}`,
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      .then(response => {
        updateVerificationState(
          {
            availability: response.data.availability,
            booking: response.data.booking,
            charges: response.data.charges,
            customer: response.data.customer,
            customerVerifiedAddress: response.data.customer_verified_address,
            customerVerifiedAge: response.data.customer_verified_age,
            customerVerifiedId: response.data.customer_verified_id,
            featured_image: response.data.featured_image,
            listing: response.data.listing,
            location: response.data.location,
            property: response.data.property,
            rentalAgreement: response.data.rental_agreement,
            propertyManager: response.data.property_manager,
            slug: response.data.slug,
            unit: response.data.unit,
            verify_id: response.data.verify_id,
            verify_id_description: response.data.verify_id_description,
            verify_signature: response.data.verify_signature,
            verify_address: response.data.verify_address,
            verify_age: response.data.verify_age,
            required_age: response.data.required_age,
            contractTermsAndConditions:
              response.data.contract_terms_and_conditions
          },
          true
        );
      })
      .catch(error => {
        console.log(error);
      });
  };

  const checkLocationVerification = () => {
    const {
      adrStreet,
      adrUnit,
      adrCity,
      adrState,
      adrCountry,
      adrPostalCode
    } = verificationState;

    if (
      adrStreet &&
      adrStreet.length > 0 &&
      adrCity &&
      adrCity.length > 0 &&
      adrState &&
      adrState.length > 0 &&
      adrCountry &&
      adrCountry.length > 0 &&
      adrPostalCode &&
      adrPostalCode.length > 0
    ) {
      updateVerificationState({
        address_verified: true,
        adrStreet,
        adrUnit,
        adrCity,
        adrState,
        adrCountry,
        adrPostalCode
      });
    }
  };

  useEffect(
    () => {
      if (verificationStateChanged) {
        dispatch(updateUiSlice({ verificationStateChanged: false }));
        checkCustomerVerification();
        checkLocationVerification();
      }
    },
    [verificationState, verificationStateChanged]
  );

  useEffect(() => {
    dispatch(updateUiSlice({ checkoutActiveStep: 'stepTwo' }));
    fetchVerificationInfo();
  }, []);

  return (
    <>
      <Container variation="extended-wls">
        <CheckoutSteps history={props.history} />
        <FlexBox className="detailsLayout checkoutLayout">
          <Box flex="1">
            <VerificationHeader
              updateVerificationState={updateVerificationState}
            />
            {verificationState.verify_address && (
              <VerificationLocation
                updateVerificationState={updateVerificationState}
              />
            )}
            {verificationState.verify_age && (
              <VerificationAge
                updateVerificationState={updateVerificationState}
              />
            )}
            {verificationState.verify_signature && (
              <VerificationSignature
                updateVerificationState={updateVerificationState}
              />
            )}
            {verificationState.verify_id && (
              <VerificationId
                updateVerificationState={updateVerificationState}
              />
            )}
            <Divider />
            <Spacer size="l" />
            <VerificationSubmit
              updateVerificationState={updateVerificationState}
            />
          </Box>
          {isLargeTabletOrGreater && (
            <Box className="detailsLayout__right checkoutLayout">
              <Panel addShadow>
                <VerificationListingInfo />
                <VerificationPricingInfo />
              </Panel>
            </Box>
          )}
        </FlexBox>
      </Container>
      <Spacer size="xxl" />
      <FooterFinal />
    </>
  );
};

export default VerificationV2;
