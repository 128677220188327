import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  Box,
  Container,
  Divider,
  FlexBox,
  Panel,
  Spacer,
  TextBody,
  TextH2
} from '@directsoftware/ui-kit-web-admin';
import ReactI18n from 'react-i18n';
import moment from 'moment/moment';
import Script from 'react-load-script';
import { Redirect } from 'react-router-dom';
import {
  selectPaymentState,
  updatePaymentSlice
} from '../../redux/slices/payment';
import PageHeader from '../shared/page-header';
import ListingInfo from '../shared/listing-info';
import PaymentBreakdown from '../shared/payment-breakdown';
import RemainingTotal from '../shared/remaining-total';
import { useDetectMobile } from '../../../shared/hooks/useDetectMobile';
import StripeV2 from '../stripe/stripe-v2';
import FooterFinal from '../layout/footer-final';
import PaymentTransaction from '../errors/payment-transaction';
import { selectUiState, updateUiSlice } from '../../redux/slices/ui';
import FormV2 from './form-v2';
import { formatCurrency } from '../../../shared/helpers';
import LynnbrookPaymentV2 from '../lynnbrook/lynnbrook-payment-v2';
import ConfirmationHeader from '../receipt/shared/confirmation-header';

const PaymentV2 = props => {
  const { isLargeTabletOrGreater } = useDetectMobile();
  const dispatch = useDispatch();
  const brand = useSelector(state => state.brand);
  const paymentState = useSelector(selectPaymentState);
  const { paymentStateChanged } = useSelector(selectUiState);
  const translate = ReactI18n.getIntlMessage;
  const [isLynnbrook, setIsLynnbrook] = useState(false);

  const updatePaymentState = (data, isFilterChange = false) => {
    dispatch(updatePaymentSlice(data));
    if (isFilterChange) dispatch(updateUiSlice({ paymentStateChanged: true }));
  };

  const handleStripeScriptError = () => {
    updatePaymentState({
      isStripeSuccessful: false
    });
  };

  const handleStripeScriptLoad = () => {
    Stripe.setPublishableKey(paymentState.stripe_publishable_key);
    updatePaymentState({
      isStripeSuccessful: true,
      loading: false
    });
  };

  const getChargeAmount = () => {
    return Math.max(0, paymentState.bookingPaymentDueToday).toFixed(2);
  };

  const within48HoursOfCheckIn = () => {
    const checkIn = moment(paymentState.booking.check_in, 'YYYY-MM-DD');
    const rightNow = moment();
    const duration = moment.duration(rightNow.diff(checkIn));
    return Math.abs(duration.asHours()) <= 48;
  };

  const moreChargesNeeded = () => {
    const {
      isStripeSuccessful,
      securityDepositRequired,
      booking,
      charges
    } = paymentState;
    if (!isStripeSuccessful || booking.cancelled || !booking.confirmed) {
      return false;
    }
    if (
      securityDepositRequired &&
      charges.length > 0 &&
      moment() > moment(booking.check_in).subtract(4, 'days')
    ) {
      return (
        charges.findIndex(
          c => c.is_security_deposit === true && c.status !== 'failed'
        ) === -1
      );
    }
    return charges.length === 0;
  };

  const failedSecurityDeposit = () => {
    const { charges } = paymentState;
    const securityDeposits = charges.filter(
      c => c.is_security_deposit === true
    );
    if (securityDeposits.length === 0) {
      return false;
    }
    return securityDeposits.every(c => c.status === 'failed');
  };

  const shouldShowSecDepForm = () => {
    // if there are no charges AND it is within 48 hours of check in OR there is a failed charge
    if (within48HoursOfCheckIn() && moreChargesNeeded()) {
      return true;
    } else if (failedSecurityDeposit()) {
      return true;
    } else {
      return false;
    }
  };

  const fetchData = () => {
    axios
      .post(
        `${process.env.DIRECT_URL}/api/v2/my-bookings/payment/${
          props.match.params.booking_code
        }`
      )
      .then(response => {
        const data = response.data;
        const lynnbrookData = {
          accountTargetId: response.data.lynnbrook_target_id,
          accountPersonId: response.data.lynnbrook_account_person_id,
          sessionId: response.data.lynnbrook_session_id,
          token: null
        };
        // remove duplicate lynnbrook data
        delete data.lynnbrook_target_id;
        delete data.lynnbrook_account_person_id;
        delete data.lynnbrook_session_id;
        updatePaymentState(
          {
            loading: false,
            ...response.data,
            accountPersonCreated: true,
            lynnbrookErrors: [],
            fetchingLynnbrookConfig: false,
            lynnbrookConfig: lynnbrookData,
            lynnbrookCardAccepted: false
          },
          true
        );
      })
      .catch(error => toast.error(`error loading page ${error}`));
  };

  const chargeSecurityDeposit = token => {
    axios
      .post(
        `${process.env.DIRECT_URL}/api/v2/listings/${
          paymentState.listing.id
        }/process_security_deposit`,
        {
          booking_id: paymentState.booking.id,
          customer_email: paymentState.customerEmail,
          customer_name: paymentState.customerName,
          customer_telephone: paymentState.customerTelephone,
          stripe_token: token
        }
      )
      .then(response => {
        updatePaymentState({
          charges: [...paymentState.charges, { is_security_deposit: true }],
          isSubmitted: true
        });
      })
      .catch(error => {
        return toast.error(`unable to process security deposit ${error}`);
      });
  };

  const handleStripeSuccess = json => {
    const token = json.id;
    chargeSecurityDeposit(token);
  };

  const handleStripeFailure = ({ error }) => {
    console.error(error);
    updatePaymentState({ transactionError: error });
  };

  const handleStripeCallback = (statusCode, json) => {
    if (statusCode === 200) {
      handleStripeSuccess(json);
    } else {
      handleStripeFailure(json);
    }
  };

  const createStripeToken = () => {
    Stripe.card.createToken(
      {
        number: paymentState.cardNumber,
        cvc: paymentState.cardCvv,
        name: paymentState.customerName,
        exp: paymentState.cardExpiry,
        address_zip: paymentState.customerPostalCode || 'invalid'
      },
      handleStripeCallback
    );
  };

  const processSecurityDeposit = (
    cardNumber,
    cardExpiry,
    cardCvv,
    customerEmail,
    customerName,
    customerPostalCode,
    customerTelephone
  ) => {
    updatePaymentState(
      {
        cardNumber: cardNumber.replace(' ', ''),
        cardExpiry,
        cardCvv: cardCvv.replace(' ', ''),
        customerEmail,
        customerName,
        customerPostalCode,
        customerTelephone,
        createStripeToken: true
      },
      true
    );
  };

  const fetchLynnbrookConfig = targetId => {
    const name = `${paymentState.customerFirstName} ${
      paymentState.customerLastName
    }`;
    axios
      .get(
        `${process.env.DIRECT_URL}/api/v2/listings/${
          paymentState.listing.id
        }/lynnbrook_config?customer_email=${
          paymentState.customer.email
        }&customer_name=${name}&customer_telephone=${
          paymentState.customer.telephone
        }&target_id=${targetId}`,
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      .then(res => {
        const data = res.data;
        updatePaymentState({
          fetchingLynnbrookConfig: false,
          accountPersonCreated: true,
          lynnbrookConfig: {
            ...paymentState.lynnbrookConfig,
            accountPersonId: data.account_person_id,
            sessionId: data.session_id,
            token: process.env.APTEXX_API_TOKEN
          }
        });
      })
      .catch(error => {
        console.warn(error);
      });
  };

  const showOnlySecDepLynnbrook = () => {
    return (
      isLynnbrook &&
      shouldShowSecDepForm() &&
      getChargeAmount() === (0).toFixed(2)
    );
  };

  const showOnlyProcessPaymentLynnbrook = () => {
    return (
      isLynnbrook &&
      !shouldShowSecDepForm() &&
      getChargeAmount() !== (0).toFixed(2)
    );
  };

  useEffect(
    () => {
      if (paymentStateChanged) {
        dispatch(updateUiSlice({ paymentStateChanged: false }));
        if (paymentState.createStripeToken) {
          updatePaymentState({ createStripeToken: false });
          createStripeToken();
        }
      }
    },
    [paymentState, paymentStateChanged]
  );

  useEffect(() => {
    fetchData();
    setIsLynnbrook(brand.organization.payment_processor === 2);
  }, []);

  if (paymentState.isSubmitted) {
    return (
      <Redirect
        to={{
          pathname: `/my-bookings/receipt/${paymentState.booking.booking_code}`
        }}
      />
    );
  }

  return paymentState.loading ? null : (
    <>
      <Script
        url="https://js.stripe.com/v2/"
        onError={handleStripeScriptError}
        onLoad={handleStripeScriptLoad}
      />
      <Container variation="extended-wls">
        <PageHeader
          label="Make a Payment"
          backLink={`/my-bookings/receipt/${paymentState.booking.booking_code}`}
        />
        <FlexBox className="detailsLayout checkoutLayout">
          <Box flex="1">
            {getChargeAmount() !== (0).toFixed(2) ||
            showOnlySecDepLynnbrook() ? (
              <>
                <ConfirmationHeader usePaymentState />
                {!showOnlySecDepLynnbrook() && (
                  <>
                    <Box>
                      In order to complete your reservation, please enter your
                      payment details below.
                    </Box>
                    {paymentState.bookingPaymentBalanceDueDate &&
                      paymentState.bookingPaymentBalanceDueDate.length > 0 && (
                        <Box>
                          {`Your final payment ${
                            paymentState.bookingPaymentBalanceDueDate
                          }`}
                        </Box>
                      )}
                  </>
                )}
                <Spacer size="l" />
                {isLynnbrook ? (
                  <>
                    {!showOnlySecDepLynnbrook() && (
                      <FlexBox
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box>
                          <TextH2>Charge Amount</TextH2>
                        </Box>
                        <Box>
                          <TextH2>
                            {formatCurrency(getChargeAmount(), brand.currency)}
                          </TextH2>
                        </Box>
                      </FlexBox>
                    )}
                    <Divider padding="s" />
                    <LynnbrookPaymentV2
                      usePaymentState
                      onlySecDep={showOnlySecDepLynnbrook()}
                      onlyProcessPayment={showOnlyProcessPaymentLynnbrook()}
                      accountPersonCreated={paymentState.accountPersonCreated}
                      availability={paymentState.availability}
                      booking={paymentState.booking}
                      brand_info={brand.brand_info}
                      chargeAmount={getChargeAmount()}
                      listing={paymentState.listing}
                      slug={paymentState.slug}
                      customerEmail={paymentState.customerEmail}
                      customerName={`${paymentState.customerFirstName} ${
                        paymentState.customerLastName
                      }`}
                      currency={brand.currency}
                      customerPostalCode={paymentState.adrPostalCode}
                      customerTelephone={paymentState.customerTelephone}
                      translate={translate}
                      unit={paymentState.unit}
                      lynnbrookConfig={paymentState.lynnbrookConfig}
                      adrStreet={paymentState.adrStreet}
                      adrCity={paymentState.adrCity}
                      adrState={paymentState.adrState}
                      adrCountry={paymentState.adrCountry}
                      adrPostalCode={paymentState.adrPostalCode}
                      updateState={updatePaymentState}
                      fetchLynnbrookConfig={fetchLynnbrookConfig}
                    />
                  </>
                ) : (
                  <StripeV2
                    currency={brand.currency}
                    availability={paymentState.availability}
                    booking={paymentState.booking}
                    brand_info={brand.brand_info}
                    chargeAmount={getChargeAmount()}
                    listing={paymentState.listing}
                    slug={paymentState.slug}
                    stripeCustomerId={paymentState.stripe_customer_id}
                    stripeIntentId={paymentState.stripe_intent_id}
                    stripePublishableKey={paymentState.stripe_publishable_key}
                    stripeAccountID={paymentState.stripe_account_id}
                    translate={translate}
                    unit={paymentState.unit}
                  />
                )}
                <PaymentTransaction errors={[paymentState.transactionError]} />
              </>
            ) : (
              <>
                <TextH2>You're all paid up!</TextH2>
                <Spacer />
                <TextBody>
                  No additional payments are needed, enjoy your stay!
                </TextBody>
              </>
            )}
            {shouldShowSecDepForm() && !isLynnbrook && (
              <section className="payment">
                {paymentState.securityDepositRequired ? (
                  <div>
                    <Spacer />
                    <Box>
                      Please enter your billing details below so we can process
                      your damage deposit authorization. This authorization
                      works exactly the same way as when you check in to a
                      hotel, and your card will not be charged this amount
                      unless there is damage to the property.
                    </Box>
                  </div>
                ) : (
                  <Box>
                    Please enter your billing details below so we can process
                    your reservation.
                  </Box>
                )}
                <Spacer size="m" />
                <FormV2
                  processSecurityDeposit={processSecurityDeposit}
                  slug={paymentState.slug}
                  rental_agreement={paymentState.rental_agreement}
                />
                <PaymentTransaction errors={[paymentState.transactionError]} />
              </section>
            )}
          </Box>
          {isLargeTabletOrGreater && (
            <Box className="detailsLayout__right checkoutLayout">
              <Panel addShadow>
                <Box padding="s">
                  <ListingInfo stateObject={paymentState} />
                </Box>
                <Divider />
                <Box padding="s">
                  <PaymentBreakdown stateObject={paymentState} />
                </Box>
                <Divider />
                <Box padding="s">
                  <RemainingTotal
                    priceTotal={paymentState.booking.price_total}
                    pricePaid={paymentState.booking.price_paid}
                  />
                </Box>
              </Panel>
            </Box>
          )}
        </FlexBox>
      </Container>
      <Spacer size="xxl" />
      <FooterFinal />
    </>
  );
};

export default PaymentV2;
