import React from 'react';
import { Box } from '@directsoftware/ui-kit-web-admin';
import ReactI18n from 'react-i18n';
import { useSelector } from 'react-redux';
import ShowMoreWrapper from './show-more-wrapper';
import ListingsDetailsContentBlock from './listings-details-content-block';

const Description = ({ description }) => {
  const listing = useSelector(state => state.listing);
  const translate = ReactI18n.getIntlMessage;

  if (
    !description &&
    (!listing || !listing.property?.summary_description ||
      listing.property.summary_description.replace(/(<([^>]+)>)/gi, '') === '')
  )
    return null;

  return (
    <ListingsDetailsContentBlock
      label={translate(`cx.details.headers.description`)}
    >
      <ShowMoreWrapper>
        <Box className="customHtmlStyles">
          <div
            dangerouslySetInnerHTML={{
              __html: description || listing.property.summary_description
            }}
          />
        </Box>
      </ShowMoreWrapper>
    </ListingsDetailsContentBlock>
  );
};

export default Description;
