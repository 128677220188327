import { combineReducers } from 'redux';
import brand from './brandReducer';
import listing from './listingReducer';
import listings from './listingsReducer';
import ui from '../slices/ui';
import listingsSearch from '../slices/listingsSearch';
import listingsDetailsSingle from '../slices/listingsDetailsSingle';
import listingsDetailsMulti from '../slices/listingsDetailsMulti';
import checkout from '../slices/checkout';
import verification from '../slices/verification';
import receipt from '../slices/receipt';
import payment from '../slices/payment';
import contract from '../slices/contract';

const rootReducer = combineReducers({
  brand,
  listing,
  listings,
  ui,
  listingsSearch,
  listingsDetailsSingle,
  listingsDetailsMulti,
  checkout,
  verification,
  receipt,
  payment,
  contract
});

export default rootReducer;
